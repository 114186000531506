<template>
  <div class="warehouse">
    <v-btn small color="primary" @click="update_mode = !update_mode"
      >update</v-btn
    >
    <v-simple-table v-if="parts.length > 0" class="single_warehouse_table">
      <thead>
        <tr>
          <th>Supplier</th>
          <th>WCS Part#</th>
          <th>Description</th>
          <th>Qty</th>
          <th v-if="update_mode == true">New qty</th>
          <th>Unit</th>
          <th>Cost per unit</th>
        </tr>
      </thead>
      <tbody>
        <SingleInventoryItem
          v-for="item in items"
          :update_mode="update_mode"
          :key="item.part_id + increment"
          :passedPart="get_part(item.part_id)"
          :passedItem="item"
          @deleteme="deleteme"
        ></SingleInventoryItem>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import SingleInventoryItem from '../components/SingleInventoryItem.vue'
export default {
  name: 'SingleWarehouse',
  components: { SingleInventoryItem },
  props: {
    warehouse_id: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: function () {
        return []
      }
    },
    parts: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      update_mode: false,
      increment: 0
    }
  },
  computed: {},
  methods: {
    get_part(part_id) {
      // return part_id
      return this.parts.filter(x => x.id == part_id)[0]
    },
    deleteme(x) {
      this.$emit('deleteme', x)
      this.increment++
    }
  }
}
</script>

<style scoped>
</style>