import Vue from 'vue'
import Vuex from 'vuex'
import parts from './parts'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    parts
  },
  state: {
    profile: {
      user: '',
      authorized: false
    },
    customer_type: 'wholesale',
    state_search: '',
    loading: false,
    filter: '',
    selected_warehouse: null,
    new_inventory_persist: false,
    list_parents: false,
    include_index: true,
    filters: {
      has_children: false,
      cost_mismatch: false
    }
  },
  getters: {
    profile: state => state.profile,
    get_customer_type: state => state.customer_type,
    get_search: state => state.state_search
  },
  mutations: {
    set_search(state, search) {
      state.state_search = search
    },
    set_profile(state, profile) {
      state.profile = profile
    },
    set_customer_type_mutation(state, customer_type) {
      state.customer_type = customer_type
    },
    set_filter(state, value) {
      state.filter = value
    },
    set(state, payload) {
      state[payload.field] = payload.value
    }
  },
  actions: {
    set_customer_type({ commit }, payload) {
      commit('set_customer_type_mutation', payload)
    }
  },
  plugins: [
    createPersistedState({
      //These values will save to local storage to survive refreshes
      paths: [
        'profile',
        'customer_type',
        'state_search',
        'parts.part_search',
        'selected_warehouse',
        'new_inventory_persist'
      ]
    })
  ]
})
