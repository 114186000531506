<template>
  <div class="home">
    <TheTopBar>
      <template #title>Notes on calculations</template>
    </TheTopBar>

    <v-card width="1200">
      <v-card-text>
        To find parts where the saved retail does not match calculated.
        <v-alert outlined color="blue"
          >SELECT id, wcs_part_number, cost, retail, desired_margin,
          calculated_retail FROM parts WHERE retail != ROUND(calculated_retail,
          2)</v-alert
        >
        Getting rounded margin based on saved pricing
        <v-alert outlined color="blue">
          UPDATE `parts` SET desired_margin = ROUND(((retail - cost)/ retail) *
          100)
        </v-alert>
        Calculated retail
        <v-alert outlined color="blue">
          cost / (1 - (desired_margin / 100))
        </v-alert>
        Calculate margin
        <v-alert outlined color="blue">
          ((retail - cost)/ retail) * 100
        </v-alert>
        Set calculated retail based on desired margin
        <v-alert outlined color="blue">
          UPDATE `parts` SET calculated_retail = ROUND(cost / (1 -
          (desired_margin / 100)),2)
        </v-alert>
        Get grouped index tags
        <v-alert outlined color="blue">
          SELECT i.id, i.tag, GROUP_CONCAT(i.wcs_part_number ORDER BY
          i.wcs_part_number) FROM `index_tags` i JOIN parts p ON
          i.wcs_part_number = p.wcs_part_number WHERE p.customer_pricelist = '1'
          GROUP BY i.tag
        </v-alert>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import TheTopBar from '@/components/TheTopBar.vue'
export default {
  name: 'Home',
  components: {
    TheTopBar
  }
}
</script>
