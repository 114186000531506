var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.update_production_costs(true)}}},[_vm._v("Update Prod cost")]),_c('v-btn',{staticClass:"mr-5",attrs:{"color":"primary"},on:{"click":_vm.save_all}},[_vm._v("Update Saved cost & prices")])],1),_c('v-data-table',{attrs:{"items":_vm.results,"item-key":"id","items-per-page":50,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.production_cost",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.production_cost, item.sum),"dark":""}},[_vm._v(" "+_vm._s(item.production_cost)+" ")])]}},{key:"item.retail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.retail)+" "),_c('br'),_vm._v(" margin "+_vm._s(_vm.get_current_margin(item) * 100)+" ")]}},{key:"item.wholesale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.wholesale)),_c('br'),_vm._v(" discount "+_vm._s(_vm.get_discount(item))+" ")]}},{key:"item.new_retail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_new_retail(item))),_c('br'),_vm._v(" margin: "+_vm._s(_vm.get_new_margin(item) * 100)+" ")]}},{key:"item.new_wholesale",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_new_wholesale(item))),_c('br'),_vm._v(" discount "+_vm._s(_vm.get_new_discount(item))+" ")]}},{key:"item.save",fn:function(ref){
var item = ref.item;
return [_c('div',{class:_vm.is_same(item)},[_c('v-btn',{attrs:{"color":_vm.is_same(item),"disabled":_vm.is_same(item) == 'secondary',"small":""},on:{"click":function($event){return _vm.save_new_costs(item)}}},[_vm._v("Save")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }