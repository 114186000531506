<template>
  <v-card width="1100">
    <div>
      <v-app-bar class="my-header align-center" app fixed>
        <div style="width: 300px" class="mr-5">
          <v-text-field
            id="filter_input"
            dense
            outlined
            background-color="white"
            hide-details
            append-icon="mdi-close"
            @click:append="filter = ''"
            label="filter"
            v-model="filter"
          ></v-text-field>
        </div>
        <div style="width: 300px">
          {{ count }}
          Search results
          {{ get_search ? 'for &quot;' + get_search + '&quot;' : ' ' }} in
          {{ get_customer_type }}
        </div>
        <div class="pagination" v-if="count > 0">
          <v-pagination
            v-model="page"
            :length="pagination_length"
            total-visible="7"
          ></v-pagination>
          <div style="width: 60px" class="pt-3">
            <v-autocomplete
              dense
              hide-details="true"
              label="per page"
              v-model="per_page"
              :items="per_page_options"
            >
            </v-autocomplete>
          </div>
        </div>
        <v-checkbox
          dense
          class="mycheckbox mt-4"
          v-model="collapse"
          label="Show details"
        >
        </v-checkbox>
        <v-checkbox
          dense
          class="mycheckbox mt-4"
          v-model="global_show_events_flag"
          label="Show events"
        ></v-checkbox>
        <v-icon @click="new_customer()">mdi-account-plus</v-icon>
      </v-app-bar>
    </div>
    <v-card-text v-if="$store.state.loading == true">
      <v-skeleton-loader
        loading="true"
        type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-card-text>
    <v-card-text v-else class="px-0 text-buffer">
      <table class="table table-responsive-sm text-left">
        <thead>
          <th>Name&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>

          <th>Phone&emsp;&emsp;&emsp;&emsp;</th>
          <th>Address&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>
          <th>Id</th>
          <th>&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</th>
        </thead>

        <SingleCustomer
          v-for="(r, index) in paginated_customers"
          :key="r.id"
          :index="index"
          :collapse="!collapse"
          :global_show_events_flag="global_show_events_flag"
          :r="r"
          @updated="update_from_child($event)"
          @deleted="delete_me($event)"
          @added="add_me($event)"
          @cancel="delete_me($event)"
        ></SingleCustomer>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import SingleCustomer from '../components/SingleCustomer'
import axios from 'axios'
export default {
  name: 'CustomerList',
  components: {
    SingleCustomer
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    customerType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      per_page: 25,
      page: 1,
      per_page_options: [10, 15, 20, 25, 40, 100, 500, 9999999],
      ship: false, //whether or not to show the shipping address fields
      save_confirmation: '',
      customers: [],
      filter: '',
      collapse: false,
      global_show_events_flag: false
    }
  },
  computed: {
    ...mapGetters(['get_search', 'get_customer_type']),
    ...mapState(['loading']),

    pagination_length() {
      return Math.ceil(this.count / this.per_page)
    },
    paginated_customers() {
      return this.filtered_array.slice(
        this.page * this.per_page - this.per_page,
        this.per_page * this.page
      )
    },

    filtered_array() {
      return this.customers.filter(
        cust =>
          ((cust.first_name + ' ')
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.last_name + ' ')
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.company + ' ')
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          (cust.id == this.filter) |
          ((cust.address1 + ' ')
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.address2 + ' ')
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.city + ' ').toLowerCase().indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.zip + ' ').toLowerCase().indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.phone_1 + ' ' + cust.phone_1_text)
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.phone_2 + ' ' + cust.phone_2_text)
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.phone_3 + ' ' + cust.phone_3_text)
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0) |
          ((cust.phone_4 + ' ' + cust.phone_4_text)
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) >=
            0)
      )
    },
    count() {
      return this.filtered_array.length
    }
  },
  watch: {
    get_search() {
      this.search_fn()
    },
    get_customer_type() {
      this.search_fn()
    }
  },
  mounted() {
    this.search_fn()
  },
  methods: {
    /* ---------------------------------------------------------------------------------------------- */
    /*                                       ASYNC SEARCH_FN() {                                      */
    /* ---------------------------------------------------------------------------------------------- */

    async search_fn() {
      this.$store.state.loading = true
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_clients.php',
          {
            customer_type: this.get_customer_type,
            search: this.get_search
          }
        )
        console.log(response.data)
        this.customers = response.data
        // Validated 02/09/2021
        if (response.data.length > 0) this.validate_client(this.customers[0])
        this.$store.state.loading = false
      } catch (error) {
        alert(error)
      }
    },

    /* ---------------------------------------------------------------------------------------------- */
    /*                                          NEW CUSTOMER                                          */
    /* ---------------------------------------------------------------------------------------------- */

    new_customer() {
      this.page = this.pagination_length
      var noob = { noob: true }
      this.customers.push(noob)
    },
    update_from_child(payload) {
      let index = this.customers.findIndex(
        x => x.client_id == payload.client_id
      )
      this.customers.splice(index, 1, payload)
    },
    delete_me(payload) {
      let index = this.customers.findIndex(
        x => x.client_id == payload.client_id
      )
      this.customers.splice(index, 1)
    },
    add_me(payload) {
      // remove temp record
      const noobindex = this.customers.findIndex(x => {
        x.noob == true
      })
      this.customers.splice(noobindex, 1)
      payload.edit_customer_flag = false
      this.customers = [...this.customers, payload]
    }
  }
}
</script>

<style lang="scss" scoped>
.my-header {
  background-color: $my_header_color;
}

::v-deep .mycheckbox label {
  margin: 0px 20px 0px -5px;
}
</style>
