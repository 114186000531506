<template>
  <div v-if="is_logged_in">
    <slot></slot>
  </div>
  <div v-else>
    <div class="box">
      <div class="">
        Please use
        <a href="https://weavertown3.lancasterdatasystems.com"
          >https://weavertown3.lancasterdatasystems.com</a
        >
        going forward. Notice the "3" in the URL.
      </div>

      <v-btn @click="override = !override" class="mt-5 mb-5"
        >Click here for old site if there is a reason to</v-btn
      >
      <div class="" v-if="override == true">
        <GoogleLogin />
      </div>
    </div>
  </div>
</template>

<script>
import GoogleLogin from '../components/GoogleLogin'
export default {
  components: {
    GoogleLogin
  },
  data() {
    return {
      override: false
    }
  },
  computed: {
    is_logged_in: function() {
      // return false;
      return this.$store.getters.profile.authorized === true
    }
  }
}
</script>
<style scoped>
.box {
  width: 700px;
  height: 500px;
  border: 1px solid black;
  border-radius: 10px;
  margin: 50px;
  padding: 20px;
  background-color: #ededed;
}
</style>
