<template>
  <div>
    <div class="background">
      <v-progress-circular
        size="50"
        class="spinner"
        indeterminate
      ></v-progress-circular>
    </div>

    <iframe id="printPdf" name="printPdf"></iframe>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'ReportsCustomerPriceList',
  data() {
    return {
      parts: [],
      index: [],
      index_table: [],
      index_header: [
        { text: 'Description', fontSize: 9, fillColor: '#DDDDFF' },
        { text: 'Part#', fontSize: 9, fillColor: '#DDDDFF' }
      ],
      mm_groups: {},
      header_row: [
        {
          text: '',
          fontSize: 9,
          fillColor: '#DDDDFF'
        },

        {
          text: [
            { text: 'Supplier/', fontSize: 9, color: 'purple', bold: 'true' },
            { text: 'Part#', fontSize: 9, bold: 'true' }
          ],
          fillColor: '#DDDDFF'
        },
        {
          text: [
            { text: 'WCS#', fontSize: 9, bold: 'true' },
            {
              text: '          id',
              fontSize: 7,
              color: 'blue',
              alignment: 'right'
            }
          ],
          fillColor: 'DDDDFF'
        },
        {
          text: 'Description',
          fontSize: 9,
          fillColor: '#DDDDFF',
          bold: 'true'
        },
        {
          text: 'Cost',
          fontSize: 9,
          fillColor: '#FF5555',
          bold: 'true'
        },
        {
          text: 'Retail',
          fontSize: 9,
          fillColor: '#FFFFDD',
          bold: 'true'
        },
        {
          text: 'WHSL',
          fontSize: 9,
          fillColor: '#DDDDFF',
          bold: 'true'
        },
        {
          text: 'Qty discounts',
          fontSize: 9,
          fillColor: '#DDDDFF',
          bold: 'true'
        },

        {
          text: 'Notes',
          fontSize: 9,
          fillColor: '#DDDDFF',
          bold: 'true'
        }
      ],

      default_style: { fontSize: 8, color: 'black' }, // used as a style
      main_table_layout: {
        hLineWidth: function () {
          return 0.1
        },
        vLineWidth: function () {
          return 0.1
        },
        hLineColor: function () {
          return '#aaaaaa'
        },
        vLineColor: function () {
          return '#aaaaaa'
        },
        paddingLeft: function () {
          return 3
        },
        paddingRight: function () {
          return 3
        },
        paddingBottom: function () {
          return 1
        },
        paddingTop: function () {
          return 9
        }
      }
    }
  },
  /* ---------------------------------------------------------------------------------------------- */
  /*                                            COMPUTED                                            */
  /* ---------------------------------------------------------------------------------------------- */
  computed: {
    /* -------------------------------------- categorized_parts ------------------------------------- */
    categorized_parts() {
      return _.groupBy(this.parts, x => x.category_id)
    },
    /* --------------------------------------- category_tables -------------------------------------- */
    category_tables() {
      let newarray = []
      Object.values(this.categorized_parts).forEach(category => {
        newarray.push(...this.create_category_table(category))
      })
      return newarray
    },
    /* ----------------------------------------- main_table ----------------------------------------- */
    main_table() {
      let newarray = []
      newarray.push(...this.category_tables)
      newarray.unshift(this.header_row)
      return newarray
    },
    /* ---------------------------------------- grouped_index --------------------------------------- */
    grouped_index() {
      return _.groupBy(this.index, x => x.tag)
    },

    /* ---------------------------------------- summed_index ---------------------------------------- */
    summed_index() {
      let newarry = []
      Object.values(this.grouped_index).forEach(x => {
        newarry.push({
          tag: x[0].tag,
          wcs_part_number: _.join(
            x.map(wcs_part_number => wcs_part_number.wcs_part_number),
            ','
          )
        })
      })
      return newarry
    },
    /* ---------------------------------------- sorted_index ---------------------------------------- */
    sorted_index() {
      return _.sortBy(this.summed_index, x => x.tag)
    },
    /* -------------------------------------- index_PDF_object -------------------------------------- */
    index_PDF_object() {
      if (this.$store.state.filter) {
        return {}
      }
      return {
        //* Index
        table: {
          dontBreakRows: true,
          body: this.index_table
        },
        layout: {
          paddingTop: function () {
            return 0
          },
          paddingBottom: function () {
            return 0
          },
          paddingLeft: function () {
            return 0
          },
          paddingRight: function () {
            return 0
          },
          defaultBorder: true,
          hLineColor: function (i, node) {
            return '#aaaaaa'
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length
              ? '#aaaaaa'
              : '#ffffff'
          }
        }
        //layout: this.index_table_layout
      }
    }
  },

  /* ---------------------------------------------------------------------------------------------- */
  /*                                           MOUNTED() {                                          */
  /* ---------------------------------------------------------------------------------------------- */

  mounted() {
    this.load_parts()
    this.load_index()
  },

  /* ----------- ----------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    /* ------------------------------ create_category_table(category) { ----------------------------- */

    create_category_table(category) {
      this.set_mm_start_and_end(category)
      let current_mm = ''
      let current_subcategory = ''
      let newarray = [
        [
          {
            //* Add the category header black bar
            text: category[0].category_id + ' - ' + category[0].category_name,
            colSpan: 9,
            fontSize: 10,
            fillColor: 'black',
            bold: 'true',
            color: 'white',
            margin: [0, -3, 0, 0],
            lineHeight: 0.8
          }
        ]
      ]
      category.forEach(part => {
        //* Add the mix and match header bar if needed
        if (
          part.mm_group !== current_mm &&
          part.mm_group != '' &&
          part.category_id != '1000'
        ) {
          let mm = this.mm_groups.find(x => x.mm_group == part.mm_group)
          newarray.push([
            {
              text: `${part.mm_group} (${mm?.start} to ${mm?.end}) may be mix or matched for better pricing`,
              colSpan: 9,
              fontSize: 7,
              margin: [0, -2, 0, 0],
              lineHeight: 0.7,
              bold: true,
              italics: true,
              fillColor: part.mm_group ? '#ddffdd' : ''
            }
          ])
          current_mm = part.mm_group
        }
        //* Add the subcategory if needed
        if (
          part.subcategory !== current_subcategory &&
          part.subcategory != '' &&
          part.category_id != '1000'
        ) {
          newarray.push([
            {
              text: `${part.subcategory}`,
              colSpan: 9,
              fontSize: 8,
              margin: [0, -2, 0, 0],
              lineHeight: 0.8,
              bold: true,

              fillColor: '#efeaea'
            }
          ])
          current_subcategory = part.subcategory
        }

        //* Create the actual row
        newarray.push(...this.create_row(part))
      })
      return newarray
    },

    create_qty_discounts(part, x) {
      let unit = part['q' + x + '_unit']
      let discount = part['q' + x + '_discount']

      let price_array = []
      price_array[1] =
        part.wholesale - (part.wholesale * part.q1_discount) / 100
      price_array[2] =
        price_array[1] - (price_array[1] * part.q2_discount) / 100
      price_array[3] =
        price_array[2] - (price_array[2] * part.q3_discount) / 100
      price_array[4] =
        price_array[3] - (price_array[3] * part.q4_discount) / 100
      // special case for distributor discount:
      // if (unit == 'D') {
      //   price_array[4] = part.wholesale - (part.wholesale * discount) / 100
      //   x = 4
      //   console.log(
      //     `%cPart# ${part.id} Unit is${unit} and discount is ${discount}`,
      //     'color:green;font-weight:bold'
      //   )
      // }
      // --->

      let output = {
        columns: [
          {
            text: unit,
            fontSize: 7,
            width: 'auto',
            alignment: 'left',
            color: unit == 'D' ? '#FF0000' : '',
            bold: unit == 'D' ? true : false
          },
          {
            text: `${discount}%`,
            fontSize: 7,
            color: '#4444FF',
            width: '*',
            alignment: 'right'
          },
          {
            text: ' ',
            width: 3
          },
          {
            // Just drop the 3rd decimal place if cost < $1
            text:
              part.cost < 1
                ? this.money_format(price_array[x], 3)
                : //this.money_format(Math.floor(price_array[x] * 100) / 100, 2) - to drop the 3rd decimal place
                  this.money_format(price_array[x], 2),

            fontSize: 7,
            color: '#333333',
            alignment: 'right',
            width: 'auto'
          },
          {
            text: ' ',
            width: 3
          },
          {
            text: this.percent_format(
              (price_array[x] - part.cost) / price_array[x]
            ),
            fontSize: 7,
            color: '#E66100',
            alignment: 'right',
            width: 'auto'
          }
        ]
      }
      return output
    },
    /* ------------------------------------- create_row(part) { ------------------------------------- */

    create_row(part) {
      let row_array = []

      //! ------------------------------------- Quantity discounts ------------------------------------- */

      let q1 = this.create_qty_discounts(part, '1')
      let q2 = this.create_qty_discounts(part, '2')
      let q3 = this.create_qty_discounts(part, '3')
      let q4 = this.create_qty_discounts(part, '4')

      let discounts = []
      if (part.q1_unit != '' && part.q1_unit != null) discounts.push(q1)
      if (part.q2_unit != '' && part.q2_unit != null) discounts.push(q2)
      if (part.q3_unit != '' && part.q3_unit != null) discounts.push(q3)
      if (part.q4_unit != '' && part.q4_unit != null) discounts.push(q4)
      // Special case for distributor discounts:
      // if (
      //   [part.q1_unit, part.q2_unit, part.q3_unit, part.q4_unit].includes('D')
      // ) {
      //   discounts.push(q4)
      // }
      // --->
      let qty_discounts = {
        stack: discounts
      }

      //! ---------------------push to row array ---------------------------------

      row_array.push([
        {
          text: '',
          color: '#555555',
          fillColor: part.mm_group ? '#ddffdd' : ''
        },

        {
          stack: [
            { text: part.supplier_name, color: 'purple' },
            { text: part.supplier_part_number, bold: 'true' }
          ]
        },
        {
          stack: [
            {
              text: part.wcs_part_number,
              bold: 'true'
            },
            { text: part.id, alignment: 'right', fontSize: 7, color: 'blue' }
          ]
        },
        {
          text: this.get_formatted_description(part.description)
        },
        {
          stack: [
            {
              text: this.money_format(part.cost),
              color: part.cost > 0 ? '#000000' : 'red'
            },
            { text: part.unit, alignment: 'right' }
          ]
        },
        {
          stack: [
            {
              text: this.money_format(part.retail),

              color: part.retail > 0 ? '#000000' : 'red'
            },
            {
              text:
                part.retail > 0
                  ? this.percent_format(1 - part.cost / part.retail)
                  : '',
              alignment: 'right',
              color: '#444444'
            }
          ],
          fillColor: '#FFFFDD'
        },
        {
          stack: [
            {
              text: this.money_format(part.wholesale),

              color: part.wholesale > 0 ? '#000000' : 'red'
            },
            {
              columns: [
                {
                  // Percent off of retail
                  text:
                    part.wholesale > 0
                      ? this.percent_format(
                          (part.retail - part.wholesale) / part.retail
                        )
                      : '',
                  alignment: 'left',
                  color: '#4444FF',
                  fontSize: 7
                },
                {
                  // Profit margin as a percent
                  text:
                    part.wholesale > 0
                      ? this.percent_format(1 - part.cost / part.wholesale)
                      : '',
                  alignment: 'right',
                  color: '#E66100',
                  fontSize: 7
                }
              ]
            }
          ]
        },
        qty_discounts,

        {
          text: part.notes
        }
      ])
      return row_array
    },
    set_mm_start_and_end(category) {
      // Adds a start and stop part number to the array of M&M groups in data
      let current_mm = ''
      category.forEach(part => {
        if (part.mm_group !== current_mm && part.mm_group != '') {
          let mm = this.mm_groups.find(x => x.mm_group == part.mm_group) || {}
          if (!mm.start) {
            mm.start = part.wcs_part_number
          }

          mm.end = part.wcs_part_number
        }
      })
    },
    /* ---------------------------------------- CREATE INDEX ---------------------------------------- */
    create_index() {
      // this.index.unshift({
      //   tag:
      //     '123456789012345678901234567890123456789012345678901234567890123456',
      //   wcs_part_number: '12345678901234567890'
      // })
      let index_array = []
      let page_array = []
      let column_array = []
      let per_column = 65
      let per_page = 3
      let column_count = 0
      let line_count = 1
      let alternating = 0
      let tag
      //!Begin loop
      for (tag of this.sorted_index) {
        //Adding line counts based on the length of the descriptions and part numbers. This way, we can always start new column at the bottom of the page

        let x = tag.tag.length
        let addA = 0
        if (x >= 29) {
          addA = 1
        }
        if (x >= 58) {
          addA = 2
        }
        if (x >= 87) {
          addA = 3
        }

        let y = tag.wcs_part_number.length
        let addB = 0
        if (y >= 12) {
          addB = 1
        }
        if (y >= 24) {
          addB = 2
        }
        line_count = line_count + (addA >= addB ? addA : addB)

        column_array.push({
          table: {
            body: [
              [
                {
                  //text: line_count + '-' + tag.tag,
                  text: tag.tag,
                  width: 100,
                  // border: [true, false, false, false],
                  font: 'RobotoMonoLight',
                  fontSize: 7,
                  lineHeight: 1
                },
                {
                  text: tag.wcs_part_number,
                  // border: [false, false, true, false],
                  font: 'RobotoMonoLight',
                  fontSize: 7
                }
              ]
            ],
            widths: [120, 48]
          },
          layout: {
            fillColor: alternating % 2 === 0 ? '#DDDDDD' : '',

            defaultBorder: false,
            paddingTop: function (i, node) {
              return 0
            },
            paddingBottom: function (i, node) {
              if (node.positions.length > 3) return 4
              if (node.positions.length > 2) return 2
              return 0
            }
          }
        })
        //* After 50 lines, create a column and start over
        if (line_count > per_column) {
          //* Finish the column and add it to the page
          page_array.push(column_array)
          column_array = []
          line_count = 0
          column_count++
          //* After 5 columns, create a new page and start over:
          if (column_count == per_page) {
            index_array.push(page_array)
            column_count = 0
            page_array = []
          }
        }
        line_count++
        alternating++
      }
      //! End loop
      page_array.push(column_array)
      //* We have to add the remaining items that did not make up a full page:
      let stragglers = 3 - page_array.length
      for (let i = 0; i < stragglers; i++) {
        page_array.push([])
      }
      index_array.push(page_array)
      this.index_table = index_array
    },

    /* ---------------------------------------- generate() { ---------------------------------------- */
    //http://pdfmake.org/playground.html for examples

    generate() {
      var tmpdoc = document.getElementById('printPdf').contentWindow.document
      tmpdoc.open()
      tmpdoc.write('Loading Customer Pricelist...')
      tmpdoc.close()
      var date = dayjs().format('M/D/YYYY h:mm A')
      var docDefinition = {
        footer: function (currentPage, pageCount) {
          return {
            table: {
              body: [
                [
                  {
                    text: `Customer pricelist - Printed ${date} Page ${currentPage.toString()} of ${pageCount}`,
                    alignment: 'right',
                    fontSize: 7,
                    style: 'normalText',
                    margin: [30, -7, 0, 0]
                  }
                ]
              ]
            },
            layout: 'noBorders'
          }
        },
        //! ---------- COLUMN WIDTHS ----------------
        content: [
          this.index_PDF_object,
          { text: '', pageBreak: this.$store.state.filter ? 'none' : 'after' },
          {
            table: {
              headerRows: 1,
              dontBreakRows: true,
              body: this.main_table,
              widths: [4, 60, 59, 130, 36, 36, 36, 67, '*']
            },
            layout: this.main_table_layout
          }
          //   ]
          // }
        ],

        defaultStyle: this.default_style,
        // pageMargins: function (currentPage, pageCount) {
        //   return [30, 15, 15, 15]
        // },
        pageMargins: [35, 15, 15, 15],

        pageSize: 'LETTER',
        pageOrientation: 'portrait'
      }
      pdfMake.fonts = {
        RobotoMonoLight: {
          normal: process.env.VUE_APP_FONT_URL + 'RobotoMono-Light.ttf'
        },

        Roboto: {
          normal:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          bold: process.env.VUE_APP_FONT_URL + 'Roboto-Bold.ttf',
          bolditalics: process.env.VUE_APP_FONT_URL + 'Roboto-BoldItalic.ttf'
        }
      }
      pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf'])
    },

    /* ----------------------------------------- load_parts ----------------------------------------- */
    async load_parts() {
      //alert(this.$store.state.filter)
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parts.php',
          {
            customer: true,
            search: this.$store.state.filter,
            hide_archive: false
          }
        )
        this.parts = response.data.parts // This is where we get the data
        // Validated 02/09/2021
        // this.validate_part(response.data.parts[0])
        this.mm_groups = response.data.mm_groups
        this.generate()
        // console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    /* ----------------------------------------- load_index ----------------------------------------- */
    async load_index() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_index.php',
          {
            customer: true
          }
        )
        this.index = response.data
        this.create_index()
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    /* ---------------------------------- get_formatted_description --------------------------------- */
    get_formatted_description(d) {
      // use a special character to bold text
      if (d.includes('(b)')) {
        const startBold = d.indexOf('(b)')
        const endBold = d.indexOf('(/b)')
        const normal_start = d.substring(0, startBold)
        const bold = d.substring(startBold + 3, endBold)
        const normal_end = d.substring(endBold + 4)

        return [normal_start, { text: bold, bold: 'true' }, normal_end]
      }
      return d
    }
  }
}
</script>

<style scoped>
.background {
  position: absolute;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
}
.spinner {
  margin: 99px;
}
#printPdf {
  position: absolute;
  top: 0px;
  display: inline;
  height: 1000px;
  width: 1200px;
}
</style>
