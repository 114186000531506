<template>
  <div>
    <div class="background">
      <v-progress-circular
        size="50"
        class="spinner"
        indeterminate
      ></v-progress-circular>
    </div>

    <iframe id="printPdf" name="printPdf"></iframe>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'ReportsParentParts',
  data() {
    return {
      parts: [],

      default_style: { fontSize: 8, color: 'black' }, // used as a style
      main_table_layout: {
        hLineWidth: function() {
          return 0.1
        },
        vLineWidth: function() {
          return 0.1
        },
        hLineColor: function() {
          return '#aaaaaa'
        },
        vLineColor: function() {
          return '#aaaaaa'
        },
        paddingLeft: function() {
          return 3
        },
        paddingRight: function() {
          return 3
        },
        paddingBottom: function() {
          return 1
        },
        paddingTop: function() {
          return 3
        }
      }
    }
  },
  /* ---------------------------------------------------------------------------------------------- */
  /*                                            COMPUTED                                            */
  /* ---------------------------------------------------------------------------------------------- */
  computed: {
    /* ----------------------------------------- main_table ----------------------------------------- */
    main_table() {
      let newarray = []
      this.parts.forEach(part => {
        newarray.push(...this.create_parent_row(part))
        newarray.push(...this.create_child_table(part))
      })
      return newarray
    }
  },

  mounted() {
    this.load_parts()
  },

  /* ------------------------------ create_category_table(category) { ----------------------------- */
  methods: {
    create_child_table(part) {
      let row_array = []
      part.children.forEach(mypart => {
        row_array.push([
          {
            columns: [
              { text: '', width: 50 },
              { text: mypart.qty, width: 20 },
              { text: mypart.wcs_part_number, width: 80 },
              { text: mypart.description },
              {
                stack: [
                  { text: mypart.cost + ' cost' },
                  {
                    text:
                      mypart.production_cost > 0
                        ? mypart.production_cost + ' production cost'
                        : ''
                  }
                ]
              },
              {
                stack: [
                  { text: mypart.cost * mypart.qty },
                  {
                    text:
                      mypart.production_cost > 0
                        ? mypart.production_cost * mypart.qty
                        : ''
                  }
                ]
              }
            ],
            colSpan: 6
          }
        ])
      })

      return row_array
    },

    /* ------------------------------------- create_parent_row(part) { ------------------------------------- */

    create_parent_row(part) {
      let row_array = []

      row_array.push([
        {
          stack: [
            {
              text: part.wcs_part_number,
              bold: 'true'
            },
            { text: part.id, alignment: 'right', fontSize: 7, color: 'blue' }
          ]
        },

        {
          text: this.get_formatted_description(part.description)
        },

        {
          stack: [
            { text: 'cost' },
            {
              text: this.money_format(part.cost),
              color: part.cost > 0 ? '#000000' : 'red'
            }
          ]
        },

        {
          stack: [
            { text: 'retail' },
            {
              text: this.money_format(part.retail),

              color: part.retail > 0 ? '#000000' : 'red'
            },
            {
              text:
                part.retail > 0
                  ? this.percent_format(1 - part.cost / part.retail)
                  : '',
              alignment: 'right',
              color: '#444444'
            }
          ]
        },

        {
          stack: [
            { text: 'wholesale' },
            {
              text: this.money_format(part.wholesale),

              color: part.wholesale > 0 ? '#000000' : 'red'
            },
            {
              columns: [
                {
                  // Percent off of retail
                  text:
                    part.wholesale > 0
                      ? this.percent_format(
                          (part.retail - part.wholesale) / part.retail
                        )
                      : '',
                  alignment: 'left',
                  color: '#4444FF',
                  fontSize: 7
                },
                {
                  // Profit margin as a percent
                  text:
                    part.wholesale > 0
                      ? this.percent_format(1 - part.cost / part.wholesale)
                      : '',
                  alignment: 'right',
                  color: '#E66100',
                  fontSize: 7
                }
              ]
            }
          ]
        },

        {
          stack: [
            { text: this.money_format(part.sum_of_cost) + ' sum of cost' },
            {
              text:
                this.money_format(part.sum_of_production_cost) +
                ' sum of production cost'
            }
          ]
        }
      ])
      return row_array
    },
    generate() {
      var tmpdoc = document.getElementById('printPdf').contentWindow.document
      tmpdoc.open()
      tmpdoc.write('Loading parent parts...')
      tmpdoc.close()
      var date = dayjs().format('M/D/YYYY h:mm A')
      var docDefinition = {
        footer: function(currentPage, pageCount) {
          return {
            table: {
              body: [
                [
                  {
                    text: `Parent parts - Printed ${date} Page ${currentPage.toString()} of ${pageCount}`,
                    alignment: 'right',
                    fontSize: 7,
                    style: 'normalText',
                    margin: [30, -7, 0, 0]
                  }
                ]
              ]
            },
            layout: 'noBorders'
          }
        },
        //! ---------- COLUMN WIDTHS ----------------
        content: [
          {
            table: {
              dontBreakRows: true,
              body: this.main_table,
              widths: [40, 200, 36, 36, 67, '*']
            },
            layout: this.main_table_layout
          }
          //   ]
          // }
        ],

        defaultStyle: this.default_style,

        pageMargins: [35, 15, 15, 15],

        pageSize: 'LETTER',
        pageOrientation: 'portrait'
      }
      pdfMake.fonts = {
        RobotoMonoLight: {
          normal: process.env.VUE_APP_FONT_URL + 'RobotoMono-Light.ttf'
        },

        Roboto: {
          normal:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          bold: process.env.VUE_APP_FONT_URL + 'Roboto-Bold.ttf',
          bolditalics: process.env.VUE_APP_FONT_URL + 'Roboto-BoldItalic.ttf'
        }
      }
      pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf'])
    },

    /* ----------------------------------------- load_parts ----------------------------------------- */
    async load_parts() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parent_parts.php',
          {
            search: '',
            parents_only: true,
            hide_archive: false
          }
        )
        this.parts = response.data
        // Add some calculations:
        this.parts.forEach(part => {
          part.sum_of_cost = _.sumBy(part.children, x => {
            return Number(x.qty) * Number(x.cost)
          })
        })
        this.parts.forEach(part => {
          part.sum_of_production_cost = _.sumBy(part.children, x => {
            return (
              Number(x.qty) *
              (x.production_cost > 0
                ? Number(x.production_cost)
                : Number(x.cost))
            )
          })
        })

        this.generate()
      } catch (error) {
        console.log(error)
      }
    },

    /* ---------------------------------- get_formatted_description --------------------------------- */
    get_formatted_description(d) {
      // use a special character to bold text
      if (d.includes('(b)')) {
        const startBold = d.indexOf('(b)')
        const endBold = d.indexOf('(/b)')
        const normal_start = d.substring(0, startBold)
        const bold = d.substring(startBold + 3, endBold)
        const normal_end = d.substring(endBold + 4)

        return [normal_start, { text: bold, bold: 'true' }, normal_end]
      }
      return d
    }
  }
}
</script>

<style scoped>
.background {
  position: absolute;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
}
.spinner {
  margin: 99px;
}
#printPdf {
  position: absolute;
  top: 65px;
  display: inline;
  height: 1000px;
  width: 1200px;
}
</style>
