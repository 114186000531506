<template>
  <tr>
    <td>
      <div class="relative_cell">
        <div class="part_number">{{ part.wcs_part_number }}</div>

        <div class="part_id">
          <v-icon v-if="part.has_children == true" small color="green">
            mdi-sitemap
          </v-icon>
          <b-icon-box-arrow-in-up
            v-if="part.has_parents > 0"
          ></b-icon-box-arrow-in-up>
          <div class="id">{{ part.id }}</div>
        </div>

        <div class="description">{{ part.description }}</div>
        <v-icon
          v-if="part.has_photos == '1'"
          size="20"
          @click="show_part_photos = true"
        >
          mdi-camera
        </v-icon>
      </div>
    </td>

    <td>
      <div class="d-flex align-center">
        <div class="qty_controls">
          <v-text-field
            v-model="qty"
            outlined
            dense
            type="number"
            :hint="part.unit"
            persistent-hint
          >
          </v-text-field>
        </div>
        <div class="add_button">
          <v-icon color="success" @click="add_child()">
            mdi-plus-circle
          </v-icon>
        </div>
      </div>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'SingleAddChildPart',

  props: {
    passedpart: {
      type: Object,
      default: function() {
        return {}
      }
    },
    parentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      part: JSON.parse(JSON.stringify(this.passedpart)),
      qty: 1
    }
  },

  methods: {
    async add_child() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'add_child_part.php',
          {
            parent_id: this.parentId,
            child_id: this.part.id,
            qty: this.qty
          }
        )
        console.log(response.data)

        this.$emit('added')
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
/* To allow absolute position inside */
td {
  position: relative;
}

/* Create partial border between cells */
td:before {
  content: '';
  position: absolute;
  width: 1px;
  background: rgba(185, 185, 185, 0.5);
  right: 0;
  margin: 7px 0px 7px 0px;
  top: 0;
  bottom: 0;
}
.part_number {
  text-align: left;
  font-weight: bold;
}
.id {
  display: inline-block;
  font-size: 0.6em;
  font-style: italic;
  color: purple;
}
.part_id {
  position: absolute;
  right: 0px;
  top: 0px;
}

.relative_cell {
  position: relative;
}
tr td:nth-child(1) {
  min-width: 120px;
  text-align: left;
}
tr td:nth-child(2) {
  max-width: 30px;
}

.description {
  margin-left: 20px;
}
</style>
