<template>
  <tr v-if="events.length > 0">
    <td class="container_td" colspan="5">
      <v-simple-table dense class="my_events">
        <thead>
          <th>Event date</th>
          <th>Event type</th>
          <th>Carriage type</th>
          <th>Notes</th>
          <th>WC Serial#</th>
          <th>Job#</th>
        </thead>
        <tbody>
          <SingleEvent
            v-for="event in events"
            :passedEvent="event"
            :client="client"
            :key="event.id"
            @add="add_event"
            @dblclick="edit_event(event.id)"
            @update="update_event"
          >
          </SingleEvent>
        </tbody>
      </v-simple-table>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import SingleEvent from '../components/SingleEvent'
export default {
  name: 'MyEvents',
  components: {
    SingleEvent
  },
  props: {
    client_id: {
      type: String,
      default: ''
    },
    client: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      events: []
    }
  },
  mounted() {
    this.get_my_events()
  },
  methods: {
    async get_my_events() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'get_my_events.php',
          {
            client_id: this.client_id
          }
        )
        this.events = response.data
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    add_event(event) {
      alert('ASD')
      this.events.push(event)
    },
    update_event(event) {}
  }
}
</script>

<style lang="sass" scoped>
.my_events
  min-width: 1000px

.container_td
  padding: 0px 0px 20px 50px

tr td:nth-child(1)
  border-left: 2px solid $my_border_color_dark

tr td:last-child
  border-right: 2px solid $my_border_color_dark
</style>