<template>
  <v-dialog
    v-model="dialog"
    draggable
    width="1250"
    ref="edit_modal"
    @input="$emit('killme')"
    @click:outside="cancel"
  >
    <v-card>
      <v-card-title class="card_title">
        {{
          customer_modal_mode == 'new'
            ? 'New customer'
            : 'Edit customer ' + customer.client_id
        }}</v-card-title
      >
      <v-card-text class="mycard">
        <v-form hide-details id="edit_customer_form">
          <!-- ROW -->
          <v-row>
            <v-col cols="5">
              <v-text-field
                class="myinput"
                v-model="customer.company"
                dense
                hide-details
                outlined
                label="Company"
              ></v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                class="myinput"
                v-model="customer.phone_1"
                dense
                hide-details
                outlined
                label="Phone 1"
              ></v-text-field>
            </v-col>
            <v-col cols="3"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Client type"
                v-model="customer.client_type"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Start date"
                v-model="customer.start_date"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- ROW -->
          <v-row>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="First name"
                v-model="customer.first_name"
              ></v-text-field>
            </v-col>
            <v-col cols="1"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="M.I."
                v-model="customer.middle_initial"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Last name"
                v-model="customer.last_name"
              ></v-text-field>
            </v-col>

            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Phone 1 text"
                v-model="customer.phone_1_text"
              ></v-text-field> </v-col
            ><v-col cols="3"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Business type"
                v-model="customer.business_type"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Topping"
                v-model="customer.topping"
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- ROW -->
          <v-row>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Suffix"
                v-model="customer.suffix"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Title"
                v-model="customer.title"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Address 1"
                v-model="customer.address1"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Phone 2"
                v-model="customer.phone_2"
              ></v-text-field> </v-col
            ><v-col cols="3"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Tax status"
                v-model="customer.tax_status"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Tax number"
                v-model="customer.tax_number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Address 2"
                v-model="customer.address2"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Phone 2 text"
                v-model="customer.phone_2_text"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Fax"
                v-model="customer.fax"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="City"
                v-model="customer.city"
              ></v-text-field>
            </v-col>
            <v-col cols="1"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="State"
                v-model="customer.state"
              ></v-text-field>
            </v-col>
            <v-col cols="1"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Zip"
                v-model="customer.zip"
              ></v-text-field>
            </v-col>
            <v-col cols="1"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Country"
                v-model="customer.country"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Phone 3"
                v-model="customer.phone_3"
              ></v-text-field>
            </v-col>
            <v-col cols="4"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="email"
                v-model="customer.email"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5">
              <v-checkbox
                class="mycheckbox"
                hide-details
                v-model="ship"
                label="Ship"
              ></v-checkbox>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Phone 3 text"
                v-model="customer.phone_3_text"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row> </v-row>
          <!-- separate shipping address -->
          <div v-if="ship" class="ship_address">
            <v-row>
              <v-col cols="5"
                ><v-text-field
                  class="myinput"
                  dense
                  hide-details
                  outlined
                  label="Shipping name"
                  v-model="customer.ship_name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5"
                ><v-text-field
                  class="myinput"
                  dense
                  hide-details
                  outlined
                  label="Shipping address 1"
                  v-model="customer.ship_address1"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="5"
                ><v-text-field
                  class="myinput"
                  dense
                  hide-details
                  outlined
                  label="Shipping address 2"
                  v-model="customer.ship_address2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2"
                ><v-text-field
                  class="myinput"
                  dense
                  hide-details
                  outlined
                  label="Shipping city"
                  v-model="customer.ship_city"
                ></v-text-field>
              </v-col>
              <v-col cols="1"
                ><v-text-field
                  class="myinput"
                  dense
                  hide-details
                  outlined
                  label="Shipping state"
                  v-model="customer.ship_state"
                ></v-text-field> </v-col
              ><v-col cols="1"
                ><v-text-field
                  class="myinput"
                  dense
                  hide-details
                  outlined
                  label="Shipping zip"
                  v-model="customer.ship_zip"
                ></v-text-field>
              </v-col>
              <v-col cols="1"
                ><v-text-field
                  class="myinput"
                  dense
                  hide-details
                  outlined
                  label="Shipping country"
                  v-model="customer.ship_country"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>
          <!-- end shipping address -->
          <v-row>
            <v-col cols="5"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Notes"
                v-model="customer.notes"
                rows="3"
              ></v-text-field>
            </v-col>
            <v-col cols="2"
              ><v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Phone 4"
                v-model="customer.phone_4"
              ></v-text-field>
              <v-text-field
                class="myinput"
                dense
                hide-details
                outlined
                label="Phone 4 text"
                v-model="customer.phone_4_text"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
        <div class="checkboxes">
          <v-checkbox
            class="slim_checks mycheckbox"
            hide-details
            v-model="customer.retail_newsletter"
            value="TRUE"
            unchecked-value="FALSE"
            label="Retail news"
          >
          </v-checkbox>
          <v-checkbox
            class="slim_checks mycheckbox"
            hide-details
            v-model="customer.wholesale_newsletter"
            value="TRUE"
            unchecked-value="FALSE"
            label="Wholesale news"
          >
          </v-checkbox>
          <v-checkbox
            class="slim_checks mycheckbox"
            hide-details
            v-model="customer.catalog_parts"
            value="TRUE"
            unchecked-value="FALSE"
            label="Catalog parts"
          >
          </v-checkbox>
          <v-checkbox
            class="slim_checks mycheckbox"
            hide-details
            v-model="customer.catalog_carriage"
            value="TRUE"
            unchecked-value="FALSE"
            label="Catalog carriage"
          >
          </v-checkbox>
        </div>
      </v-card-text>
      <v-divider class="ma-0"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="customer_modal_mode == 'edit'"
          color="error"
          @click="delete_customer_flag = true"
        >
          Delete
        </v-btn>
        <v-btn
          color="success"
          @click="
            customer_modal_mode == 'edit'
              ? save_customer()
              : save_new_customer()
          "
        >
          Save
        </v-btn>
        <v-btn color="grey" @click="cancel"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
    <!-- footer for customer edit modal -->
    <!-- <template v-slot:modal-footer="{ hide }">
      <b-button
        size="sm"
        variant="success"
        @click="
          customer_modal_mode == 'edit' ? save_customer() : save_new_customer()
        "
      >
        Save
      </b-button>
      <b-button
        v-if="customer_modal_mode == 'edit'"
        size="sm"
        variant="danger"
        @click="delete_customer_flag = true"
      >
        Delete
      </b-button>
      <b-button size="sm" variant="secondary" @click="hide()"> Close </b-button>
    </template>-->
    <DeleteCustomer
      v-if="delete_customer_flag == true"
      :key="2"
      :customer="r"
      @delete_me="delete_customer($event)"
      @killme="delete_customer_flag = false"
    ></DeleteCustomer>
  </v-dialog>
</template>

<script>
import axios from 'axios'
import DeleteCustomer from '../components/DeleteCustomer'
export default {
  name: 'EditCustomer',
  components: {
    DeleteCustomer
  },
  props: {
    r: {
      type: Object,
      default: function () {
        return {}
      }
    },
    customer_modal_mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      ship: false,
      customer: { ...this.r },
      delete_customer_flag: false,
      dialog: true
    }
  },
  mounted() {
    this.$refs.edit_modal.show()
  },
  methods: {
    /* -------------------------------------- save_new_customer ------------------------------------- */
    async save_new_customer() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_new_customer.php',
          {
            customer: this.customer
          }
        )
        this.$emit('added', response.data)

        this.$emit('killme')

        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    /* ---------------------------------------- save_customer --------------------------------------- */
    async save_customer() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_customer.php',
          {
            customer: this.customer,
            Nothing: 'OK'
          }
        )
        this.$emit('updated', this.customer)

        this.$emit('killme')

        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    /* --------------------------------------- delete_customer -------------------------------------- */
    delete_customer() {
      this.$emit('delete_me', this.customer)
      this.delete_customer_flag = false
      this.$emit('killme')
    },
    /* ------------------------------------------- cancel ------------------------------------------- */
    cancel() {
      this.$emit('cancel', this.customer)
      this.dialog = false
    }
  }
}
</script>

<style scoped>
.checkboxes {
  position: absolute;
  right: 190px;
  bottom: 100px;
  padding: 0;
  margin: 0;
}
.mycard {
  margin: 20px 0px;
}
.slim_checks {
  padding: 0;
  margin: 0;
}
.card_title {
  background-color: #607d8b;
  color: white;
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -5px;
}
</style>
