<template>
  <tbody class="customer">
    <tr
      class="top_row"
      @dblclick="
        local_show_events_flag = !local_show_events_flag
        collapse = !collapse
      "
    >
      <td>
        <div class="cell_wrapper">
          <div style="padding-left: 12px">
            <b>
              {{ surname }}
            </b>
            {{ r.first_name }} {{ r.middle_initial }}
            <br />
            <div>{{ r.title }}</div>
            <div class="company">{{ r.company }}</div>

            <div class="cell_top_right">
              <v-icon v-if="r.notes" @click="show_notes(r.id)">
                mdi-message-text
              </v-icon>
            </div>
          </div>
        </div>
      </td>
      <td>
        <div>{{ r.phone_1 }} {{ r.phone_1_text }}</div>
        <div>{{ r.phone_2 }} {{ r.phone_2_text }}</div>
        <div>{{ r.phone_3 }} {{ r.phone_3_text }}</div>
        <div>{{ r.phone_4 }} {{ r.phone_4_text }}</div>
        <div>{{ r.fax ? 'Fax: ' + r.fax : '' }}</div>
        <span style="color: blue">{{ r.email }}</span>
      </td>
      <td>
        <div>
          {{ r.address1 }}
        </div>
        <div>
          {{ r.address2 }}
        </div>

        <div>{{ r.city }}, {{ r.state }} {{ r.zip }}</div>
        <div v-if="r.ship_address1" class="ship_address">
          ship:
          <br />
          <div>
            {{ r.ship_address1 }}
          </div>
          <div>
            {{ r.ship_name }}
          </div>
          <div>
            {{ r.ship_address2 }}
          </div>
          {{ r.ship_city }}, {{ r.ship_state }}
          {{ r.ship_zip }}
        </div>
      </td>
      <td class="id_type">
        <span class="client_type">{{ r.client_type }}</span>
        <span class="id">{{ r.client_id }}</span>
      </td>
      <td>
        <v-menu offset-y>
          <template v-slot:activator="{ on }" v-on="on">
            <v-btn small v-on="on">Action <v-icon>mdi-menu-down</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title @click="begin_edit"
                >Edit customer
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="delete_customer_flag = true"
                >Delete customer
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title @click="new_event_flag = true"
                >New Event
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>

      <EditCustomer
        v-if="edit_customer_flag == true"
        :r="r"
        :customer_modal_mode="customer_modal_mode"
        @delete_me="delete_customer($event)"
        @updated="update_me"
        @added="addme"
        @killme="
          edit_customer_flag = false
          customer_modal_mode = 'edit'
        "
      ></EditCustomer>
      <DeleteCustomer
        v-if="delete_customer_flag == true"
        :key="1"
        :customer="r"
        @delete_me="delete_customer($event)"
      ></DeleteCustomer>
      <EditEvent
        v-if="new_event_flag == true"
        :r="r"
        :mode="'new'"
        @add="add_event"
        @killme="new_event_flag = false"
      ></EditEvent>
    </tr>
    <tr v-if="collapse == false" class="bottom_row">
      <td colspan="5">
        <div class="details">
          <div class="notes">
            <div class="notes_label">Notes:</div>
            {{ r.notes }}
          </div>

          <div class="subscriptions">
            <div class="label">subscriptions:</div>
            <div v-if="r.retail_newsletter == 'TRUE'" class="subscription">
              Retail news
            </div>
            <div v-if="r.wholesale_newsletter == 'TRUE'" class="subscription">
              Wholesale news
            </div>
            <div v-if="r.catalog_parts == 'TRUE'" class="subscription">
              Catalog-parts
            </div>
            <div v-if="r.catalog_carriage == 'TRUE'" class="subscription">
              Catalog-carriage
            </div>
          </div>
          <div>
            <div class="business_type">
              <div class="label">business type:</div>
              <div class="data">{{ r.business_type }}</div>
            </div>

            <div class="tax_status">
              <div class="label">Tax status:</div>
              <div class="data">{{ r.tax_status }}</div>
            </div>
            <div class="tax_number">
              <div class="label">Tax number:</div>
              <div class="data">{{ r.tax_number }}</div>
            </div>
            <div class="topping">
              <div class="label">topping:</div>
              <div class="data">{{ r.topping }}</div>
            </div>
          </div>
          <div class="meta">
            <div class="start_date">
              <div class="label">Start date:</div>
              <div class="data">{{ r.start_date }}</div>
            </div>
            <div class="created_at">
              <div class="label">Record created:</div>
              <div class="data">{{ r.created_at }}</div>
            </div>
            <div class="created_by">
              <div class="label">Created by:</div>
              <div class="data">{{ r.created_by }}</div>
            </div>
            <div class="updated_at">
              <div class="label">Record updated:</div>
              <div class="data">{{ r.updated_at }}</div>
            </div>
            <div class="updated_by">
              <div class="label">Updated by:</div>
              <div class="data">{{ r.updated_by }}</div>
            </div>
          </div>
        </div>
      </td>
    </tr>

    <MyEvents
      v-if="show_events_flag == true"
      :client_id="r.client_id"
      :client="r"
    ></MyEvents>
  </tbody>
</template>

<script>
import axios from 'axios'
import DeleteCustomer from '../components/DeleteCustomer.vue'
import EditCustomer from '../components/EditCustomer.vue'
import EditEvent from '../components/EditEvent.vue'
import MyEvents from '../components/MyEvents.vue'
export default {
  name: 'SingleCustomer',
  components: {
    EditCustomer,
    DeleteCustomer,
    EditEvent,
    MyEvents
  },
  props: {
    r: {
      type: Object,
      default: function() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    },
    passedEvents: {
      type: Array,
      default: function() {
        return []
      }
    },

    collapse: {
      type: Boolean,
      default: false
    },
    global_show_events_flag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      customer_modal_mode: 'edit',
      edit_customer_flag: false,
      delete_customer_flag: false,
      new_event_flag: false,
      local_show_events_flag: false,
      ship: false, //whether or not to show the shipping address fields,
      pdf_id: '', //used for the mailing labels
      events: [...this.passedEvents]
    }
  },
  computed: {
    show_events_flag() {
      return this.global_show_events_flag || this.local_show_events_flag
    },
    label_url() {
      return process.env.VUE_APP_API_URL + 'pdf.php?id=' + this.pdf_id
    },
    rowColor() {
      return this.index % 2 ? 'even' : 'odd'
    },
    surname() {
      if (this.r.suffix) {
        return this.r.last_name + ' ' + this.r.suffix + ','
      }
      if (this.r.last_name) {
        return this.r.last_name + ','
      }
      return ''
    }
  },

  mounted() {
    // This opens the edit modal for new customers
    if (this.r.noob) {
      this.noob()
    }
  },
  methods: {
    noob() {
      this.customer_modal_mode = 'new'
      this.begin_edit()
    },

    mailing_labels(cust_id) {
      this.pdf_id = cust_id
      this.$refs['label_modal'].show()
    },

    /* ---------------------------------------------------------------------------------------------- */
    /*                                      SAVE_NEW_CUSTOMER() {                                     */
    /* ---------------------------------------------------------------------------------------------- */

    async save_new_customer() {
      // delete this.r.noob
      try {
        await axios.post(
          process.env.VUE_APP_API_URL + 'save_new_customer.php',
          {
            customer: this.r
          }
        )
      } catch (error) {
        console.log(error)
      }
      this.$refs['my-modal'].hide()
    },

    /* ---------------------------------------------------------------------------------------------- */
    /*                                          SAVE CUSTOMER                                         */
    /* ---------------------------------------------------------------------------------------------- */

    async save_customer() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + 'save_customer.php', {
          customer: JSON.stringify(this.r)
        })
      } catch (error) {
        console.log(error)
      }

      this.$refs['my-modal'].hide()
    },
    show_notes() {
      this.$bvModal.msgBoxOk(this.r.notes, {
        title: 'Customer notes',
        size: 'sm',
        hideHeaderClose: false,
        centered: true
      })
    },

    /* ---------------------------------------------------------------------------------------------- */
    /*                                         DELETE CUSTOMER                                        */
    /* ---------------------------------------------------------------------------------------------- */
    begin_edit() {
      this.edit_customer_flag = true
    },
    update_me(payload) {
      this.$emit('updated', payload)
    },
    done_editing() {
      this.edit_customer_flag = false
    },
    delete_customer(payload) {
      this.$emit('deleted', payload)
    },
    new_event() {
      this.events.push({ noob: true })
      this.new_event_flag = true
    },
    add_event(event) {
      this.events.push(event)
    },
    addme(payload) {
      this.$emit('added', payload)
    }
  }
}
</script>

<style lang="sass" scoped>
td
  line-height: 1.2em

tr td:nth-child(1)
  min-width: 220px

tr td:nth-child(2)
  min-width: 102px

tr td:nth-child(3)
  min-width: 152px

tr td:nth-child(4)
  min-width: 50px
  text-align: right

tr td:nth-child(5)
  min-width: 50px

.cell_top_right
  position: absolute
  top: 0px
  right: 0px

.cell_wrapper
  position: relative
  min-height: 20px

.ship_address
  border-top: 1px solid $my_border_color_light
  font-size: 0.9em
  font-style: italic

.company
  padding-left: 10px
  color: royalblue

.id
  font-weight: bold
  text-decoration: underline

.client_type
  font-size: 0.8em
  margin-right: 5px

.even
  background-color: #fff

.odd
  background-color: #eee

.bottom_row td
  border-bottom: 0px solid $my_border_color_dark

.top_row td
  border-top: 2px solid $my_border_color_dark

.delete
  color: red

.details
  display: flex

.notes
  padding-left: 50px
  width: 300px
  padding-right: 10px

.label
  display: inline-block
  font-style: italic
  font-size: 0.9em
  color: rgba(0, 0, 0, 0.596)
  width: 120px

.subscriptions
  width: 150px

.data
  display: inline-block
  font-size: 0.9em
  font-weight: bold
  width: 150px

.subscription
  font-weight: bold
  font-size: 0.9em

.customer tr td:nth-child(1)
  border-left: 2px solid $my_border_color_dark

.customer tr td:last-child
  border-right: 2px solid $my_border_color_dark
</style>
