<template>
  <v-app id="app">
    <LoginRequired>
      <v-navigation-drawer
        app
        v-model="drawer"
        permanent
        width="230"
        mini-variant-width="20"
        :mini-variant.sync="comp_mini"
        :expand-on-hover="comp_mini"
      >
        <TheSidebar :mini="comp_mini" @toggle="toggle" />
      </v-navigation-drawer>

      <v-main :class="mini ? 'collapsed' : ''">
        <router-view ref="main" :key="$store.state.filter" />
      </v-main>
    </LoginRequired>
  </v-app>
</template>

<script>
import TheSidebar from '@/views/TheSidebar'
import LoginRequired from '@/components/LoginRequired.vue'

export default {
  components: {
    TheSidebar,
    LoginRequired
  },
  data() {
    return {
      drawer: true,
      mini: false
    }
  },
  computed: {
    comp_mini() {
      return this.$vuetify.breakpoint.smAndDown || this.mini
    }
  },
  mounted() {
    this.get_key()
    this.make_draggable()
  },
  methods: {
    get_key() {
      if (this.$route.query.key)
        this.$store.commit('set_key', this.$route.query.key)
    },
    toggle(x) {
      this.mini = x
    },
    make_draggable: function () {
      // make vuetify dialogs movable
      const d = {}
      document.addEventListener('mousedown', e => {
        const closestDialog = e.target.closest('.v-dialog.v-dialog--active')
        if (
          e.button === 0 &&
          closestDialog != null &&
          e.target.classList.contains('v-card__title')
        ) {
          // element which can be used to move element
          d.el = closestDialog // element which should be moved
          d.mouseStartX = e.clientX
          d.mouseStartY = e.clientY
          d.elStartX = d.el.getBoundingClientRect().left
          d.elStartY = d.el.getBoundingClientRect().top
          d.el.style.position = 'fixed'
          d.el.style.margin = 0
          d.oldTransition = d.el.style.transition
          d.el.style.transition = 'none'
        }
      })
      document.addEventListener('mousemove', e => {
        if (d.el === undefined) return
        d.el.style.left =
          Math.min(
            Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
            window.innerWidth - d.el.getBoundingClientRect().width
          ) + 'px'
        d.el.style.top =
          Math.min(
            Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
            window.innerHeight - d.el.getBoundingClientRect().height
          ) + 'px'
      })
      document.addEventListener('mouseup', () => {
        if (d.el === undefined) return
        d.el.style.transition = d.oldTransition
        d.el = undefined
      })
      setInterval(() => {
        // prevent out of bounds
        const dialog = document.querySelector('.v-dialog.v-dialog--active')
        if (dialog === null) return
        dialog.style.left =
          Math.min(
            parseInt(dialog.style.left),
            window.innerWidth - dialog.getBoundingClientRect().width
          ) + 'px'
        dialog.style.top =
          Math.min(
            parseInt(dialog.style.top),
            window.innerHeight - dialog.getBoundingClientRect().height
          ) + 'px'
      }, 100)
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

.spinner-background {
  position: absolute;
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100%;
  width: 100%;
  text-align: center;
  vertical-align: middle;
}
.spinner-circle {
  position: fixed;
  top: 50%;
  left: 50%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.v-text-field__slot .v-label {
  color: rgb(218, 148, 148) !important;
}
.v-text-field__slot .v-label--active {
  color: rgb(24, 145, 0) !important;
}
.v-select__slot .v-label {
  color: rgb(218, 148, 148) !important;
}
.v-select__slot .v-label--active {
  color: rgb(24, 145, 0) !important;
}
.test {
  color: #ff5555;
}
</style>
