<template>
  <div>
    <v-dialog v-model="show" width="1100" @click:outside.native.stop.prevent="">
      <v-card>
        <v-card-title class="card_title"
          >{{ mode[0].toUpperCase() + mode.substring(1) }} part {{ part.id }}

          <v-spacer></v-spacer>

          <v-icon @click="cancel_edit">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <v-form class="edit_part_form">
            <v-row>
              <v-col>
                <v-text-field
                  hide-details="true"
                  outlined
                  dense
                  label="Supplier"
                  v-model="part.supplier_name"
                />
              </v-col>
            </v-row>
            <v-row class="mt-3 justify-space-between">
              <v-col cols="4">
                <v-text-field
                  hide-details="true"
                  outlined
                  label="Supplier part#"
                  dense
                  v-model="part.supplier_part_number"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-btn
                  color="success"
                  :disabled="!part.supplier_part_number"
                  @click="copy_part_number"
                >
                  COPY &#10140;
                </v-btn>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="4">
                <v-text-field
                  hide-details="true"
                  outlined
                  dense
                  label="WCS part#"
                  v-model="part.wcs_part_number"
                  :class="part_exists ? 'warning' : ''"
                />
              </v-col>
            </v-row>
            <hr />
            <ChildParts
              :parent-id="part.id"
              :has-children="part.has_children"
              @set_has_children="set_has_children"
              @set_production_cost="set_production_cost"
              @apply_production_cost="apply_production_cost"
            ></ChildParts>
            <hr />

            <v-row>
              <v-col cols="6">
                <CatPicker
                  :cat-name="part.category_name"
                  :key="part.category_id"
                  @output="set_cat"
                ></CatPicker>
              </v-col>
              <v-col cols="1">
                <v-text-field
                  hide-details="true"
                  outlined
                  label="(cat_id)"
                  dense
                  v-model="part.category_id"
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="5">
                <v-autocomplete
                  outlined
                  label="Mix & Match"
                  dense
                  hide-details="true"
                  clearable
                  item-text="mm_group"
                  :items="mm_list"
                  :value="part.mm_group"
                  v-model="part.mm_group"
                ></v-autocomplete>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  outlined
                  label="Subcategory"
                  dense
                  clearable
                  item-text="subcategory"
                  :items="sub_cats"
                  item-value="subcategory"
                  v-model="part.subcategory"
                ></v-autocomplete
              ></v-col>
            </v-row>
            <v-row>
              <v-col colspan="12">
                <v-textarea
                  label="Description"
                  outlined
                  dense
                  hint="normal (b)BOLD(/b) normal"
                  rows="1"
                  v-model="part.description"
                />
              </v-col>
            </v-row>
            <hr />
            <v-row>
              <v-col cols="3">
                <v-text-field
                  autofocus
                  hide-details="true"
                  outlined
                  dense
                  :label="
                    part.production_cost > 0
                      ? 'Cost - (' + part.production_cost + ')'
                      : 'Cost'
                  "
                  v-model="part.cost"
                  step=".01"
                  type="number"
                />
              </v-col>
              <!-- wholesale and retail -->
              <v-col cols="3">
                <v-btn
                  class="suggessted_retail_button"
                  v-if="suggested_retail"
                  @click="accept_suggested_retail"
                >
                  <div class="d-flex">
                    <div>
                      <div style="text-transform: none">retail</div>
                      {{ suggested_retail }}
                    </div>
                    <div style="width: 20px">-</div>
                    <div>
                      <div style="text-transform: none">wholesale</div>
                      {{
                        round(
                          suggested_retail -
                            suggested_retail * wholesale_discount
                        )
                      }}
                    </div>
                  </div></v-btn
                >
              </v-col>
              <v-col cols="2">
                <v-text-field
                  dense
                  outlined
                  hide-details="true"
                  label="Retail "
                  v-model="part.retail"
                  step=".01"
                  type="number"
                  @blur="set_wholesale_price()"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  dense
                  outlined
                  hide-details="true"
                  label="Wholesale"
                  v-model="part.wholesale"
                  step=".01"
                  type="number"
                />
              </v-col>
            </v-row>

            <v-row class="mt-5">
              <v-col cols="3">
                <v-text-field
                  dense
                  outlined
                  hide-details="true"
                  label="unit"
                  v-model="part.unit"
                />
              </v-col>
              <v-col cols="3">
                <PercentCalculator
                  :base="Number(part.cost)"
                  :passedMargin="retail_margin"
                  @update_price="update_price($event, 'retail')"
                ></PercentCalculator>
              </v-col>
              <v-col cols="3">
                <!--------------------------------------------------------->

                <DiscountCalculator
                  :base="Number(this.part.retail)"
                  :label="'Wholesale'"
                  :current="wholesale_discount"
                  @update_price="update_price($event, 'wholesale')"
                ></DiscountCalculator>
              </v-col>
              <!------------------------------------------------------>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  hide-details="true"
                  label="  Profit each"
                  disabled
                  :value="money_format(profit)"
                />
              </v-col>
            </v-row>
            <hr />
            <!-- QTY discounts -->
            <v-row>
              <v-col cols="1"> <br />Qty 1</v-col>
              <v-col cols="2">
                <v-text-field
                  hide-details="true"
                  clearable
                  label="Qty"
                  outlined
                  dense
                  v-model="part.q1_unit"
                />
              </v-col>
              <v-col cols="2">
                <v-text-field
                  hide-details="true"
                  outlined
                  dense
                  label="Discount"
                  suffix="%"
                  v-model="part.q1_discount"
                />
              </v-col>
              <v-col cols="3">
                <v-text-field
                  hide-details="true"
                  outlined
                  dense
                  disabled
                  label="Price each"
                  :value="money_format(q1_price, decimal_digits)"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field
                  hide-details="true"
                  outlined
                  dense
                  label="Profit each"
                  disabled
                  :value="money_format(q1_profit)"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- Q2 -->
            <div v-if="Number(part.q1_unit) + Number(part.q2_unit) != ''">
              <v-row>
                <v-col cols="1">Qty 2</v-col>
                <v-col cols="2">
                  <v-text-field
                    hide-details="true"
                    clearable
                    outlined
                    label="Qty"
                    dense
                    v-model="part.q2_unit"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    label="Discount"
                    suffix="%"
                    v-model="part.q2_discount"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    disabled
                    :value="money_format(q2_price, decimal_digits)"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    disabled
                    :value="money_format(q2_profit)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- Q3 -->
            <div v-if="Number(part.q2_unit) + Number(part.q3_unit) != ''">
              <v-row>
                <v-col cols="1">Qty 3</v-col>
                <v-col cols="2">
                  <v-text-field
                    hide-details="true"
                    clearable
                    outlined
                    label="Unit"
                    dense
                    v-model="part.q3_unit"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    label="Discount"
                    suffix="%"
                    v-model="part.q3_discount"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    disabled
                    :value="money_format(q3_price, decimal_digits)"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    disabled
                    :value="money_format(q3_profit)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <!-- Q4 -->
            <div v-if="Number(part.q3_unit) + Number(part.q4_unit) != ''">
              <v-row>
                <v-col cols="1">Qty 4</v-col>
                <v-col cols="2">
                  <v-text-field
                    hide-details="true"
                    clearable
                    outlined
                    dense
                    label="Qty"
                    v-model="part.q4_unit"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    label="Discount"
                    suffix="%"
                    v-model="part.q4_discount"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    disabled
                    :value="money_format(q4_price, decimal_digits)"
                  ></v-text-field>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    hide-details="true"
                    outlined
                    dense
                    disabled
                    :value="money_format(q4_profit)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <hr />
            <v-row>
              <v-col cols="3">
                Reorder level
                <v-text-field
                  outlined
                  dense
                  v-model="part.reorder_level"
                ></v-text-field
              ></v-col>
              <v-col cols="3">
                Order qty
                <v-text-field
                  outlined
                  dense
                  v-model="part.order_qty"
                ></v-text-field
              ></v-col>
              <v-col cols="3">
                Shelf capacity
                <v-text-field
                  outlined
                  dense
                  v-model="part.shelf_capacity"
                ></v-text-field
              ></v-col>
              <v-col cols="3" class="d-flex mt-5">
                <v-checkbox
                  dense
                  true-value="1"
                  false-value="0"
                  class="mini-checkbox"
                  v-model="part.customer_pricelist"
                >
                  <div slot="label" @click.prevent=""></div
                ></v-checkbox>
                <div class="mt-2">Customer pricelist</div></v-col
              >
            </v-row>
            <hr />
            <v-row>
              <v-col cols="2">Notes</v-col>
              <v-col cols="10">
                <v-textarea outlined dense rows="1" v-model="part.notes" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <!--------------footer------------>
        <v-card-actions color="modal_footer" class="footer">
          <v-btn @click="cancel_edit">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="my_mode != 'new'"
            color="error"
            @click="delete_part_flag = true"
          >
            Delete
          </v-btn>
          <v-btn v-if="my_mode != 'new'" color="primary" @click="duplicate()">
            Duplicate
          </v-btn>
          <v-btn
            v-if="my_mode == 'new'"
            color="success"
            :disabled="saveable"
            @click="save_new_part()"
          >
            Save new
          </v-btn>
          <v-btn
            v-if="my_mode == 'edit'"
            color="success"
            :disabled="saveable"
            @click="save()"
          >
            <div class="mt-2">
              Save
              <div class="ctrl-s">ctrl-s</div>
            </div>
          </v-btn>
        </v-card-actions>
      </v-card>
      <DeletePart
        v-if="delete_part_flag"
        :part="passedPart"
        @killme="deleted"
      ></DeletePart>
      <Confirm ref="confirm"></Confirm>
    </v-dialog>
  </div>
</template>

<script>
/* ---------------------------------------------------------------------------------------------- */
/*                                             IMPORTS                                            */
/* ---------------------------------------------------------------------------------------------- */

import axios from 'axios'
import PercentCalculator from '../components/PercentCalculator.vue'
import DiscountCalculator from '../components/DiscountCalculator.vue'
import CatPicker from '../components/CatPicker.vue'
import Confirm from '../components/Confirm.vue'
import DeletePart from '../components/DeletePart.vue'
import ChildParts from '../components/ChildParts.vue'
export default {
  name: 'EditPart',
  components: {
    CatPicker,
    Confirm,
    PercentCalculator,
    DiscountCalculator,
    ChildParts,
    DeletePart
  },
  /* ---------------------------------------------------------------------------------------------- */
  /*                                           COMPUTED: {                                          */
  /* ---------------------------------------------------------------------------------------------- */
  props: {
    passedPart: {
      type: Object,
      default: function() {
        return {}
      }
    },
    mode: {
      type: String,
      default: 'new'
    }
  },
  data() {
    return {
      sub_cats: [],
      part_exists: false,
      part: JSON.parse(JSON.stringify(this.passedPart)),
      show: true,
      suggested_retail: '',
      delete_part_flag: false,
      original_part: { ...this.passedPart },
      wholesale_percent: 0,
      retail_percent: 0,
      q1_percent: '',
      mm_list: this.$store.getters['parts/get_mm_groups'],
      wholesale_discount:
        1 - this.passedPart.wholesale / this.passedPart.retail,
      retail_margin: 0,
      my_mode: this.mode
    }
  },
  computed: {
    retail_markup() {
      return (this.part.retail - this.part.cost) / this.part.cost
    },

    profit() {
      return this.part.wholesale - this.part.cost
    },

    q1_profit() {
      return this.q1_price - this.part.cost
    },
    q2_profit() {
      return this.q2_price - this.part.cost
    },
    q3_profit() {
      return this.q3_price - this.part.cost
    },
    q4_profit() {
      return this.q4_price - this.part.cost
    },
    decimal_digits() {
      return Number(this.part.cost) < 1 ? 3 : 2
    },
    q1_price() {
      return (
        this.part.wholesale -
        (this.part.wholesale * this.part.q1_discount) / 100
      )
    },
    q2_price() {
      return this.q1_price - (this.q1_price * this.part.q2_discount) / 100
    },
    q3_price() {
      return this.q2_price - (this.q2_price * this.part.q3_discount) / 100
    },
    q4_price() {
      return this.q3_price - (this.q3_price * this.part.q4_discount) / 100
    },

    cats() {
      return this.$store.getters['parts/get_parts_cats']
    },
    saveable() {
      var wcs_part_ok = this.part.wcs_part_number ? 1 : 0
      var description_ok = this.part.description ? 1 : 0
      let duplicate = this.part_exists ? 0 : 1
      var cost_ok = this.part.cost ? 1 : 0

      var editing =
        this.$store.getters['parts/get_editing_flag'] == true ? 0 : 1
      var allGood = wcs_part_ok * description_ok * cost_ok * editing * duplicate
      return !allGood
    }
  },
  watch: {
    'part.wcs_part_number': function() {
      this.check_for_duplicate_part_number()
    },
    'part.cost': function() {
      this.suggested_retail = this.round(
        this.part.cost / (1 - this.retail_margin / 100)
      )
    },
    show() {
      this.$emit('killme')
    }
  },
  /* ---------------------------------------------------------------------------------------------- */
  /*                                           MOUNTED() {                                          */
  /* ---------------------------------------------------------------------------------------------- */

  mounted() {
    this.load_cats()
    this.load_parts_sub_cats()
    this.load_mm_groups()
    this.retail_margin = this.round(
      this.round(1 - this.passedPart.cost / this.passedPart.retail, 2) * 100
    )
    // To use ctrl-s to save:
    window.addEventListener('keydown', this.doSave)
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.doSave)
  },
  /* ---------------------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    async load_parts_sub_cats() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parts_sub_cats.php'
        )
        this.sub_cats = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async check_for_duplicate_part_number() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'check_for_duplicate_part_number.php',
          {
            wcs_part_number: this.part.wcs_part_number
          }
        )
        this.part_exists = response.data
      } catch (error) {
        console.log(error)
      }
    },
    set_wholesale_price() {
      this.part.wholesale = this.round(
        this.part.retail - this.part.retail * this.wholesale_discount
      )
    },

    copy_part_number() {
      this.part.wcs_part_number = this.part.supplier_part_number
    },

    /* ------------------------------------- async load_cats() { ------------------------------------ */

    async load_cats() {
      if (this.cats.length < 1) {
        try {
          const response = await axios.post(
            process.env.VUE_APP_API_URL + 'load_parts_cats.php',
            {
              key: this.$store.state.mykey
            }
          )

          this.$store.dispatch('parts/set_parts_data', {
            param: 'cats',
            value: response.data
          })
        } catch (error) {
          console.log(error)
        }
      }
    },

    /* ---------------------------------------- set_cat(x) { ---------------------------------------- */

    set_cat(x) {
      this.part.category_name = x.category_name
      this.part.category_id = x.category_id
    },

    async load_mm_groups() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_mm_groups.php',
          {}
        )
        this.mm_list = response.data
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },

    /* ----------------------------------- async save_new_part() { ---------------------------------- */

    async save_new_part() {
      if (isNaN(this.part.retail) || isNaN(this.part.wholesale)) {
        alert('invalid price')
        return
      }
      delete this.part.noob
      delete this.part.category_name
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_new_part.php',
          {
            part: this.part
          }
        )
        console.log(
          `%c${JSON.stringify(response.data)}`,
          'color:#40d9ff;font-weight:bold'
        )
        this.$store.dispatch('parts/replace_parts_part', {
          param: 'parts',
          key: 'id',
          id: this.part.id,
          value: this.part
        })
        this.$emit('addme', response.data)
      } catch (error) {
        console.log(error)
      }
      this.show = false
    },

    /* --------------------------------------- async save() { --------------------------------------- */

    async save() {
      let part_copy = { ...this.part }
      if (isNaN(this.part.retail) || isNaN(this.part.wholesale)) {
        alert('invalid price')
        return
      }
      part_copy.old_cost = this.passedPart.cost
      part_copy.old_wholesale = this.passedPart.wholesale
      part_copy.old_retail = this.passedPart.retail
      delete part_copy.category_name

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_part.php',
          {
            part: part_copy
          }
        )
        console.log(response.data)
        this.$store.dispatch('parts/replace_parts_part', {
          param: 'parts',
          key: 'id',
          id: this.part.id,
          value: this.part
        })
        this.show = false
      } catch (error) {
        console.log(error)
      }
    },
    /* --------------------------------------- cancel_edit() { -------------------------------------- */

    async cancel_edit() {
      if (_.isEqual(this.part, this.original_part) == false) {
        if (
          await this.$refs.confirm.open(
            'Unsaved Changes',
            'Are you sure you want to close this form without saving?',
            { color: 'red', textColor: 'white' }
          )
        ) {
          this.show = false
          return
        }
        return
      }
      this.show = false
    },
    /* --------------------------------- update_price(price, type) { -------------------------------- */

    update_price({ price, percent }, type) {
      if (type == 'wholesale') {
        this.part.wholesale = price
        this.wholesale_discount = percent
        this.set_wholesale_price()
      }
      if (type == 'retail') {
        this.part.retail = price
        this.retail_margin = percent
        this.set_wholesale_price()
      }
    },
    accept_suggested_retail() {
      this.part.retail = this.suggested_retail
      this.set_wholesale_price()
    },

    /* ----------------------------------------- deleted() { ---------------------------------------- */

    deleted() {
      this.delete_part_flag = false
      this.$emit('killme')
    },

    /* ---------------------------------------- duplicate(){ ---------------------------------------- */

    duplicate() {
      this.check_for_duplicate_part_number()
      this.my_mode = 'new'
      this.part.id = null
    },
    // To use ctrl-s to save:
    doSave(e) {
      if (!(e.keyCode === 83 && (e.ctrlKey || e.metaKey))) {
        return
      }
      e.preventDefault()

      if (!this.saveable) {
        this.save()
      }
    },
    set_has_children(x) {
      this.$set(this.part, 'has_children', x)
    },
    set_production_cost(x) {
      // alert(x)
      this.$set(this.part, 'production_cost', x)
    },
    apply_production_cost(x) {
      this.$set(this.part, 'cost', x)
    }
  }
}
</script>

<style scoped>
::v-deep hr {
  margin: 10px 0px 10px 0px;
  width: 100%;
  border-top: 2px solid #607d8b;
}

::v-deep .warning {
  color: red;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -5px;
}
.card_title {
  background-color: #607d8b;
  color: white;
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
.footer {
  background-color: #c7d0d4;
}
.col {
  padding: 3px 3px 0px 3px;
}
.row {
  margin: 5px 0px -3px 0px;
}

.mini-checkbox {
  width: 30px;
}
.ctrl-s {
  font-size: 0.9em;
  line-height: 1em;
  padding: 0;
  margin-top: -3px;
  text-transform: none !important;
}
</style>
