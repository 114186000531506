<template>
  <v-dialog v-model="show">
    <v-card height="1000">
      <v-card-text><iframe id="printPdf" name="printPdf"></iframe></v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'InventoryWarehousePDF',
  props: {
    warehouse_name: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: function() {
        return []
      }
    },
    parts: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      show: true,
      items: [],
      header: [
        {
          text: 'Supplier',
          fontSize: 10,
          color: '#000',
          border: [false, false, false, false]
        },
        {
          text: 'WCS#',
          fontSize: 10,
          color: '#000',
          border: [false, false, false, false]
        },
        {
          text: 'Description',
          fontSize: 10,
          color: '#000',
          border: [false, false, false, false]
        },
        {
          text: 'Qty',
          fontSize: 10,
          color: '#000',
          border: [false, false, false, false]
        },
        // Commented out for finished copy, add back next time
        {
          text: 'New',
          fontSize: 10,
          color: '#000',
          border: [false, false, false, false]
        },
        {
          text: 'Unit',
          fontSize: 11,
          color: '#000',
          border: [false, false, false, false]
        },
        {
          text: 'Cost per unit',
          fontSize: 10,
          color: '#000',
          border: [false, false, false, false]
        }
      ],

      body: { fontSize: 9, color: 'black' } // used as a style
    }
  },
  watch: {
    show() {
      this.$emit('killme')
    }
  },
  computed: {
    row() {
      // This takes the original data and creates a new array for each element of the original.  Then we can pick and choose fields.
      var newarray = []
      this.data.forEach(function(item) {
        let part = this.get_part(item.part_id)
        if (!part) {
          return
        }
        newarray.push([
          {
            stack: [
              {
                text: part.supplier_name,
                style: this.body,
                fontSize: 8
              },
              { text: part.supplier_part_number, fontSize: 8 }
            ],
            borderColor: ['#aaa', '#aaa', '#eee', '#aaa']
          },

          {
            stack: [
              {
                text: part.wcs_part_number,
                style: this.body
              },
              {
                text: item.part_id,
                style: this.body,
                alignment: 'right',
                color: '#555',
                fontSize: 7
              }
            ],
            borderColor: ['#eee', '#aaa', '#eee', '#aaa']
          },

          {
            text: part.description,
            borderColor: ['#eee', '#aaa', '#eee', '#aaa'],

            style: this.body
          },
          {
            text: item.qty,
            style: this.body,
            borderColor: ['#eee', '#aaa', '#555', '#aaa'],
            margin: [0, 5, 0, 0],
            alignment: 'center'
          },
          // Commented out for finished copy, add back next time
          {
            text: '',
            height: 30,
            alignment: 'right',
            borderColor: ['#555', '#555', '#555', '#555'],
            border: [true, true, true, true]
          },
          {
            text: part.unit,
            borderColor: ['#eee', '#aaa', '#eee', '#aaa'],
            margin: [0, 5, 0, 0],

            style: this.body
          },
          {
            text: this.money_format(part.cost),
            style: this.body,
            borderColor: ['#eee', '#aaa', '#aaa', '#aaa'],
            margin: [0, 5, 0, 0],

            alignment: 'right'
          }
        ])
      }, this)
      newarray.unshift(this.header)
      return newarray
    }
  },
  mounted() {
    this.generate()
  },

  /* ----------- ----------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    get_part(part_id) {
      return this.parts.filter(x => x.id == part_id)[0] || false
    },
    /* ---------------------------------------- generate() { ---------------------------------------- */
    generate() {
      var date = dayjs().format('M/D/YYYY h:mm A')
      //console.log(date)
      var docDefinition = {
        header: {
          text: this.warehouse_name,
          alignment: 'center',
          margin: [0, 10, 0, 0]
        },

        footer: function(currentPage, pageCount) {
          return {
            table: {
              body: [
                [
                  {
                    text:
                      'Printed ' +
                      date +
                      ' Page ' +
                      currentPage.toString() +
                      ' of ' +
                      pageCount,
                    alignment: 'right',
                    style: 'normalText',
                    margin: [40, -7, 0, 30]
                  }
                ]
              ]
            },
            fontSize: 8,
            layout: 'noBorders'
          }
        },
        content: [
          {
            table: {
              dontBreakRows: true,
              headerRows: 1,
              body: this.row,
              heights: 20,
              widths: [70, 70, 200, 30, 30, 25, 70]
            },
            layout: 'inventory'
          }
        ],
        pageMargins: [20, 30, 20, 20],
        pageSize: 'LETTER'
      }
      //   pdfMake.createPdf(docDefinition).download("Directory.pdf");
      pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf'])
    }

    /* ----------------------------------- async load_items() { ----------------------------------- */
  }
}
</script>

<style scoped>
#printPdf {
  position: absolute;
  top: 0px;
  display: inline;
  height: 1000px;
  width: 1200px;
}
</style>
