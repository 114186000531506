<template>
  <div>
    <b-modal ref="modal" size="xl" @hide="$emit('killme')">
      <template #modal-header>
        Price history for {{ passedPart.wcs_part_number }}
      </template>
      <table class="table-sm table-hover">
        <thead>
          <th>Date</th>
          <th>Old amount</th>
          <th>New amount</th>
          <th>Change amount</th>
          <th>Change %</th>
          <th>Price type</th>
          <th>Updated by</th>
        </thead>
        <tbody v-if="changes.length > 0">
          <tr v-for="change in changes" :key="change.id">
            <td>{{ format_date(change.date) }}</td>
            <td>{{ money_format(change.old_price) }}</td>
            <td>{{ money_format(change.new_price) }}</td>
            <td :class="change.new_price > change.old_price ? 'green' : 'red'">
              {{ money_format(change.new_price - change.old_price) }}
            </td>
            <td>
              {{ percent_format(change.new_price / change.old_price - 1) }}
            </td>
            <td>{{ change.type }}</td>
            <td>{{ change.updated_by }}</td>
          </tr>
        </tbody>
      </table>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'PriceHistory',
  props: {
    passedPart: {
      type: Object,
      default: function () {
        return {}
      }
    },
    priceType: {
      type: String,
      default: 'cost'
    }
  },
  data() {
    return {
      changes: []
    }
  },

  mounted() {
    this.$refs.modal.show()
    this.load_price_history()
  },

  methods: {
    async load_price_history() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'get_price_history.php',
          {
            part: this.passedPart,
            type: this.priceType
          }
        )
        this.changes = response.data
        console.table(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    format_date(d) {
      return moment(d).format('MM/DD/YYYY h:mm a')
    }
  }
}
</script>

<style scoped>
td {
  border: solid black 1px;
  padding: 5px;
  text-align: center;
}
tr td:nth-child(1) {
  min-width: 10px;
}
tr td:nth-child(2) {
  text-align: right;
}
tr td:nth-child(3) {
  text-align: right;
}
.green {
  color: green;
}
.red {
  color: red;
}
</style>
