<template>
  <tr v-if="passedPart.id">
    <td>
      <div class="supplier">{{ part.supplier_name }}</div>
      <div class="supplier_id">{{ part.supplier_part_number }}</div>
    </td>
    <td>
      <div class="part_number">{{ part.wcs_part_number }}</div>
      <div class="id">{{ part.id }}</div>
    </td>
    <td>
      {{ part.description }}
    </td>
    <td>
      <div class="qty">{{ passedItem.qty }} {{ total_qty }}</div>
    </td>
    <td v-if="update_mode == true">
      <v-text-field
        :class="saved == true ? 'saved' : ''"
        dense
        hide-details="true"
        outlined
        label="new qty"
        v-model="qty"
        type="number"
        @change="update_qty"
      ></v-text-field>
    </td>
    <td>
      {{ part.unit }}
    </td>
    <td>{{ money_format(part.cost) }}</td>

    <td v-if="total_qty">
      {{ money_format(Number(part.cost) * Number(total_qty)) }}
    </td>
    <td v-if="passedItem.qty">
      <v-icon class="trash" tabindex="-1" @click="deleteme"
        >mdi-trash-can</v-icon
      >
      <Confirm ref="confirm"></Confirm>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import Confirm from '@/components/Confirm.vue'
export default {
  name: 'SingleInventoryItem',
  components: {
    Confirm
  },
  props: {
    passedPart: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passedItem: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passedInventory: {
      type: Array,
      default: function () {
        return []
      }
    },
    update_mode: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  data() {
    return {
      part: { ...this.passedPart },
      saved: false,
      qty: null
    }
  },
  computed: {
    total_qty() {
      if (this.passedInventory.length > 0) {
        return _.sumBy(this.passedInventory, x => Number(x.qty))
      }
    }
  },
  methods: {
    /* ----------------------------------------- update_qty ----------------------------------------- */
    async update_qty() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'update_inventory_qty.php',
          {
            warehouse_id: this.passedItem.warehouse_id,
            part_id: this.passedItem.part_id,
            qty: this.qty,
            user: this.$store.state.profile.name
          }
        )
        console.log(response.data)
        if ((response.data = 1)) {
          this.saved = true
        }
        // if ((this.passedItem.qty = this.qty)) {
        //   this.saved = true
        // }
      } catch (error) {
        console.log(error)
      }
    },
    /* ------------------------------------------ deleteme ------------------------------------------ */
    async deleteme() {
      if (
        await this.$refs.confirm.open(
          'Delete item ',
          'Are you sure you want to remove this part from the warehouse?',
          { color: 'red', textColor: 'white' }
        )
      ) {
        try {
          const response = await axios.post(
            process.env.VUE_APP_API_URL + 'delete_inventory_item.php',
            {
              warehouse_id: this.passedItem.warehouse_id,
              part_id: this.passedItem.part_id
            }
          )
          this.$emit('deleteme', this.passedItem)
          console.log(response.data)
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
}
</script>

<style scoped>
.id {
  font-size: 0.7em;
  color: purple;
  text-align: right;
}
.supplier_id {
  font-size: 0.7em;
  color: purple;
}
.qty {
  text-align: right;
}
.saved {
  background-color: rgb(199, 255, 199);
}
.trash:hover {
  color: red;
}
</style>