<template>
  <div>
    <div style="width: 100px">
      <v-btn-toggle v-model="type">
        <div class="d-flex flex-column">
          <v-btn
            class="mybutton"
            value="wholesale"
            @click="load_clients('wholesale')"
            >Wholesale</v-btn
          >
          <v-btn class="mybutton" value="retail" @click="load_clients('retail')"
            >retail</v-btn
          >
          <v-btn class="mybutton" value="Fire" @click="load_clients('Fire-Co')"
            >Fire-Co</v-btn
          >
          <v-btn
            class="mybutton"
            value="Disability"
            @click="load_clients('Disability')"
            >Disability</v-btn
          >
          <v-btn class="mybutton" value="Other" @click="load_clients('Other')"
            >Other</v-btn
          >
          <v-btn
            class="mybutton"
            value="Supplier"
            @click="load_clients('Supplier')"
            >Supplier</v-btn
          >
          <v-btn
            class="mybutton"
            value="Transport"
            @click="load_clients('Transport')"
            >Transport</v-btn
          >
          <v-btn
            class="mybutton"
            value="Personal"
            @click="load_clients('Personal')"
            >Personal</v-btn
          >
        </div>
      </v-btn-toggle>
    </div>
    <div class="background">
      <v-progress-circular
        v-if="loading"
        :size="50"
        class="spinner"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-alert color="success" outlined width="150" show>
      <h3>{{ type }}</h3>
      {{ count }} clients
    </v-alert>
    <iframe id="printPdf" name="printPdf"></iframe>
  </div>
</template>

<script>
import axios from 'axios'
import pdfMake from 'pdfmake/build/pdfmake'

export default {
  name: 'PocketDirectory',
  data() {
    return {
      clients: [],
      loading: false,
      type: '',
      main_layout: {
        defaultBorder: false,
        paddingLeft: function () {
          return 0
        },
        paddingRight: function () {
          return 0
        },
        paddingTop: function () {
          return 3
        },
        paddingBottom: function () {
          return 1
        },
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0
          }
          return i === node.table.headerRows ? 2 : 1
        },
        vLineWidth: function () {
          return 0
        },
        hLineColor: function (i) {
          return i === 0 ? 'black' : '#aaa'
        },
        fillColor: function (rowIndex) {
          return rowIndex % 2 === 0 ? '#EEEEEE' : null
        }
      }
    }
  },

  computed: {
    count() {
      return this.clients.length
    },
    rows() {
      var newarray = []
      //! Begin Loop-----------------------
      this.clients.forEach(client => {
        let row = {}

        /* ---------------------------------------- Company info ---------------------------------------- */

        let company_info = {
          text: client.company,
          // text: client.company + '(' + client.client_type.charAt(0) + ')', // to show the type and verify section sorting
          fontSize: 10,
          bold: true
        }

        /* ------------------------------------------ Name info ----------------------------------------- */

        let name_info = {
          text:
            client.last_name +
            client.suffix +
            client.first_name +
            client.middle_initial,
          fontSize: 9,
          marginLeft: 9,
          bold: false
        }

        let phone_info = [{ text: '\u00A0' }] // troubleshooting line break issues.  not really needed
        phone_info.push({
          text: client.phone_1 + ' ',
          fontSize: 10,
          bold: true
        })

        if (client.phone_1_text)
          phone_info.push({
            text: client.phone_1_text + ' ',
            fontSize: 9,
            bold: true
          })
        if (client.phone_2)
          phone_info.push({
            text: client.phone_2 + ' ',
            fontSize: 10,
            bold: false,
            color: 'grey'
          })
        if (client.phone_2_text)
          phone_info.push({
            text: client.phone_2_text + ' ',
            fontSize: 9,
            bold: false,
            color: 'grey'
          })
        if (client.phone_3)
          phone_info.push({
            text: client.phone_3 + ' ',
            fontSize: 10,
            bold: true
          })
        if (client.phone_3_text)
          phone_info.push({
            text: client.phone_3_text + ' ',
            fontSize: 9,
            bold: true
          })
        if (client.phone_4)
          phone_info.push({
            text: client.phone_4 + ' ',
            fontSize: 10,
            bold: false,
            color: 'grey'
          })
        if (client.phone_4_text)
          phone_info.push({
            text: client.phone_4_text + ' ',
            fontSize: 9,
            bold: false,
            color: 'grey'
          })
        if (client.fax)
          phone_info.push({
            text: 'FAX: ' + client.fax + ' ',
            fontSize: 9,
            italics: true
          })

        /* ---------------------------------------- Address info ---------------------------------------- */
        let address_string =
          client.address1 +
          client.address2 +
          client.city +
          client.state +
          client.zip
        //split by hyphens
        let address_info = []
        let split_array = address_string.split('-')
        if (split_array.length == 1) {
          address_info.push({
            text: address_string.replace(/\s/g, '\u00A0'), //Replace spaces with non breaking spaces
            fontSize: 8
          })
        } else {
          // Since Myriad does not have a non-breaking hyphen, we use Roboto just for that character
          split_array.forEach((x, index) => {
            address_info.push({ text: x.replace(/\s/g, '\u00A0'), fontSize: 8 }) //Replace spaces with non breaking spaces
            if (index < split_array.length - 1)
              address_info.push({ text: '\u2011', font: 'Roboto', fontSize: 8 }) // Insert non breaking hyphens
          })
        }

        /* ------------------------------------- Business Type Info ------------------------------------- */

        let business_type_info = {
          text: client.business_type + ' ',
          fontSize: 8,
          color: 'grey'
        }

        /* ----------------------------------------- Notes info ----------------------------------------- */

        let notes_info = {
          text: ' (' + client.notes + ')',
          fontSize: 8
        }

        /* -------------- Now all the variables are defined, we combine them into an array -------------- */

        row.text = [company_info]
        row.text.push(name_info)
        phone_info.forEach(x => row.text.push(x)) // This is a loop since there can be more than one phone number
        address_info.forEach(x => row.text.push(x))
        if (client.business_type) row.text.push(business_type_info)
        if (client.notes) row.text.push(notes_info)
        // row.pageBreak = client.pageBreak //  We created the pagebreak in intiialize function

        newarray.push([row])
      }, this) //! End Loop --------------------------

      //newarray.unshift(this.header);   this line was used to insert the header titles onto a table.
      return newarray
    }
  },
  mounted() {
    // this.load_clients()
    // this.initializeClient()
  },

  /* ----------- ----------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    initializeClient() {
      /* -------------------------- Insert page breaks between customer types ------------------------- */

      // this.clients.forEach((client, index) => {
      //   if (client.client_id == 'page_break') {
      //     this.clients[index - 1].pageBreak = 'after'
      //     this.clients.splice(index, 1)
      //     return true
      //   }
      // })

      /* ------------------------------------ Add spaces and commas ----------------------------------- */
      this.clients.forEach(client => {
        let needs_space = ['company', 'address1', 'address2', 'zip']
        needs_space.forEach(key => {
          if (client[key]) client[key] += ' '
        })
        // Adding NON BREAKING spaces for name
        if (client.last_name) client.last_name += ',\u00A0'
        if (client.first_name) client.first_name += '\u00A0'
        if (client.middle_initial) client.middle_initial += ' '
        if (client.suffix) client.suffix += '\u00A0'
        if (client.state) client.state = ', ' + client.state + ' '
        if (client.company) client.company += '  '
        return true
      })

      /* ---------------------------------------- PHONE NUMBERS --------------------------------------- */
      this.clients.forEach(x => {
        var phone_number_array = []
        // var phone_number_array = [];
        if (x.phone_1 !== undefined && x.phone_1 !== '') {
          phone_number_array.push({
            number: x.phone_1,
            text: ' ' + x.phone_1_text
          })
        }
        if (x.phone_2 !== undefined && x.phone_2 !== '') {
          phone_number_array.push({
            number: x.phone_2,
            text: ' ' + x.phone_2_text
          })
        }
        if (x.phone_3 !== undefined && x.phone_3 !== '') {
          phone_number_array.push({
            number: x.phone_3,
            text: ' ' + x.phone_3_text
          })
        }
        if (x.fax !== undefined && x.fax !== '') {
          phone_number_array.push({ number: x.fax, text: ' fax' })
        }
        x.phoneNumbers = phone_number_array
      })
    },
    /* ---------------------------------------- generate() { ---------------------------------------- */
    //http://pdfmake.org/playground.html for examples

    generate() {
      var tmpdoc = document.getElementById('printPdf').contentWindow.document
      tmpdoc.open()
      tmpdoc.write('Loading pocket directory...')
      tmpdoc.close()
      //this.initializeClient()

      var docDefinition = {
        content: [
          {
            table: { body: this.rows, dontBreakRows: true },
            layout: this.main_layout
          }
        ],
        defaultStyle: {
          font: 'Myriad'
        },
        pageMargins: [19, 9, 18, 9],
        pageSize: { width: 252, height: 450 }
      }
      pdfMake.fonts = {
        // Myriad was recommended since that is what their previous report used
        Myriad: {
          normal: process.env.VUE_APP_FONT_URL + 'Myriad-Pro-Condensed.ttf',
          bold: process.env.VUE_APP_FONT_URL + 'Myriad-Pro-Bold-Condensed.ttf',
          italics:
            process.env.VUE_APP_FONT_URL + 'Myriad-Pro-Condensed-Italic.ttf'
        },

        Roboto: {
          normal:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf'
        }
      }
      pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf'])
    },

    /* ----------------------------------- async load_clients() { ----------------------------------- */

    async load_clients(type) {
      this.loading = true
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_pocket_directory_report.php',
          {
            type: type,
            search: ''
          }
        )
        this.clients = response.data // This is where we get the data
        // Validated 02/09/2021
        this.validate_client(this.clients[0])
        await this.initializeClient()
        await this.generate()
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.background {
  position: absolute;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
}
.spinner {
  margin: 99px;
  left: 500px;
}
#printPdf {
  position: absolute;
  top: 0px;
  left: 150px;
  display: inline;
  height: 1000px;
  width: 1200px;
}
.mybutton {
  cursor: pointer;
}
</style>
