<template>
  <v-card>
    <v-card-title>
      <v-btn color="primary" class="mr-5" @click="update_production_costs(true)"
        >Update Prod cost</v-btn
      >
      <v-btn color="primary" class="mr-5" @click="save_all"
        >Update Saved cost & prices</v-btn
      >
    </v-card-title>
    <v-data-table
      :items="results"
      item-key="id"
      :items-per-page="50"
      :headers="headers"
    >
      <template v-slot:item.production_cost="{ item }">
        <v-chip :color="getColor(item.production_cost, item.sum)" dark>
          {{ item.production_cost }}
        </v-chip>
      </template>
      <template v-slot:item.retail="{ item }">
        {{ item.retail }}
        <br />
        margin {{ get_current_margin(item) * 100 }}
      </template>
      <template v-slot:item.wholesale="{ item }">
        {{ item.wholesale }}<br />
        discount
        {{ get_discount(item) }}
      </template>

      <template v-slot:item.new_retail="{ item }">
        {{ get_new_retail(item) }}<br />
        margin: {{ get_new_margin(item) * 100 }}
      </template>
      <template v-slot:item.new_wholesale="{ item }">
        {{ get_new_wholesale(item) }}<br />
        discount
        {{ get_new_discount(item) }}
      </template>
      <template v-slot:item.save="{ item }">
        <div :class="is_same(item)">
          <v-btn
            :color="is_same(item)"
            :disabled="is_same(item) == 'secondary'"
            small
            @click="save_new_costs(item)"
            >Save</v-btn
          >
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from 'axios'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      results: [],
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Part#', value: 'wcs_part_number' },
        { text: 'Desc', value: 'description' },
        { text: 'Cost', value: 'cost' },
        { text: 'Retail', value: 'retail' },
        { text: 'Wholesale', value: 'wholesale' },
        { text: 'Prod cost', value: 'production_cost' },
        { text: 'Sum of children', value: 'sum' },
        { text: 'New retail', value: 'new_retail' },
        { text: 'New wholesale', value: 'new_wholesale' },
        { text: 'Save', value: 'save' }
      ]
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.update_production_costs(false)
  },
  methods: {
    async save_all() {
      for (const item of this.results) {
        if (this.is_same(item) != 'secondary') await this.save_new_costs(item)
      }
    },
    is_same(item) {
      return this.money_format(item.cost) ==
        this.money_format(item.production_cost) &&
        this.money_format(item.retail) ==
          this.money_format(this.get_new_retail(item)) &&
        this.money_format(item.wholesale) ==
          this.money_format(this.get_new_wholesale(item))
        ? 'secondary'
        : 'success'
    },
    get_current_margin(item) {
      return this.round((item.retail - item.cost) / item.retail, 2)
      // return this.round(1 - item.cost / item.retail, 2) * 100
    },

    get_new_margin(item) {
      return this.round(
        (this.get_new_retail(item) - item.production_cost) /
          this.get_new_retail(item),
        2
      )
      // return this.round(1 - item.cost / item.retail, 2) * 100
    },
    get_discount(item) {
      return this.round(1 - item.wholesale / item.retail)
    },
    get_new_retail(item) {
      return this.round(
        item.production_cost / (1 - this.get_current_margin(item)),
        2
      )
    },
    get_new_wholesale(item) {
      return this.round(
        this.get_new_retail(item) -
          this.get_new_retail(item) * this.get_discount(item)
      )
    },
    get_new_discount(item) {
      return this.round(
        1 - this.get_new_wholesale(item) / this.get_new_retail(item)
      )
    },
    async update_production_costs(update) {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'update_production_costs.php',
          {
            update: update
          }
        )
        this.results = response.data
        // this.count_updated = response.data
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    async save_new_costs(item) {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'accept_parent_costs.php',
          {
            part_id: item.id,
            old_cost: item.cost,
            new_cost: item.production_cost,
            old_retail: item.retail,
            new_retail: this.get_new_retail(item),
            old_wholesale: item.wholesale,
            new_wholesale: this.get_new_wholesale(item)
          }
        )
        this.results = response.data
        // this.count_updated = response.data
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    getColor(cost, sum) {
      return this.money_format(cost) == this.money_format(sum) ? 'green' : 'red'
    }
  }
}
</script>

<style scoped>
.card_title {
  background-color: rgb(212, 212, 212);
  color: rgb(0, 0, 0);
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
::v-deep .v-text-field__slot .v-label {
  color: rgb(218, 148, 148) !important;
}
::v-deep .v-text-field__slot .v-label--active {
  color: rgb(24, 145, 0) !important;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -7px;
}
::v-deep .mycheckbox {
  padding: 0px 0px 0px 0px;
}
.same {
  color: green;
  background-color: green;
}
.different {
  color: red;
  background-color: red;
}
</style>
