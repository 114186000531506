<template>
  <v-container>
    Choose one, then click "Export CSV"
    <br />
    <v-btn-toggle v-model="type">
      <v-btn color="primary" value="retail">Retail newsletter</v-btn>
      <v-btn color="primary" value="wholesale">Wholesale newsletter</v-btn>
    </v-btn-toggle>
    <br />
    <br />
    <v-btn :href="csvURL" color="primary">Export CSV</v-btn>
  </v-container>
</template>

<script>
export default {
  name: 'MailingLists',
  components: {},
  props: {},
  data() {
    return {
      type: ''
    }
  },
  computed: {
    csvURL() {
      return `${process.env.VUE_APP_API_URL}newsletter_mailing_list_CSV.php?type=${this.type}`
    }
  },
  watch: {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.card_title {
  background-color: rgb(212, 212, 212);
  color: rgb(0, 0, 0);
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
::v-deep .v-text-field__slot .v-label {
  color: rgb(218, 148, 148) !important;
}
::v-deep .v-text-field__slot .v-label--active {
  color: rgb(24, 145, 0) !important;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -7px;
}
::v-deep .mycheckbox {
  padding: 0px 0px 0px 0px;
}
</style>
