<template>
  <v-card>
    <v-simple-table
      lazy
      style="width: 800px"
      height="calc(100vh - 20px)"
      fixed-header
    >
      <thead>
        <tr>
          <th>
            <v-text-field
              hide-details="true"
              dense
              label="tag search"
              outlined
              clearable
              v-model="tag_filter"
            ></v-text-field>
          </th>
          <th>
            <v-text-field
              hide-details="true"
              dense
              outlined
              label="part# search"
              clearable
              v-model="number_filter"
            ></v-text-field>
          </th>
        </tr>
        <tr>
          <th>
            <v-text-field
              hide-details="true"
              dense
              label="new tag"
              outlined
              clearable
              v-model="new_tag"
            ></v-text-field>
          </th>
          <th>
            <v-text-field
              hide-details="true"
              dense
              label="new_part#"
              outlined
              clearable
              v-model="new_number"
            >
              <template v-slot:append>
                <v-icon :disabled="!saveable" @click="save_new_tag"
                  >mdi-content-save</v-icon
                >
              </template>
            </v-text-field>
          </th>
        </tr>
      </thead>
      <tbody>
        <SingleIndexTag
          v-for="(tag, index) in grouped_tags"
          :key="index + increment"
          :passedTag="tag"
          @deleted="delete_part_number"
        ></SingleIndexTag>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import SingleIndexTag from '../components/SingleIndexTag'
export default {
  name: 'IndexEntry',
  components: {
    SingleIndexTag
  },
  data() {
    return {
      tags: [],
      tag_filter: '',
      number_filter: '',
      new_tag: '',
      new_number: '',
      increment: 0
    }
  },
  computed: {
    filtered_tags() {
      if (this.tag_filter) {
        return this.tags.filter(x => {
          return x.tag.toLowerCase().includes(this.tag_filter.toLowerCase())
        })
      }
      if (this.number_filter) {
        return this.tags.filter(x => {
          return x.wcs_part_number
            .toLowerCase()
            .includes(this.number_filter.toLowerCase())
        })
      }
      return this.tags
    },
    grouped_tags() {
      return _.groupBy(this.filtered_tags, x => x.tag)
    },
    saveable() {
      return this.new_number && this.new_tag
    },
    tag() {
      return {
        tag: this.new_tag,
        wcs_part_number: this.new_number,
        created_by: this.$store.state.profile.name
      }
    }
  },
  mounted() {
    this.load_index()
  },
  methods: {
    async load_index() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_index.php'
        )
        // console.table(response.data)
        this.tags = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async save_new_tag() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_new_tag.php',
          {
            tag: this.new_tag,
            created_by: this.$store.state.profile.name,
            wcs_part_number: this.new_number
          }
        )
        console.log(response.data)
        this.tags.push({ ...this.tag, id: response.data.id })
        this.new_number = ''
      } catch (error) {
        console.log(error)
      }
    },
    delete_part_number(x) {
      let index = this.tags.indexOf(tag => (tag.id = x))
      this.tags.splice(index, 1)
      this.increment++
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep table tr td {
  padding: 20px !important;
}
</style>