<template>
  <tr>
    <td>
      <v-text-field
        outlined
        dense
        hide-details="true"
        v-model="tag[0].tag"
      ></v-text-field>
    </td>

    <td>
      <SingleIndexTagPart
        v-for="(part, index) in parts"
        :key="index"
        :passedPart="part"
        @deleted="delete_part_number"
      >
      </SingleIndexTagPart>
    </td>
  </tr>
</template>

<script>
import SingleIndexTagPart from '../components/SingleIndexTagPart'
export default {
  name: 'SingleIndexTag',
  components: {
    SingleIndexTagPart
  },
  props: {
    passedTag: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      tag: { ...this.passedTag }
    }
  },
  computed: {
    parts() {
      let array = []
      this.passedTag.forEach(x => {
        array.push(x)
      })
      return array
    }
  },
  methods: {
    delete_part_number(x) {
      this.$emit('deleted', x)
    }
  }
}
</script>

<style scoped>
</style>