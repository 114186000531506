<template>
  <v-dialog v-model="show" width="1000">
    <v-card fluid class="main">
      <v-card-title class="card_title">
        {{ mode == 'new' ? 'New event for ' + name : 'Edit event for ' + name }}
      </v-card-title>
      <v-card-text class="pt-5">
        <v-row>
          <v-col class="col-md-5">
            <v-text-field
              outlined
              dense
              label=" Event date"
              type="date"
              v-model="event.event_date"
            ></v-text-field>
          </v-col>
          <v-col class="col-md-3">
            <v-autocomplete
              outlined
              dense
              label="Event type"
              v-model="event.event_type"
              :items="event_types"
            ></v-autocomplete>
          </v-col>
          <v-col class="col-md-3">
            <v-text-field
              outlined
              dense
              label="Carriage type"
              v-model="event.carriage_type"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-md-3">
            <v-text-field
              outlined
              label="Job number"
              dense
              v-model="event.job_number"
            ></v-text-field>
          </v-col>
          <v-col class="col-md-3">
            <v-text-field
              outlined
              label="WC Serial number"
              dense
              v-model="event.wc_serial_no"
            ></v-text-field>
          </v-col>
          <v-col class="col-md-5">
            <v-text-field
              outlined
              dense
              label="Notes"
              v-model="event.notes"
            ></v-text-field>
          </v-col>
        </v-row>
        <MyEvents
          v-if="show_history_flag == true"
          :client_id="r.client_id"
        ></MyEvents>
      </v-card-text>

      <!-- footer for customer edit modal -->
      <v-card-actions>
        <v-btn
          color="primary"
          :outlined="show_history_flag == true ? false : true"
          @click="show_history_flag = !show_history_flag"
          >Show history</v-btn
        >
        <v-spacer></v-spacer>
        <!-- SAVE BUTTON -->
        <v-btn v-if="mode == 'Edit'" color="success" @click="save_event()">
          Save
        </v-btn>
        <v-btn v-else color="success" @click="save_new_event()"
          >Save new event</v-btn
        >
        <!-- ---- -->
        <!-- <v-btn v-if="mode == 'Edit'" color="error" @click="delete_event()">
          Delete
        </v-btn> -->
        <v-btn size="sm" color="secondary" @click="show = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EditEvent',
  components: {
    MyEvents: () => import('../components/MyEvents.vue')
  },
  props: {
    r: {
      type: Object,
      default: function () {
        return {}
      }
    },
    passedEvent: {
      type: Object,
      default: function () {
        return {}
      }
    },

    mode: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      customer: { ...this.r },
      event: { ...this.passedEvent, client_id: this.r.client_id },
      show_history_flag: false,
      show: true,
      event_types: [
        'Amish repair',
        'Restoration',
        'New Amish carriage',
        'Regular Repair'
      ]
    }
  },
  computed: {
    name() {
      return (
        this.customer.company ||
        this.customer.first_name + ' ' + this.customer.last_name
      )
    }
  },
  watch: {
    show() {
      this.$emit('killme')
    }
  },
  mounted() {
    this.show = true
  },
  methods: {
    async save_new_event() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_new_event.php',
          {
            event: this.event
          }
        )
        console.log('%csaved', 'color:red;font-weight:bold', response.data)
        this.$emit('add', this.event)

        this.show = false
      } catch (error) {
        console.log(error)
      }
    },
    async save_event() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_event.php',
          {
            event: this.event,
            customer: this.customer
          }
        )
        // this.$÷emit('killme')
        this.show = false
        this.$emit('update', this.event)
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
::v-deep table {
  min-width: 700px !important;
}
::v-deep td:nth-child(1) {
  width: 100px;
}
::v-deep td:nth-child(2) {
  width: 120px;
}
::v-deep td:nth-child(3) {
  width: 120px;
}
::v-deep td:nth-child(4) {
  width: 120px;
}
::v-deep td:nth-child(5) {
  width: 90px;
}
::v-deep td:nth-child(5) {
  width: 20px;
}

.card_title {
  background-color: #607d8b;
  color: white;
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
.v-card-text {
  margin-top: 20px !important;
}
</style>







