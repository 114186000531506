import _ from 'lodash'
const parts = {
  namespaced: true,
  state: {
    parts: [],
    cats: [],
    part_search: '',
    mm_groups: []
  },

  /* ---------------------------------------------------------------------------------------------- */
  /*                                           GETTERS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  getters: {
    get_mm_groups: state => {
      return state.mm_groups.filter(x => x !== '')
    },
    get_parts_cats: state => {
      return state.cats
    },
    get_part_by_id: state => id => {
      return state.parts.find(x => x.id === id)
    },
    get_parts: state => state.parts,

    get_part_search: state => state.part_search
  },

  /* ---------------------------------------------------------------------------------------------- */
  /*                                          MUTATIONS: {                                          */
  /* ---------------------------------------------------------------------------------------------- */

  mutations: {
    clear_persist_vars(state) {
      state.part_search = ''
    },
    set_part_search(state, search) {
      state.part_search = search
    },
    set_mutation(state, payload) {
      state[payload.param] = payload.value
    },
    add_mutation(state, payload) {
      state[payload.param].push(payload.value)
    },
    replace_record_mutation(state, payload) {
      var index = state[payload.param].findIndex(
        x => x[payload.key] === payload.id
      )
      state[payload.param].splice(index, 1, payload.value)
    }
  },

  /* ---------------------------------------------------------------------------------------------- */
  /*                                           ACTIONS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  actions: {
    set_parts_data({ commit }, payload) {
      commit('set_mutation', payload)
    },
    add_part({ commit }, payload) {
      commit('add_mutation', payload)
    },
    replace_parts_part({ commit }, payload) {
      commit('replace_record_mutation', payload)
    }
  }
}
export default parts
