<template>
  <div>
    <v-dialog v-model="show" width="700" @click:outside="$emit('killme')">
      <v-card>
        <v-card-title class="blue-grey white--text">
          <h3>Hide or delete?</h3>
        </v-card-title>
        <v-card-text>
          You can archive this customer so that they will no longer show up in
          the list. This does not fully delete it from the database in case it
          will need to be referenced later.
          <br />
          <br />
          Only fully delete it if there are no orders, events, or other records
          referencing it. To enable the delete button, enter DELETE into this
          box
          <v-text-field outlined v-model="delete_text"></v-text-field>
        </v-card-text>
        <v-card-actions flex>
          <v-btn
            color="error"
            :disabled="delete_text != 'DELETE'"
            @click="delete_me"
          >
            Delete
          </v-btn>
          <v-btn color="warning" @click="hide_me">Archive customer</v-btn>
          <v-btn color="grey" @click="$emit('killme')">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'DeleteCustomer',
  props: {
    customer: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      delete_text: '',
      show: true
    }
  },
  mounted() {
    this.show = true
  },
  methods: {
    async delete_me() {
      console.log('trying delete' + this.customer.client_id)
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'delete_customer.php',
          {
            func: 'delete',
            customer_id: this.customer.client_id
          }
        )
        console.log(response.data)
        this.$emit('delete_me', this.customer)
      } catch (error) {
        console.log(error)
      }
      this.show = false
      return true
    },
    async hide_me() {
      console.log('trying archive')
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'delete_customer.php',
          {
            func: 'hide',
            customer_id: this.customer.client_id
          }
        )
        console.log(response.data)

        this.$emit('delete_me', this.customer)
        this.$emit('killme')
      } catch (error) {
        console.log(error)
      }
      this.show = false
      return true
    }
  }
}
</script>

<style scoped></style>
