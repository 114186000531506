<template>
  <tbody>
    <tr>
      <td>
        {{ part.wcs_part_number }}
        <div class="id">
          {{ part.id }}
          <b-icon
            v-if="part.has_children == true"
            font-scale="1.3"
            icon="diagram3-fill"
            variant="success"
          ></b-icon>
        </div>
      </td>
      <td>
        {{ part.description }}
      </td>
      <td>
        <div class="qty" @click="edit_qty">
          <div v-if="edit_qty_flag == false" class="current_qty">
            {{ pretty_qty }}
          </div>

          <div v-else class="qty_input">
            <v-text-field
              dense
              outlined
              autofocus
              hide-details="true"
              v-model="part.qty"
              type="number"
              @keydown.69.prevent
              class="qty_input"
              @focusout="replace_child()"
              @blur="replace_child()"
              @keyup.enter.stop="replace_child()"
            ></v-text-field>
          </div>
        </div>
        <div v-if="part.unit">per {{ part.unit }}</div>
        <span>@ {{ money_format(part.cost) }}</span>
      </td>
      <td>
        {{ money_format(total) }}
      </td>

      <td>
        <v-icon class="trash" @click="unlink">mdi-trash-can</v-icon>
      </td>
    </tr>

    <Confirm ref="confirm"></Confirm>
  </tbody>
</template>

<script>
import axios from 'axios'
import Confirm from '@/components/Confirm.vue'
export default {
  name: 'SingleChild',
  components: {
    Confirm
  },
  props: {
    parentId: {
      type: String,
      default: ''
    },
    passedPart: {
      type: Object,
      default: function() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      edit_qty_flag: false,
      part: JSON.parse(JSON.stringify(this.passedPart)),
      show_notes_flag: this.passedPart.child_part_notes ? true : false,
      edit_flag: false,
      edit_single_use_flag: false
    }
  },
  computed: {
    total() {
      return this.part.qty * this.part.cost
    },

    pretty_qty() {
      return String(this.part.qty).replace(/\.000$/, '')
    }
  },

  methods: {
    /* ---------------------------------------- replace_child --------------------------------------- */
    async replace_child() {
      this.edit_qty_flag = false
      await this.add_child()
      this.$emit('replace_child', this.part)
      this.$store.state.parts.editing_flag = false
    },
    /* ------------------------------------------ add_child ----------------------------------------- */
    async add_child() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'add_child_part.php',
          {
            parent_id: this.parentId,
            child_id: this.part.id,
            qty: this.part.qty,
            update: true
          }
        )
        console.log(response.data)

        this.$emit('added')
      } catch (error) {
        console.log(error)
      }
    },
    /* ------------------------------------------- unlink ------------------------------------------- */
    async unlink() {
      if (
        await this.$refs.confirm.open(
          'Confirm unlink ',
          'Are you sure you want to unlink this item? ',
          { color: 'red' }
        )
      ) {
        try {
          const response = await axios.post(
            process.env.VUE_APP_API_URL + 'unlink_child.php',
            {
              parent_id: this.parentId,
              child_id: this.part.id
            }
          )
          this.$emit('unlink', this.index)
          console.log(response.data)
        } catch (error) {
          console.log(error)
        }
      }
    },
    edit_qty() {
      this.edit_qty_flag = true
      // Focus on the input
      this.$store.state.parts.editing_flag = true
    }
  }
}
</script>

<style scoped>
td {
  border: solid 1px #ddd;
}
tr td:nth-child(1) {
  width: 100px;
  text-align: center;
}
tr td:nth-child(2) {
  width: 280px;
}
tr td:nth-child(3) {
  width: 70px;
  text-align: center;
}
tr td:nth-child(4) {
  text-align: right;
  width: 50px;
  padding-right: 5px;
}
tr td:nth-child(5) {
  text-align: center;
  width: 20px;
}

.qty {
  display: inline;
  width: 75%;
}

.current_qty {
  display: block;
  width: 100%;
  border-radius: 10px;
  padding-right: 10px;
  border: solid #888 1px;
}
.qty_input {
  display: inline;
  width: 80px;
  border-color: yellow;
  border-width: 2px;
}
.id {
  font-size: 0.8em;
  color: purple;
}

.trash:hover {
  color: red;
}
</style>
