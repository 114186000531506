<template>
  <v-card width="1000">
    <v-app-bar class="py-3" app>
      <div class="pb-4">
        <v-text-field
          v-model="local_search"
          @change="$store.commit('parts/set_part_search', local_search)"
          label="search"
          outlined
          clearable
          hide-details
          dense
          class="mr-2 shrink"
          @keypress.enter="load_parts()"
        >
          <template #append>
            <v-icon @click="load_parts()">mdi-magnify</v-icon>
          </template>
        </v-text-field>
      </div>
      <div class="warehouse_selector ml-5">
        <v-autocomplete
          clearable
          v-model="selected_warehouse"
          label="Choose warehouse"
          :items="warehouses"
          item-text="location_name"
          item-value="warehouse_id"
        >
        </v-autocomplete>
      </div>
      <div v-if="selected_warehouse" class="count pb-3 ml-5">
        {{ grouped_by_warehouse[selected_warehouse].length }} items
      </div>
      <div v-else class="count pb-3 ml-5">{{ parts.length }} items</div>
      <div class="totals">
        <table>
          <tr>
            <td>Brakes:</td>
            <td class="subtotals">
              {{
                selected_warehouse > 0 ? warehouse_brakes_total : brakes_total
              }}
            </td>
          </tr>
          <tr>
            <td>Carriage:</td>
            <td class="subtotals">
              {{
                selected_warehouse > 0
                  ? warehouse_carriage_total
                  : carriage_total
              }}
            </td>
          </tr>
          <tr>
            <td>Total:</td>
            <td class="subtotals">
              {{ selected_warehouse > 0 ? warehouse_total : total }}
            </td>
          </tr>
        </table>
      </div>
      <!-- SUMMARY -->
      <v-btn
        v-if="selected_warehouse > 0"
        @click="show_new_item_flag = true"
        class="mt-n5"
        color="primary"
        >Add</v-btn
      >
      <v-btn
        v-if="selected_warehouse < 1"
        @click="show_summary = !show_summary"
        class="ml-3 mt-n5"
        color="primary"
        >Summary</v-btn
      >
      <v-spacer></v-spacer>
      <!-- PRINT PDF -->
      <div class="print_icon pb-5">
        <v-icon v-if="selected_warehouse > 0" @click="print_warehouse()"
          >mdi-printer</v-icon
        >
        <v-icon v-else @click="print_summary()" color="success"
          >mdi-printer</v-icon
        >
      </div>
    </v-app-bar>
    <v-card-text v-if="loading == true">
      <v-skeleton-loader
        loading="true"
        type="table-heading, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
      ></v-skeleton-loader>
    </v-card-text>
    <v-card-text v-else class="text-buffer">
      <!-- SUMMARY -->
      <div
        class="summary"
        v-if="show_summary == true && selected_warehouse < 1"
      >
        <v-simple-table class="summary_table">
          <thead>
            <tr>
              <th>WH ID</th>
              <th>Warehouse name</th>
              <th>Brakes</th>
              <th>Carriage</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(room, index) in summary" :key="index">
              <td>
                {{ room.warehouse_id }}
              </td>
              <td style="text-align: left">
                {{ room.warehouse_name }}
              </td>
              <td>
                {{ money_format(room.brakes) }}
              </td>
              <td>
                {{ money_format(room.carriage) }}
              </td>
              <td>
                {{ money_format(room.total) }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td><h6>Totals:</h6></td>
              <td>{{ brakes_total }}</td>
              <td>{{ carriage_total }}</td>
              <td>{{ total }}</td>
            </tr>
          </tbody>
        </v-simple-table>
      </div>

      <div v-else>
        <SingleWarehouse
          v-if="selected_warehouse > 0"
          :key="selected_warehouse + increment"
          :warehouse_id="selected_warehouse"
          :items="grouped_by_warehouse[selected_warehouse]"
          :parts="parts"
          @deleteme="delete_item"
        ></SingleWarehouse>
        <v-simple-table v-else>
          <thead>
            <tr>
              <th>Supplier</th>
              <th>WCS Part#</th>
              <th>Description</th>
              <th>Qty</th>
              <th>Unit</th>
              <th>Cost per unit</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <SingleInventoryItem
              v-for="part in parts"
              :key="part.id"
              :passedPart="part"
              :passedInventory="grouped_inventory[part.id]"
            ></SingleInventoryItem>
          </tbody>
        </v-simple-table>
      </div>
    </v-card-text>
    <InventoryWarehousePDF
      v-if="print_warehouse_flag == true"
      @killme="print_warehouse_flag = false"
      :warehouse_name="
        warehouses.filter(x => x.warehouse_id == selected_warehouse)[0]
          .location_name
      "
      :data="grouped_by_warehouse[selected_warehouse]"
      :parts="parts"
    ></InventoryWarehousePDF>
    <NewInventoryItem
      v-if="show_new_item_flag == true"
      :passed-warehouse-id="selected_warehouse.toString()"
      :warehouses="warehouses"
      @killme="show_new_item_flag = false"
      @addme="add_item"
    ></NewInventoryItem>
  </v-card>
</template>

<script>
import axios from 'axios'
import SingleInventoryItem from '../components/SingleInventoryItem'
import InventoryWarehousePDF from '../components/InventoryWarehousePDF'
import SingleWarehouse from '../components/SingleWarehouse'
import NewInventoryItem from '../components/NewInventoryItem'
export default {
  name: 'InventoryList',
  components: {
    SingleInventoryItem,
    SingleWarehouse,
    InventoryWarehousePDF,
    NewInventoryItem
  },
  data() {
    return {
      local_search: '',
      increment: 0,
      show_new_item_flag: false,
      parts: [],
      loading: false,
      inventory: [],
      warehouses: [],
      selected_warehouse: this.$store.state.selected_warehouse,
      print_warehouse_flag: false,
      show_summary: false
    }
  },
  computed: {
    summary() {
      let newarray = []
      this.warehouses.forEach(w => {
        let room = this.grouped_by_warehouse[w.warehouse_id]
        let brakes = _.sumBy(room, x => {
          return x.division == 'brakes' ? Number(x.subtotal) : 0
        })
        let carriage = _.sumBy(room, x => {
          return x.division != 'brakes' ? Number(x.subtotal) : 0
        })
        let total = _.sumBy(room, x => {
          return Number(x.subtotal)
        })
        let output = {
          warehouse_id: w.warehouse_id,
          brakes: brakes,
          carriage: carriage,
          total: total,
          warehouse_name: w.location_name
        }
        newarray.push(output)
      })
      return newarray
    },

    grouped_inventory() {
      return _.groupBy(this.inventory, 'part_id')
    },

    grouped_by_warehouse() {
      return _.groupBy(this.inventory, 'warehouse_id')
    },
    total() {
      return this.money_format(
        _.sumBy(this.inventory, x => {
          return Number(x.subtotal)
        })
      )
    },
    brakes_total() {
      return this.money_format(
        _.sumBy(this.inventory, x => {
          return x.division == 'brakes' ? Number(x.subtotal) : 0
        })
      )
    },
    carriage_total() {
      return this.money_format(
        _.sumBy(this.inventory, x => {
          return x.division != 'brakes' ? Number(x.subtotal) : 0
        })
      )
    },
    warehouse_total() {
      return this.money_format(
        _.sumBy(this.grouped_by_warehouse[this.selected_warehouse], x => {
          return Number(x.subtotal)
        })
      )
    },
    warehouse_brakes_total() {
      return this.money_format(
        _.sumBy(this.grouped_by_warehouse[this.selected_warehouse], x => {
          return x.division == 'brakes' ? Number(x.subtotal) : 0
        })
      )
    },
    warehouse_carriage_total() {
      return this.money_format(
        _.sumBy(this.grouped_by_warehouse[this.selected_warehouse], x => {
          return x.division != 'brakes' ? Number(x.subtotal) : 0
        })
      )
    }
  },
  watch: {
    selected_warehouse: function (val) {
      this.$store.commit('set', { field: selected_warehouse, value: val })
    }
  },
  mounted() {
    this.get_warehouses()
    this.load_parts()
  },
  methods: {
    /* --------------------------------------- get_warehouses --------------------------------------- */
    async get_warehouses() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'get_warehouses.php',
          {}
        )
        this.warehouses = response.data
        console.log(response.data.parts)
      } catch (error) {
        console.log(error)
      }
    },
    /* ----------------------------------------- load_parts ----------------------------------------- */
    async load_parts() {
      this.loading = true
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_inventory.php',
          {
            search: this.local_search,
            inventory: true
          }
        )
        console.table(response.data)
        this.parts = response.data.parts

        this.validate_part(this.parts[0])
        this.inventory = response.data.inventory
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },

    print_warehouse() {
      this.print_warehouse_flag = true
    },
    print_summary() {
      alert('Choose a warehouse location')
    },
    add_item(item) {
      this.inventory.push(item)
    },
    delete_item(item) {
      let index = this.inventory.findIndex(
        x => x.inventory_id == item.inventory_id
      )
      this.inventory.splice(index, 1)
      this.increment++
    }
  }
}
</script>

<style scoped>
.warehouse_selector {
  width: 230px;
}
.totals {
  font-size: 0.9em;
  width: 200px;
  line-height: 1.2em;
  margin-top: -25px;
  padding-left: 20px;
}
.subtotals {
  text-align: right;
  padding-left: 10px;
}
.summary {
  width: 600px;
}
.summary_table td {
  text-align: right;
}
</style>
