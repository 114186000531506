<template>
  <div>
    <v-text-field
      v-if="calc_flag"
      dense
      outlined
      :label="base_label"
      v-model="converted_value"
      @keypress="isNumber()"
    ></v-text-field>
    <v-text-field
      v-if="!calc_flag"
      readonly
      class="readonly"
      dense
      :label="label + ' Discount'"
      hide-details="true"
      outlined
      :value="percent_format(current)"
    >
      <template #append>
        <v-btn block text class="calculator_button pa-0" @click="calculate">
          <calculator></calculator>
        </v-btn>
      </template>
    </v-text-field>
    <!-----------  Show  ------------->
    <div v-if="calc_flag" class="show">
      <v-text-field
        outlined
        dense
        class="readonly"
        label="calculated price"
        v-model="suggested_price"
        readonly
        hide-details="auto"
      ></v-text-field>
      <div class="rounding">
        round to nearest:
        <v-btn-toggle class="round_to">
          <v-btn
            class="rounding_button"
            :color="button == 1 ? 'primary' : ''"
            @click="set_rounding(1)"
          >
            ${{ my_roundTo[0] }}
          </v-btn>
          <v-btn
            class="rounding_button"
            :color="button == 2 ? 'primary' : ''"
            @click="set_rounding(2)"
          >
            ${{ my_roundTo[1] }}
          </v-btn>
          <v-btn
            class="rounding_button"
            :color="button == 3 ? 'primary' : ''"
            @click="set_rounding(3)"
          >
            ${{ my_roundTo[2] }}
          </v-btn>
          <v-btn
            class="rounding_button"
            :color="button == 4 ? 'primary' : ''"
            @click="set_rounding(4)"
          >
            ${{ my_roundTo[3] }}
          </v-btn>
        </v-btn-toggle>
      </div>
      <v-btn-toggle class="pa-0 ma-0">
        <v-btn class="my_buttons" color="success" @click="accept">Accept</v-btn>
        <v-btn class="my_buttons" color="error" @click="stop">Cancel</v-btn>
      </v-btn-toggle>
    </div>
    <!------------------------------->
  </div>
</template>
<script>
import calculator from '../components/icons/calculator.vue'
export default {
  name: 'PriceCalculator',
  components: {
    calculator
  },
  props: {
    base: {
      type: Number,
      default: 1
    },
    current: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      desired_discount: 0,
      discount_title: 'Calculated',
      calc_flag: false,
      roundTo: 0.01,
      button: 0
    }
  },
  computed: {
    base_label() {
      return this.discount_title + ' discount ' + (this.calc_flag ? '%' : '')
    },
    converted_value: {
      get() {
        return this.round(this.desired_discount * 100)
      },
      set(newValue) {
        this.desired_discount = this.round(newValue / 100)
      }
    },
    suggested_price() {
      return this.round(
        this.round_to(this.base - this.base * this.desired_discount)
      )
    },

    /* 
    This gives the options for rounding.  
    Low price items can be rounded to smaller incerements.
    */
    my_roundTo() {
      if (this.base < 1) {
        var my_array = [0.25, 0.1, 0.05, 0.01]
      }
      if (this.base >= 1 && this.base < 10) {
        my_array = [0.5, 0.25, 0.1, 0.05]
      }
      if (this.base >= 10 && this.base < 20) {
        my_array = [1, 0.5, 0.25, 0.1]
      }
      if (this.base >= 20 && this.base < 200) {
        my_array = [5, 1, 0.5, 0.25]
      }
      if (this.base >= 200) {
        my_array = [10, 5, 1, 0.5]
      }
      return my_array
    }
  },
  mounted() {
    return (this.desired_discount = this.round(this.current))
  },
  methods: {
    isNumber: function(evt) {
      evt = window.event

      var charCode = evt.which ? evt.which : evt.keyCode
      if (charCode == 13) {
        this.accept()
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    calculate() {
      this.calc_flag = true
      this.discount_title = 'Desired '
    },
    stop() {
      this.calc_flag = false
      this.discount_title = 'Calculated '
      this.button = 0
      this.desired_discount = this.round(this.current)
      this.roundTo = 0.01
    },
    accept() {
      this.$emit('update_price', {
        price: this.suggested_price,
        percent: this.round(this.desired_discount)
      })
      this.stop()
    },
    round_to(x) {
      var n = this.roundTo
      var result = Math.round(x / n) * n
      return result
    },
    set_rounding(n) {
      this.roundTo = this.my_roundTo[n - 1]
      this.button = n
    }
  }
}
</script>
<style scoped>
.calculator_button {
  position: relative;
  top: -5px;
  right: -10px;
}
.show {
  display: flex;
  flex-direction: column;
}
::v-deep .round_to button {
  font-size: 0.8em;
  padding: 0px !important;
  text-align: center;
  width: 25% !important;
}
.my_buttons {
  width: 50%;
  height: 30px !important;
}
.rounding_button {
  height: 25px !important;
}
.readonly {
  background-color: rgb(235, 235, 235);
}
</style>
