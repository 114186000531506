<template>
  <v-dialog v-model="show" width="600">
    <v-card>
      <v-card-title class="card_title">
        Add child part for id {{ parentId }}
        <v-spacer></v-spacer>
        <v-icon @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <ChildPartsList :parent-id="parentId" @added="added"></ChildPartsList>
    </v-card>
  </v-dialog>
</template>

<script>
import ChildPartsList from '@/components/ChildPartsList'
export default {
  name: 'ChildPicker',
  components: {
    ChildPartsList
  },
  props: {
    parentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      show: true
    }
  },
  watch: {
    show() {
      this.$emit('killme')
    }
  },
  mounted() {
    this.show = true
  },
  methods: {
    added() {
      this.$emit('added')
      this.show = false
    }
  }
}
</script>

<style scoped>
::v-deep .sticky {
  width: 700px !important;
  position: relative !important;
  top: 0px;
  z-index: unset;
}
::v-deep .filter {
  width: 300px !important;
}
.card_title {
  background-color: rgb(212, 212, 212);
  color: rgb(0, 0, 0);
  cursor: grab;
}
.active {
  color: rgb(0, 0, 0) !important;
  background-color: rgb(72, 255, 0) !important;
}
.card_title:active {
  cursor: grabbing;
}
::v-deep .parts_table tr td:nth-child(4) {
  width: 120px;
}
::v-deep .parts_table tr td:nth-child(2) {
  width: 310px;
}
::v-deep .parts_table tr td:nth-child(5) {
  width: 50px;
}
</style>
