<template>
  <v-row class="container">
    <div class="has_children">
      <v-switch
        v-model="has_children"
        dense
        true-value="1"
        false-value="0"
        hide-details="true"
        label="child parts"
      ></v-switch>
      <div v-if="total_cost > 0" class="total_cost">
        <div class="total_money">{{ money_format(total_cost) }}</div>
        <v-btn small color="success" class="ml-5" @click="apply_production_cost"
          >apply</v-btn
        >
        <div class="fine_print">production cost</div>
      </div>
    </div>
    <v-spacer></v-spacer>
    <div v-if="has_children == '1'" class="">
      <table class="children">
        <thead>
          <th>WCS Part#</th>
          <th>Description</th>
          <th>Qty/cost</th>
          <th>Total cost</th>
        </thead>
        <SingleChild
          v-for="(child, index) in children"
          :key="
            child.id + child.qty + child.cost + child.cms_part_number + index
          "
          :index="index"
          :passedPart="child"
          :parent-id="parentId"
          @replace_child="replace_child"
          @unlink="remove_child"
        ></SingleChild>
      </table>
    </div>
    <v-spacer></v-spacer>
    <div v-if="has_children == '1'" class="plus">
      <v-icon color="success" class="plus" @click="new_child_flag = true">
        mdi-plus-circle
      </v-icon>
    </div>
    <ChildPicker
      v-if="new_child_flag == true"
      :parent-id="parentId"
      @added="added"
      @killme="new_child_flag = false"
    ></ChildPicker>
  </v-row>
</template>

<script>
import SingleChild from '@/components/SingleChild.vue'
import ChildPicker from '@/components/ChildPicker.vue'
import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'ChildParts',
  components: {
    SingleChild,
    ChildPicker
  },
  props: {
    parentId: {
      type: String
    },
    hasChildren: {
      type: String
    }
  },
  data() {
    return {
      children: [],
      new_child_flag: false,
      has_children: '0'
    }
  },
  computed: {
    total_cost() {
      return this.round(
        _.sumBy(this.children, function(x) {
          return Number(x.cost) * Number(x.qty)
        })
      )
    }
  },
  watch: {
    total_cost: function() {
      this.$emit('set_production_cost', this.total_cost)
    },
    has_children: function(oldVal, newVal) {
      this.$emit('set_has_children', this.has_children)
    }
  },
  async mounted() {
    await this.load_children()
    this.has_children = this.hasChildren
  },
  methods: {
    /* ----------------------------------- async load_children() { ---------------------------------- */
    async load_children() {
      // Loading already linked children.
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_children.php',
          {
            parent_id: this.parentId
          }
        )
        console.log(
          `%c${JSON.stringify('CHILDS')}`,
          'color:red;font-weight:bold'
        )
        console.log(response.data)
        this.children = response.data
        this.$emit('pass_children', this.children)
      } catch (error) {
        console.log(error)
      }
    },

    /* ----------------------------------------- load_child ----------------------------------------- */

    /* ---------------------------------- async unlink(child_id) { ---------------------------------- */

    remove_child(x) {
      this.children.splice(x, 1)
    },

    /* --------------------------------------------- add -------------------------------------------- */

    added() {
      this.load_children()
    },
    apply_production_cost() {
      this.$emit('apply_production_cost', this.total_cost)
    },
    replace_child(x) {
      this.load_children()
    }
  }
}
</script>

<style scoped>
.plus {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1em;
  padding: 0px 5px 3px 5px;
}
.children {
  width: 820px;
}
.has_children {
  display: inline;
}
.container {
  margin: 0;
  padding: 0;
  margin-top: -10px !important;
}
.plus {
  margin-top: 13px;
  padding-right: 10px;
  margin-left: -10px;
  width: 10px;
}
.total_cost {
  margin-left: 10px;
}
.total_money {
  display: inline-block;
  text-align: right;
  font-size: 1.1em;
  font-weight: bold;
  min-width: 55px;
}
.fine_print {
  font-size: 0.7em;
  margin-top: -15px;
}
</style>
