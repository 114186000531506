<template>
  <div>
    <div class="background">
      <v-progress-circular
        :size="50"
        class="spinner"
        indeterminate
      ></v-progress-circular>
    </div>

    <iframe id="printPdf" name="printPdf"></iframe>
  </div>
</template>

<script>
import axios from 'axios'
// import _ from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs
export default {
  name: 'ReportsWarehouseReport',
  data() {
    return {
      parts: [],
      header_row: [
        {
          text: 'WCS#',
          fontSize: 10,
          fillColor: '#DDDDFF'
        },
        {
          text: 'Description',
          fontSize: 10,
          fillColor: '#DDDDFF'
        },
        {
          text: 'Reorder level',
          fontSize: 10,
          fillColor: 'orange'
        },
        {
          text: 'Order qty',
          fontSize: 10,
          fillColor: 'orange'
        },
        {
          text: 'Shelf capacity',
          fontSize: 9,
          fillColor: 'orange'
        },
        {
          text: 'Supplier name',
          fontSize: 10,
          fillColor: '#DDDDFF'
        },
        {
          text: 'Supplier Part#',
          fontSize: 10,
          fillColor: '#DDDDFF'
        },
        {
          text: 'Location',
          fontSize: 9,
          fillColor: '#DDDDFF'
        }
      ],

      default_style: { fontSize: 8, color: 'black' }, // used as a style
      main_table_layout: {
        hLineWidth: function () {
          return 0.1
        },
        vLineWidth: function () {
          return 0.1
        },
        hLineColor: function () {
          return '#aaaaaa'
        },
        vLineColor: function () {
          return '#aaaaaa'
        },
        paddingLeft: function () {
          return 3
        },
        paddingRight: function () {
          return 3
        }
      }
    }
  },

  computed: {
    categorized_parts() {
      return _.groupBy(this.parts, x => x.category_id)
    },
    category_tables() {
      let newarray = []
      Object.values(this.categorized_parts).forEach(category =>
        newarray.push(...this.create_category_table(category))
      )
      return newarray
    },

    main_table() {
      let newarray = []
      newarray.push(...this.category_tables)
      newarray.unshift(this.header_row)
      return newarray
    }
  },
  mounted() {
    this.load_parts()
  },

  /* ----------- ----------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    create_category_table(category) {
      let current_subcategory = ''
      let newarray = [
        [
          {
            //* Add the category header black bar
            text: category[0].category_name,
            colSpan: 8,
            fontSize: 10,
            fillColor: 'black',
            bold: 'true',
            color: 'white',
            margin: [0, -3, 0, 0],
            lineHeight: 0.8
          }
        ]
      ]
      category.forEach(part => {
        //* Add the subcategory if needed
        if (
          part.subcategory !== current_subcategory &&
          part.subcategory != ''
        ) {
          newarray.push([
            {
              text: `${part.subcategory}`,
              colSpan: 8,
              fontSize: 8,
              margin: [0, -2, 0, 0],
              lineHeight: 0.8,
              bold: true,

              fillColor: '#efeaea'
            }
          ])
          current_subcategory = part.subcategory
        }

        //* Create the actual row
        newarray.push(...this.create_row(part))
      })
      return newarray
    },

    create_row(part) {
      let row_array = []

      //! ---------------------push to row array ---------------------------------

      row_array.push([
        {
          text: part.wcs_part_number
        },
        {
          text: part.description
        },
        {
          text: part.reorder_level,
          alignment: 'center'
        },
        {
          text: part.order_qty,
          alignment: 'center'
        },

        {
          text: part.shelf_capacity,
          alignment: 'center'
        },
        {
          text: part.supplier_name
        },
        {
          text: part.supplier_part_no
        },
        {
          text: part.location
        }
      ])
      return row_array
    },
    /* ---------------------------------------- generate() { ---------------------------------------- */
    //http://pdfmake.org/playground.html for examples

    generate() {
      var tmpdoc = document.getElementById('printPdf').contentWindow.document
      tmpdoc.open()
      tmpdoc.write('Loading warehouse report...')
      tmpdoc.close()
      var date = dayjs().format('M/D/YYYY h:mm A')
      //console.log(date)
      var docDefinition = {
        // header: { table: { body: [this.header_row] } },
        //  () => {
        //   return {
        //     table: {
        //       body:
        //     }
        //   }
        // },
        footer: function (currentPage, pageCount) {
          return {
            table: {
              body: [
                [
                  {
                    text:
                      'Warehouse - Printed ' +
                      date +
                      ' Page ' +
                      currentPage.toString() +
                      ' of ' +
                      pageCount,
                    alignment: 'right',
                    fontSize: 7,
                    style: 'normalText',
                    margin: [30, 0, 0, 0]
                  }
                ]
              ]
            },
            layout: 'noBorders'
          }
        },
        content: [
          {
            table: {
              headerRows: 1,
              dontBreakRows: true,
              body: this.main_table,
              widths: [50, '*', 35, 35, 35, 60, 60, 35]
            },
            layout: this.main_table_layout
          }
        ],
        defaultStyle: this.default_style,
        pageMargins: [35, 15, 15, 15],
        pageSize: 'LETTER'
      }
      //   pdfMake.createPdf(docDefinition).download("Directory.pdf");
      pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf'])
    },

    /* ----------------------------------- async load_clients() { ----------------------------------- */

    async load_parts() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parts.php',
          {
            options: 'Whatever',
            hide_archive: true
          }
        )
        this.parts = response.data.parts // This is where we get the data
        // Validated 02/09/2021
        this.validate_part(response.data.parts[0])
        this.mm_groups = response.data.mm_groups
        this.generate()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.background {
  position: absolute;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
}
.spinner {
  margin: 99px;
}
#printPdf {
  position: absolute;
  top: 0px;
  display: inline;
  height: 1000px;
  width: 1200px;
}
</style>
