<template>
  <div>
    <div class="menu">
      <b-button-group>
        <b-button
          :variant="selected == 1 ? 'primary' : 'outline-primary'"
          @click="set_mode(1)"
        >
          Duplicate customers
        </b-button>
        <b-button
          disabled
          :variant="selected == 2 ? 'primary' : 'outline-primary'"
          @click="set_mode(2)"
        >
          Somthing
        </b-button>
        <b-button
          :variant="selected == 3 ? 'primary' : 'outline-primary'"
          @click="set_mode(3)"
        >
          Parts Index
        </b-button>
        <b-button
          :variant="selected == 4 ? 'primary' : 'outline-primary'"
          @click="set_mode(4)"
        >
          Low margins
        </b-button>
        <b-button
          :variant="selected == 5 ? 'primary' : 'outline-primary'"
          @click="set_mode(5)"
        >
          High margins
        </b-button>
      </b-button-group>
    </div>
    <div class="report">
      <AuditIndex v-if="selected == 3" :records="records"></AuditIndex>
      <AuditCosts v-if="selected == 1" :records="records"></AuditCosts>
      <AuditMargins
        v-if="selected == 4"
        :records="records"
        :sort="'low'"
      ></AuditMargins>
      <AuditMargins
        v-if="selected == 5"
        :records="records"
        :sort="'high'"
      ></AuditMargins>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import AuditParents from '../components/AuditParents.vue'
// import AuditCosts from '../components/AuditCosts.vue'
// import AuditMargins from '../components/AuditMargins.vue'

import AuditIndex from '../components/AuditIndex.vue'
// import AuditList from '../components/AuditList.vue'
export default {
  name: 'Audit',
  components: {
    // AuditParents,
    // AuditCosts,
    AuditIndex
    // AuditMargins
  },
  props: {
    name: {
      type: Array,
      default: function () {
        return []
      }
    },
    client: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },

  data() {
    return {
      example: 'hi',
      records: [],
      selected: 1
    }
  },
  computed: {
    title() {
      var titles = [
        'Choose one',
        'Mismatched costs',
        'Auto price audit',
        'Has children',
        'Low Margins',
        'High Margins'
      ]
      return titles[this.selected]
    }
  },
  mounted() {},

  /* ---------------------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    async load(mode) {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'audit_' + mode + '.php',
          {
            key: this.$store.state.mykey
          }
        )
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    set_mode(x) {
      this.selected = x
      var modes = [
        'Self-destruct',
        'costs',
        'autoprice',
        'children',
        'margins',
        'margins'
      ]
      this.load(modes[x])
    }
  }
}
</script>

<style scoped></style>
