<template>
  <tr @dblclick="edit_event_flag = true">
    <td>
      {{ event.event_date }}
    </td>
    <td>
      {{ event.event_type }}
    </td>
    <td>
      {{ event.carriage_type }}
    </td>
    <td>
      {{ event.notes }}
    </td>

    <td>
      {{ event.wc_serial_no }}
    </td>
    <td>
      {{ event.job_number }}
    </td>
    <td>
      <v-icon class="edit_icon mr-3" @click="edit_event_flag = true"
        >mdi-pencil</v-icon
      >
      <v-icon class="delete_icon" @click="delete_event">mdi-trash-can</v-icon>
    </td>
    <EditEvent
      v-if="edit_event_flag == true"
      @killme="edit_event_flag = false"
      @add="$emit('add', event)"
      @update="update_event"
      :r="client"
      :mode="'Edit'"
      :passedEvent="event"
    ></EditEvent>
    <Confirm ref="confirm"></Confirm>
  </tr>
</template>

<script>
import axios from 'axios'
import EditEvent from '../components/EditEvent'
import Confirm from '../components/Confirm'
export default {
  name: 'SingleEvent',
  components: {
    EditEvent,
    Confirm
  },
  props: {
    passedEvent: {
      type: Object,
      default: function () {
        return {}
      }
    },
    client: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      event: { ...this.passedEvent },
      edit_event_flag: false
    }
  },
  methods: {
    async delete_event() {
      if (
        await this.$refs.confirm.open(
          'Delete event ',
          'Are you sure you want to delete this event?',
          { color: 'red', textColor: 'white' }
        )
      ) {
        try {
          await axios.post(process.env.VUE_APP_API_URL + 'delete_event.php', {
            event: this.event
          })

          this.$refs.modal.hide()
          this.$emit('delete_me', this.event)
        } catch (error) {
          console.log(error)
        }
      }
    },
    update_event(e) {
      this.event = e
    }
  }
}
</script>

<style scoped>
.edit_icon:hover {
  color: green;
}
.delete_icon:hover {
  color: red;
}
</style>