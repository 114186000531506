<template>
  <div class="container d-flex">
    <v-card class="mycard">
      <v-toolbar>{{ id }}</v-toolbar>
      <v-list>
        <v-list-item
          v-for="warehouse in warehouses"
          :key="warehouse.warehouse_id"
        >
          {{ warehouse.warehouse_id }} {{ warehouse.location_name }}
          <v-spacer></v-spacer
          ><v-btn small @click="load_warehouse(warehouse.warehouse_id)"
            >load</v-btn
          ></v-list-item
        >
      </v-list>
    </v-card>
    <v-card>
      <v-card-text>
        <v-simple-table sortable>
          <thead>
            <tr>
              <th>ID</th>
              <th>wcs_part_number</th>
              <th>Description</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in stock" :key="index">
              <td>{{ item.part_id }}</td>
              <td>
                {{ item.wcs_part_number }}
                {{ item.part_id }}
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                {{ item.qty }}
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
      <v-card-actions>
        <!-- <v-btn @click="save">Save to DB</v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'EnterInventory',
  data() {
    return {
      warehouses: [],
      stock: [],
      id: 0
    }
  },
  mounted() {
    this.get_warehouses()
  },
  methods: {
    async get_warehouses() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'get_warehouses.php',
          {
            key: this.$store.state.mykey
          }
        )
        this.warehouses = response.data
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    async load_warehouse(id) {
      this.id = id
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_warehouse.php',
          {
            warehouse_id: id
          }
        )
        console.table(response.data)
        this.stock = response.data
      } catch (error) {
        console.log(error)
      }
    },
    async save() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_inventory.php',
          {
            warehouse_id: this.id,
            items: this.stock
          }
        )
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped >
.mycard {
  width: 400px;
}
</style>