<template>
  <v-container>
    <h5>You cannot make changes here.</h5>
    {{ count }} index tags do not match a WCS part number. Some are missing
    dashes. If there is a range of part numbers in here (XXXX - XXXX), that
    doesn't mean it is bad, it just doesn't match a single part number.
    <v-alert type="info" outlined>
      To get this from the database backend, use the following query: <br />
      <b>
        SELECT i.* FROM index_tags i LEFT JOIN parts p ON i.wcs_part_number =
        p.wcs_part_number WHERE p.id IS NULL
      </b>
    </v-alert>

    <SingleIndexTag
      v-for="tag in grouped_tags"
      :passedTag="tag"
      :key="tag.id"
    ></SingleIndexTag>
  </v-container>
</template>

<script>
import axios from 'axios'
import SingleIndexTag from '../components/SingleIndexTag'
export default {
  name: 'AuditIndex',
  components: { SingleIndexTag },
  props: {},
  data() {
    return {
      tags: []
    }
  },
  computed: {
    count() {
      return this.tags.length
    },
    grouped_tags() {
      return _.groupBy(this.tags, x => x.tag)
    }
  },
  watch: {},
  mounted() {
    this.load_index()
  },
  methods: {
    async load_index() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'audit_index.php',
          {}
        )
        this.tags = response.data
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.card_title {
  background-color: rgb(212, 212, 212);
  color: rgb(0, 0, 0);
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
::v-deep .v-text-field__slot .v-label {
  color: rgb(218, 148, 148) !important;
}
::v-deep .v-text-field__slot .v-label--active {
  color: rgb(24, 145, 0) !important;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -7px;
}
::v-deep .mycheckbox {
  padding: 0px 0px 0px 0px;
}
</style>