<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title class="card_title">
        New inventory item for location {{ warehouse_id }}
        <v-spacer></v-spacer>
        <v-icon @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-autocomplete
              :items="warehouses"
              hide-details="true"
              :item-text="'location_name'"
              :item-value="'warehouse_id'"
              label="Warehouse name"
              v-model="warehouse_id"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-autocomplete
              ref="focus"
              autofocus
              :items="parts"
              dense
              hide-details="true"
              :item-text="create_text"
              item-value="id"
              label="WCS part number"
              v-model="part_id"
              tabindex="5"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              dense
              hint="press enter to save"
              label="Qty"
              v-model="qty"
              tabindex="6"
              @keyup.enter="saveable ? save() : null"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" class="ml-3" @click="show = false">Close</v-btn>
        <v-spacer></v-spacer>
        <v-checkbox
          class="mycheckbox"
          tabindex="7"
          v-model="persist"
          label="Persist"
        >
        </v-checkbox>
        <v-spacer></v-spacer>
        <v-btn :disabled="!saveable" color="success" @click="save" tabindex="8"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: 'NewInventoryItem',
  components: {},
  props: {
    passedWarehouseId: {
      type: String,
      default: ''
    },
    warehouses: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      persist: this.$store.state.new_inventory_persist,
      show: true,
      parts: [],
      part_id: '',
      qty: '',
      warehouse_id: this.passedWarehouseId
    }
  },
  computed: {
    saveable() {
      return (
        this.warehouse_id > 0 &&
        this.qty >= 0 &&
        this.part_id > 0 &&
        this.qty != '' &&
        this.qty != null
      )
    }
  },
  watch: {
    show() {
      this.$emit('killme')
    },
    persist: function (val) {
      this.$store.commit('set', { field: 'new_inventory_persist', value: val })
    }
  },
  mounted() {
    this.load_parts()
  },
  methods: {
    /* ----------------------------------------- load_parts ----------------------------------------- */
    async load_parts() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parts.php',
          {
            hide_archive: false
          }
        )
        this.parts = response.data.parts
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    /* ----------------------------------------- create_text ---------------------------------------- */
    create_text(item) {
      return item.wcs_part_number + ' - ' + item.description
    },
    /* -------------------------------------------- save -------------------------------------------- */
    async save() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'save_new_inventory_item.php',
          {
            warehouse_id: this.warehouse_id,
            part_id: this.part_id,
            qty: this.qty,
            user: this.$store.state.profile.name
          }
        )
        if (response.data == 'exists') {
          alert('This item already exists in inventory')
          return
        }
        console.log(response.data)
        this.$emit('addme', response.data)
        if (this.persist) {
          this.part_id = null
          this.qty = null
          this.$refs.focus.focus()
          return
        }
        this.show = false
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.card_title {
  background-color: rgb(212, 212, 212);
  color: rgb(0, 0, 0);
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
::v-deep .v-text-field__slot .v-label {
  color: rgb(218, 148, 148) !important;
}
::v-deep .v-text-field__slot .v-label--active {
  color: rgb(24, 145, 0) !important;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -7px;
}
::v-deep .mycheckbox {
  padding: 0px 0px 0px 0px;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -5px;
}
</style>