export default {
  methods: {
    money_format(x, digits = 2) {
      x = Number(x)
      var rounded = x.toFixed(digits)
      var commas = rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return '$' + commas
    },
    round(x) {
      return Math.round((x + Number.EPSILON) * 100) / 100
    },
    percent_format(x) {
      x = Number(x)
      var p = x * 100
      return Math.round(p) + '%'
    },
    validate_part(part, showId) {
      // Set second param to 1 to display the part ID in the console log.  Not recommended when there are many similar records
      let props = [
        { name: 'id', type: 'Number', in_db: true },
        { name: 'category', type: 'String', in_db: true },
        { name: 'subcategory', type: 'String', in_db: true },
        { name: 'mm_group', type: 'String', in_db: true },
        { name: 'customer_pricelist', type: 'String', in_db: true },
        { name: 'supplier_name', type: 'String', in_db: true },
        { name: 'supplier_part_number', type: 'String', in_db: true },
        { name: 'wcs_part_number', type: 'String', in_db: true },
        { name: 'description', type: 'String', in_db: true },
        { name: 'q1_unit', type: 'String', in_db: true },
        { name: 'q2_unit', type: 'String', in_db: true },
        { name: 'q3_unit', type: 'String', in_db: true },
        { name: 'cost', type: 'String', in_db: false },
        { name: 'retail', type: 'String', in_db: true },
        { name: 'unit', type: 'String', in_db: true },
        { name: 'reorder_level', type: 'String', in_db: true },
        { name: 'order_qty', type: 'String', in_db: true },
        { name: 'shelf_capacity', type: 'String', in_db: true },
        { name: 'location', type: 'String', in_db: true },
        { name: 'AR_qty', type: 'String', in_db: true },
        { name: 'AR_unit', type: 'String', in_db: true },
        { name: 'AR_value', type: 'String', in_db: true },
        { name: 'BR_unit', type: 'String', in_db: true },
        { name: 'BR_value', type: 'String', in_db: true },
        { name: 'BR_qty', type: 'String', in_db: true },
        { name: 'FAB_unit', type: 'String', in_db: true },
        { name: 'FAB_value', type: 'String', in_db: true },
        { name: 'FAB_qty', type: 'String', in_db: true },
        { name: 'GID_qty', type: 'String', in_db: true },
        { name: 'GID_unit', type: 'String', in_db: true },
        { name: 'GID_value', type: 'String', in_db: true },
        { name: 'PD_qty', type: 'String', in_db: true },
        { name: 'PD_unit', type: 'String', in_db: true },
        { name: 'PD_value', type: 'String', in_db: true },
        { name: 'RB_qty', type: 'String', in_db: true },
        { name: 'RB_unit', type: 'String', in_db: true },
        { name: 'RB_value', type: 'String', in_db: true },
        { name: 'REST_qty', type: 'String', in_db: true },
        { name: 'REST_unit', type: 'String', in_db: true },
        { name: 'REST_value', type: 'String', in_db: true },
        { name: 'SALE_qty', type: 'String', in_db: true },
        { name: 'SALE_unit', type: 'String', in_db: true },
        { name: 'SALE_value', type: 'String', in_db: true },
        { name: 'SAND_qty', type: 'String', in_db: true },
        { name: 'SAND_unit', type: 'String', in_db: true },
        { name: 'SAND_value', type: 'String', in_db: true },
        { name: 'UPH_qty', type: 'String', in_db: true },
        { name: 'UPH_unit', type: 'String', in_db: true },
        { name: 'UPH_value', type: 'String', in_db: true },
        { name: 'WH_qty', type: 'String', in_db: true },
        { name: 'WH_unit', type: 'String', in_db: true },
        { name: 'WH_value', type: 'String', in_db: true },
        { name: 'WIPO_value', type: 'String', in_db: true },
        { name: 'WIPO_unit', type: 'String', in_db: true },
        { name: 'WIPO_qty', type: 'String', in_db: true },
        { name: 'OUT_qty', type: 'String', in_db: true },
        { name: 'OUT_unit', type: 'String', in_db: true },
        { name: 'OUT_value', type: 'String', in_db: true },
        { name: 'wholesale', type: 'String', in_db: true },
        { name: 'q1_discount', type: 'String', in_db: true },
        { name: 'q2_discount', type: 'String', in_db: true },
        { name: 'q3_discount', type: 'String', in_db: true },
        { name: 'notes', type: 'String', in_db: true },
        { name: 'piece_length', type: 'String', in_db: true },
        { name: 'ws_percent', type: 'String', in_db: true },
        { name: 'q4_discount', type: 'String', in_db: true },
        { name: 'q4_unit', type: 'String', in_db: true },
        { name: 'Weight_per_Unit', type: 'String', in_db: true }
      ]
      let missing = []
      let extra = []

      //Are we missing any props?
      for (let prop of props) {
        if (!(prop.name in part)) {
          missing.push([prop.name, part[prop.name]])
        }
      }

      //Do we have properties we shouldn't?
      for (let prop in part) {
        if (!props.some(x => x.name == prop)) {
          extra.push(prop)
        }
      }

      let missingCount = missing.length
      let extraCount = extra.length
      let missingColor = missingCount > 0 ? 'red' : 'green'
      let extraColor = extraCount > 0 ? 'red' : 'green'
      showId = showId ? '# ' + part.id : ''
      console.log(
        `%cValidating %cpart ${showId} - %c${missingCount} %cmissing props. %c${extraCount} %cextra props`,
        'color:#40d9ff;font-weight:bold',
        'color:purple;font-weight:bold',
        `color:${missingColor};font-weight:bold`,
        'color:#40d9ff;font-weight:bold',
        `color:${extraColor};font-weight:bold`,
        'color:#40d9ff;font-weight:bold'
      )
      if (missingCount > 0) {
        console.log(
          `%c${JSON.stringify('Missing:')}`,
          'color:red;font-weight:bold'
        )
        console.table(missing)
      }
      if (extraCount > 0) {
        console.log(
          `%c${JSON.stringify('Extra:')}`,
          'color:red;font-weight:bold'
        )
        console.table(extra)
      }
      // if (missingCount + extraCount > 0) {
      //   alert('Error - check console')
      // }
      return true
    },
    validate_client(client) {
      let props = [
        { name: 'client_id', type: 'String', in_db: true },
        { name: 'archive', type: 'String', in_db: true },
        { name: 'company', type: 'String', in_db: true },
        { name: 'first_name', type: 'String', in_db: true },
        { name: 'middle_initial', type: 'String', in_db: true },
        { name: 'last_name', type: 'String', in_db: true },
        { name: 'title', type: 'String', in_db: true },
        { name: 'retail_newsletter', type: 'String', in_db: true },
        { name: 'client_type', type: 'String', in_db: true },
        { name: 'business_type', type: 'String', in_db: true },
        { name: 'phone_1', type: 'String', in_db: true },
        { name: 'phone_1_text', type: 'String', in_db: true },
        { name: 'suffix', type: 'String', in_db: true },
        { name: 'phone_2', type: 'String', in_db: true },
        { name: 'phone_2_text', type: 'String', in_db: true },
        { name: 'phone_3', type: 'String', in_db: true },
        { name: 'phone_3_text', type: 'String', in_db: true },
        { name: 'phone_4', type: 'String', in_db: true },
        { name: 'phone_4_text', type: 'String', in_db: true },
        { name: 'fax', type: 'String', in_db: true },
        { name: 'address1', type: 'String', in_db: true },
        { name: 'address2', type: 'String', in_db: true },
        { name: 'city', type: 'String', in_db: false },
        { name: 'state', type: 'String', in_db: false },
        { name: 'zip', type: 'String', in_db: true },
        { name: 'country', type: 'String', in_db: true },
        { name: 'ship_address1', type: 'String', in_db: true },
        { name: 'ship_city', type: 'String', in_db: true },
        { name: 'ship_state', type: 'String', in_db: true },
        { name: 'ship_zip', type: 'String', in_db: true },
        { name: 'email', type: 'String', in_db: false },
        { name: 'catalog_parts', type: 'String', in_db: true },
        { name: 'wholesale_newsletter', type: 'String', in_db: true },
        { name: 'topping', type: 'String', in_db: true },
        { name: 'catalog_carriage', type: 'String', in_db: true },
        { name: 'tax_status', type: 'String', in_db: true },
        { name: 'tax_number', type: 'String', in_db: true },
        { name: 'start_date', type: 'String', in_db: true },
        { name: 'notes', type: 'String', in_db: true },
        { name: 'ship_address2', type: 'String', in_db: true },
        { name: 'ship_country', type: 'String', in_db: true },
        { name: 'created_at', type: 'String', in_db: true },
        { name: 'updated_at', type: 'String', in_db: true },
        { name: 'updated_by', type: 'String', in_db: true },
        { name: 'ship_name', type: 'String', in_db: true },
        { name: 'billing_address1', type: 'String', in_db: true },
        { name: 'billing_address2', type: 'String', in_db: true },
        { name: 'billing_city', type: 'String', in_db: true },
        { name: 'billing_state', type: 'String', in_db: true },
        { name: 'billing_zip', type: 'String', in_db: true },
        { name: 'billing_country', type: 'String', in_db: true }
      ]
      let missing = []
      let extra = []

      //Are we missing any props?
      for (let prop of props) {
        if (!(prop.name in client)) {
          missing.push([prop.name])
        }
        if (prop.required && !client[prop.name]) {
          missing.push([prop.name, 'Required' + client[prop.name]])
        }
      }
      //Do we have properties we shouldn't?
      for (let prop in client) {
        if (!props.some(x => x.name == prop)) {
          extra.push(prop)
        }
      }

      let missingCount = missing.length
      let extraCount = extra.length
      let missingColor = missingCount > 0 ? 'red' : 'green'
      let extraColor = extraCount > 0 ? 'red' : 'green'
      console.log(
        `%cValidating %cclient # ${client.client_id}  %c${missingCount} %cmissing props. %c${extraCount} %cextra props`,
        'color:#40d9ff;font-weight:bold',
        'color:purple;font-weight:bold',
        `color:${missingColor};font-weight:bold`,
        'color:#40d9ff;font-weight:bold',
        `color:${extraColor};font-weight:bold`,
        'color:#40d9ff;font-weight:bold'
      )
      if (missingCount > 0) {
        console.log(
          `%c${JSON.stringify('Missing:')}`,
          'color:red;font-weight:bold'
        )
        console.table(missing)
      }
      if (extraCount > 0) {
        console.log(
          `%c${JSON.stringify('Extra:')}`,
          'color:red;font-weight:bold'
        )
        console.table(extra)
      }
      // if (missingCount + extraCount > 0) {
      //   alert('Error - check console')
      // }
      return true
    }
  }
}
