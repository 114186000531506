<template>
  <v-card>
    <v-list-item class="pa-0">
      <v-icon v-if="mini" class="chevron">mdi-chevron-right</v-icon>
      <v-list-item-content>
        <v-list-item class="px-2">
          <v-list-item-avatar tile height="80" width="140">
            <v-img src="../assets/WC_logo.jpg"></v-img>
          </v-list-item-avatar>

          <div class="menu">
            <v-tooltip bottom v-if="mini">
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="$emit('toggle', !mini)">
                  mdi-pin
                </v-icon>
              </template>
              Lock menu
            </v-tooltip>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" @click="$emit('toggle', !mini)">
                  mdi-menu-open
                </v-icon>
              </template>
              Hide menu
            </v-tooltip>
          </div>
        </v-list-item>

        <v-list dense>
          <div class="subheader">Customers</div>
          <v-list-item menu>
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" block class="text-none">
                  {{ get_customer_type }}
                  <v-icon>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title @click="set_customer_type('All customers')"
                    >All customers</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Wholesale')"
                    >Wholesale</v-list-item-title
                  >
                </v-list-item>
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Retail')"
                    >Retail</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Supplier')"
                    >Supplier</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Transport')"
                    >Transport</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  ><v-list-item-title
                    @click="set_customer_type('Disability aid')"
                    >Disability aid</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Fire')"
                    >Fire</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Personal')"
                    >Personal</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Other')"
                    >Other</v-list-item-title
                  ></v-list-item
                >
                <v-list-item
                  ><v-list-item-title @click="set_customer_type('Retail')"
                    >Retail</v-list-item-title
                  ></v-list-item
                >
              </v-list>
            </v-menu>
          </v-list-item>
        </v-list>
        <v-list dense centered>
          <v-list-item>
            <div class="input-group">
              <v-text-field
                dense
                outlined
                clearable
                append-icon="mdi-magnify"
                @input="set_search($event)"
                @click:append="customer_list()"
                @keypress.13.prevent="customer_list()"
                label="Customer search"
              />
            </div>
          </v-list-item>

          <div class="subheader">Data entry</div>
          <v-list-item to="/parts_list"> Parts entry & edit </v-list-item>
          <FilterParts v-if="$route.path == '/parts_list'"></FilterParts>
          <v-list-item to="/inventory_list"> Inventory </v-list-item>
          <!-- <v-list-item to="/enter_inventory"> Enter inventory </v-list-item> -->

          <v-list-item to="/index_entry"> Index entry </v-list-item>
          <v-list-item to="/audit"> Audits </v-list-item>
        </v-list>
        <div class="subheader">Reports</div>
        <v-list-item @click="show_report_options = !show_report_options"
          >Customer reports</v-list-item
        >

        <div class="report_options" v-if="show_report_options == true">
          <v-list-item to="/reports_cheat_sheet">
            Reports - Cheat sheet
          </v-list-item>
          <v-list-item to="/reports_directory_report">
            Reports - Directory report
          </v-list-item>
          <v-list-item to="/reports_pocket_directory">
            Reports - Pocket Directory
          </v-list-item>
          <v-list-item to="/mailing_lists">
            Mailing list CSV
          </v-list-item>
          <v-list-item to="/reports_calendar"> Reports - Calendar </v-list-item>
        </div>

        <v-list-group>
          <template v-slot:activator>
            <v-list-item-title>Price lists</v-list-item-title>
          </template>

          <v-list-item to="/reports_compact_master_price_list">
            Compact Master price list
          </v-list-item>
          <v-list-item to="/reports_office_copy"> Office copy </v-list-item>
          <v-list-item to="/reports_shop_copy">
            Phone/Counter/Shop copy
          </v-list-item>
          <v-list-item to="/reports_warehouse_report">
            Warehouse report
          </v-list-item>
          <v-list-item to="/reports_parent_parts">
            Parent parts
          </v-list-item>
          <v-list-item to="/reports_pricelist_csv">
            Customer pricelist CSV Export
          </v-list-item>
          <v-list-item to="/reports_customer_pricelist">
            Customer pricelist PDF
          </v-list-item>
        </v-list-group>
        <div class="subheader">Maintenance</div>
        <v-list-item to="/production_costs">
          Update production costs
        </v-list-item>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import FilterParts from '../components/FilterParts'
export default {
  name: 'TheSidebar',
  components: {
    FilterParts
  },
  props: {
    mini: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      local_search: '',
      sidebar_folded: false,
      // customer_type: 'All Customers',
      search_input: null,
      customer_list_pdf_url: '',
      mytype: '',
      drawer: true,
      items: [
        { title: 'Home', icon: 'mdi-home-city' },
        { title: 'My Account', icon: 'mdi-account' },
        { title: 'Users', icon: 'mdi-account-group-outline' }
      ],
      show_report_options: false,
      show_parts_options: false
    }
  },
  computed: {
    ...mapGetters(['get_customer_type']),
    filter: {
      get() {
        return this.$store.state.filter
      },
      set(value) {
        this.$store.commit('set_filter', value)
      }
    }
  },
  methods: {
    set_search(event) {
      this.search_input = event
    },
    customer_list() {
      this.$store.commit('set_search', this.search_input)

      if (this.$route.name !== 'CustomerList') {
        this.$router.push({
          name: 'CustomerList',
          params: {
            customerType: this.get_customer_type,
            search: this.local_search
          }
        })
      }
    },
    set_customer_type(type) {
      this.$store.dispatch('set_customer_type', type)
    },

    print_customers() {
      this.$refs.print_customer_list.show()
    },
    update_filter(value) {
      this.$store.commit('set_filter', value)
    }
  }
}
</script>

<style lang="scss" scoped>
#sidebar {
  text-align: center;
}
::v-deep .subheader {
  text-align: center !important;
  width: 100%;
  background-color: rgb(166, 219, 255);
}

.report_options {
  border-right: 2px solid #bce383;
  border-left: 2px solid #bce383;
  border-bottom: 2px solid #bce383;
  padding-left: 20px;
  border-radius: 30px;
  padding-top: 50px;
  padding-bottom: 8px;
  top: -54px;
}
</style>
