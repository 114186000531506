import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import GSignInButton from 'vue-google-signin-button'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import mixins from '@/mixins/mixins'
import vuetify from './plugins/vuetify'
// import vuetify from '@/plugins/vuetify'
// import 'vuetify/dist/vuetify.min.css'
Vue.mixin(mixins)
Vue.config.productionTip = false

Vue.use(GSignInButton)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

new Vue({
  router,

  // vuetify,
  store,

  vuetify,
  render: h => h(App)
}).$mount('#app')
