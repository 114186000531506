<template>
  <v-container>
    There's nothing to see here. Loading this page prompts you to download the
    CSV file...<b-embed :src="csvURL"></b-embed
  ></v-container>
</template>

<script>
export default {
  name: '',
  props: {},
  data() {
    return {
      csvURL: process.env.VUE_APP_API_URL + 'pricelist_CSV.php'
    }
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {}
}
</script>

<style scoped>
.card_title {
  background-color: rgb(212, 212, 212);
  color: rgb(0, 0, 0);
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
::v-deep .v-text-field__slot .v-label {
  color: rgb(218, 148, 148) !important;
}
::v-deep .v-text-field__slot .v-label--active {
  color: rgb(24, 145, 0) !important;
}
::v-deep .mycheckbox label {
  margin: 0px 20px 0px -7px;
}
::v-deep .mycheckbox {
  padding: 0px 0px 0px 0px;
}
</style>