<template>
  <div>
    <div class="background">
      <v-progress-circular
        :size="50"
        class="spinner"
        indeterminate
      ></v-progress-circular>
    </div>

    <iframe id="printPdf" name="printPdf"></iframe>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.tableLayouts = {
  myLayout: {
    fillColor: function (i, node) {
      return i % 2 === 0 ? '#eeeeee' : null
    },
    hLineWidth: function (i, node) {
      return 1
    },
    vLineWidth: function (i) {
      return 1
    },
    hLineColor: function (i) {
      return '#aaa'
    },
    paddingTop: function (i) {
      return 5
    },
    paddingRight: function (i, node) {
      return i === node.table.widths.length - 1 ? 0 : 8
    }
  }
}
export default {
  name: 'ReportsCalendar',
  data() {
    return {
      clients: [],
      header: [
        {
          text: 'Cal',
          fontSize: 10,
          color: '#222222',
          border: [false, false, false, false]
        },
        {
          text: '(Company) Name ',
          fontSize: 10,
          color: '#222222',
          border: [false, false, false, false]
        },
        {
          text: 'Address',
          fontSize: 10,
          color: '#222222',
          border: [false, false, false, false]
        },
        {
          text: 'customer type',
          fontSize: 10,
          color: '#222222',
          border: [false, false, false, false]
        }
      ],

      body: { fontSize: 8, color: 'black', padding: [5, 5, 5, 5] } // used as a style
    }
  },

  computed: {
    names() {
      return _.map(this.clients, x => {
        return x.first_name + ' ' + x.last_name
      })
    },

    row() {
      // This takes the original data and creates a new array for each element of the original.  Then we can pick and choose fields.
      var newarray = []
      this.clients.forEach(function (client) {
        newarray.push([
          {
            text: '',
            style: this.body,
            border: [true, true, true, true],
            padding: [10, 10, 10, 10]
          }, //   { text: client.LastName, style: this.lastname },
          this.create_name(client),
          {
            text:
              client.address1 +
              ' -' +
              client.address2 +
              ' ' +
              client.city +
              ', ' +
              client.state +
              ' ' +
              client.zip +
              ' ' +
              client.country,
            style: this.body,

            border: [false, false, false, false]
          },
          {
            text: client.client_type,
            style: this.body,
            border: [false, false, false, false]
          }
        ])
      }, this)
      newarray.unshift(this.header)
      return newarray
    }
  },
  mounted() {
    this.load_clients()
  },

  /* ----------- ----------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    create_name(client) {
      if (client.company) {
        return {
          text: [
            { text: client.company, bold: 'true', style: this.body },
            {
              text:
                ' - ' +
                client.last_name +
                (client.last_name ? ', ' : ' ') +
                client.first_name +
                ' ' +
                client.middle_initial,
              style: this.body
            }
          ],
          border: [false, false, false, false]
        }
      }
      return {
        text:
          client.last_name +
          ', ' +
          client.first_name +
          ' ' +
          client.middle_initial,
        bold: 'true',
        border: [false, false, false, false],
        style: this.body
      }
    },
    /* ---------------------------------------- generate() { ---------------------------------------- */
    //http://pdfmake.org/playground.html for examples

    generate() {
      var tmpdoc = document.getElementById('printPdf').contentWindow.document
      tmpdoc.open()
      tmpdoc.write('Loading Cheat Sheet...')
      tmpdoc.close()
      var date = dayjs().format('M/D/YYYY h:mm A')
      //console.log(date)
      var docDefinition = {
        footer: function (currentPage, pageCount) {
          return {
            table: {
              body: [
                [
                  {
                    text:
                      'Printed ' +
                      date +
                      ' Page ' +
                      currentPage.toString() +
                      ' of ' +
                      pageCount,
                    alignment: 'right',
                    style: 'normalText',
                    fontSize: '8',
                    margin: [40, -5, 0, 20]
                  }
                ]
              ]
            },
            layout: 'noBorders'
          }
        },
        content: [
          //this.title,
          {
            table: {
              widths: [540],
              heights: [20],
              body: [
                [
                  {
                    text: 'Wholesale Customer Calendar List',
                    fontSize: 18,
                    color: '#333333',
                    border: [false, false, false, false],
                    fillColor: ''
                  }
                ]
              ]
            }
          },
          {
            table: {
              headerRows: 1,
              widths: [20, 200, 200, 100],
              heights: 12,
              dontBreakRows: true,
              body: this.row

              // body: [this.names]
            },

            // layout: {
            //   fillColor: function (rowIndex) {
            //     return rowIndex % 2 === 0 ? '#eeeeee' : null
            //   }
            // }
            layout: pdfMake.tableLayouts.myLayout
          }
        ],
        pageMargins: [30, 20, 30, 20],
        pageSize: 'LETTER'
      }
      //   pdfMake.createPdf(docDefinition).download("Directory.pdf");
      pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf'])
    },

    /* ----------------------------------- async load_clients() { ----------------------------------- */

    async load_clients() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_clients.php',
          {
            customer_type: 'wholesale',
            search: ''
          }
        )
        this.clients = _.orderBy(response.data, [
          x => {
            return x.company
          },
          x => {
            return x.last_name + x.first_name
          }
        ]) // This is where we get the data
        // Validated 02/09/2021
        this.validate_client(this.clients[0])
        this.generate()
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.background {
  position: absolute;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
}
.spinner {
  margin: 99px;
}
#printPdf {
  position: absolute;
  top: 0px;
  display: inline;
  height: 1000px;
  width: 1200px;
}
</style>
