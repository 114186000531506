<template>
  <div>
    <div class="background">
      <v-progress-circular
        :size="50"
        class="spinner"
        indeterminate
      ></v-progress-circular>
    </div>

    <iframe id="printPdf" name="printPdf"
      ><div style="color: red; width: 900px; height: 900px">asfsaf</div></iframe
    >
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import pdfMake from 'pdfmake/build/pdfmake'
// import pdfFonts from 'pdfmake/build/vfs_fonts'
import dayjs from 'dayjs'
// pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  name: 'Reports',
  data() {
    return {
      clients: [],
      events: [],
      phoneNumbers: [],
      checked:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAsElEQVQoU43QPQoCQQwF4GR2ERY3JGDrMQQr7Wy8ilq51mKl4k3EU3gKj5FhbHciU6z4syumDB8v4SEzVwCAiFhCy9R1fXfOGRLRJoRwakPNjogqFJGdqm47YI+Zp4g4+QVLEdmr6iqFdcG+iBwSSpee0MyuADDw3p8BIKGjqi6bd94SmXkWYxw650be+8Xrz5+nsSiKcZ7nGkK4fcE/61ljKjzGCFmWUVtFZjYHgMsDcIxOq7WfTN0AAAAASUVORK5CYII=',
      // 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAABM0lEQVQ4T7WTsUoDURBF792tBNnc/QJ/wUZshIjWdlpZqKVgoZ2FgiLYaqOlBKxS+AshAUHExk+IfzC7iCBkd+ShyLrZYEB8zYMZzp078+YRfziUdABgDUDUoNMxs04tHkvaGo1GD0zT9A3ATJMBdz81s5NKLpLUJbnu7ncB9pB09xcAw5pItXJweUtyE8BrWZbtKlyv8kNH0g3JnQAWRbGS5/nTGNxqtc6DkSzLjsIdFNI0vQawWwVDfAyWNCQ55+5XZrYn6YLkfh1shJMkWYjjuAdgFsA9gKUmsBEOwZrAd4+S5gEIgJnZ88SBJUmyGEXRWVmWx3mePwZRSX2SbXcfmNny1NP+V3gAoP/Lqm9/vcSnbUkFyaa9nqjj7j0zWw09d919gySn+WDu/g7g0MwuPwBO0ueYukjBBQAAAABJRU5ErkJggg==',
      unchecked:
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAWklEQVQoU93QMQ6AIBSD4RZWGziX1xFn4+xVPBwJrvAMgxsxznZsvulnCCEBIMkJg9VaL+ecUdJaSjlG6PkkJcYY95zz9ga7+Rf8mGdhD95ag/deo0RmNgM4b1GbMqu0kjFBAAAAAElFTkSuQmCC',

      main_layout: {
        defaultBorder: false,

        paddingLeft: function () {
          return 0
        },
        paddingRight: function () {
          return 0
        },
        paddingTop: function () {
          return 0
        },
        paddingBottom: function () {
          return 0
        }
      },
      horizontal_line_table_layout: {
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0
          }
          return i === node.table.headerRows ? 1 : 0
        },
        hLineColor: '#BBBBBB',
        vLineWidth: function () {
          return 0
        },
        paddingLeft: function (i) {
          return i === 0 ? 0 : 8
        },
        paddingRight: function (i, node) {
          return i === node.table.widths.length - 1 ? 0 : 8
        }
      },
      events_table_style: { fontSize: 8 },
      events_table_layout: {
        defaultBorder: true,
        paddingLeft: function () {
          return 0
        },
        paddingRight: function () {
          return 0
        },
        paddingTop: function () {
          return 0
        },
        paddingBottom: function () {
          return 0
        },
        hLineWidth: function (i, node) {
          if (i === 0 || i === node.table.body.length) {
            return 0
          }
          return i === node.table.headerRows ? 1 : 0
        },
        vLineWidth: function () {
          return 0
        }
      },
      events_table_layout_divider: {
        defaultBorder: true,

        paddingLeft: function () {
          return 0
        },
        paddingRight: function () {
          return 0
        },
        paddingTop: function () {
          return 0
        },
        paddingBottom: function () {
          return 0
        },
        hLineWidth: function (i, node) {
          if (i === 0) {
            return 1
          }
          return i === node.table.headerRows ? 1 : 0
        },
        hLineColor: '#EEEEEE',
        hLineStyle: function () {
          return { dash: { length: 450, space: 430 } }
        },

        vLineWidth: function () {
          return 0
        }
      }
    }
  },

  computed: {
    // names() {
    //   return _.map(this.clients, (x) => {
    //     return x.first_name + " " + x.last_name;
    //   });
    // },
    my_events() {
      return _.groupBy(this.events, x => x.client_id)
    },

    rows() {
      //! IF A CLIENT HAS TOO MANY EVENT THEY WONT PRINT AT ALL..  See AAA Carriage Rides, should be the first listing.
      var newarray = []
      this.clients.forEach(client => {
        // If this is between customer type sections, insert page break
        if (client.client_id == 'page_break') {
          var page_break = [{ text: '', pageBreak: 'after' }]
          newarray.push(page_break)
          return true
        }

        var phoneNumbers = []

        client.phoneNumbers.forEach(x => {
          phoneNumbers.push([
            {
              text: [
                { text: x.number, fontSize: 10 },
                { text: x.text, fontSize: 9 }
              ]
            }
          ])
        })

        var events_table = {
          table: {
            body: [client.row7]
          },
          margin: [30, 0, 0, 0],
          layout: this.events_table_layout_divider,
          colSpan: 2
        }

        var col1 = {
          table: {
            widths: [125],
            body: [
              [
                {
                  text: client.company,
                  fontSize: 12,
                  bold: true
                }
              ],
              [
                {
                  text:
                    client.last_name +
                    client.first_name +
                    client.middle_initial,
                  alignment: 'left',
                  fontSize: 10,
                  marginLeft: 10,
                  bold: true
                }
              ],
              [
                {
                  text: client.titleLine,
                  alignment: 'left',
                  fontSize: 9,
                  margin: [10, 0, 0, 0]
                }
              ],

              [
                {
                  stack: phoneNumbers,
                  margin: [10, 0, 0, 0]
                }
              ],
              [
                {
                  text: client.emailLine,
                  alignment: 'left',
                  fontSize: 8
                }
              ]
            ]
          },
          layout: {
            defaultBorder: false,
            paddingLeft: function () {
              return 0
            },
            paddingRight: function () {
              return 0
            },
            paddingTop: function () {
              return 0
            },
            paddingBottom: function () {
              return 0
            }
          }
        }

        /* ---------------------------------------- Address info ---------------------------------------- */

        var address_info = [
          {
            stack: [
              {
                text: client.address1 + '\n' + client.address2,
                fontSize: 9
              },
              {
                text: client.city + ' ' + client.state + ' ' + client.zip,
                fontSize: 9
              }
            ]

            //relativePosition: { x: 0, y: 0 }
          }
        ]

        /* ---------------------------------------- Business info --------------------------------------- */

        var business_info = [
          {
            text: client.client_type,
            alignment: 'right',

            fontSize: 9
          },
          {
            text: client.client_id,
            alignment: 'right',
            fontSize: 10,
            decoration: 'underline'
          },
          {
            text: client.business_type,
            alignment: 'left',
            fontSize: 9,
            color: '#555555'
          }
        ]
        var notes_info = [
          { text: client.notes ? 'notes:' : '', fontSize: 8, color: '#555555' },
          {
            text: client.notes,
            fontSize: 8,
            italics: true,
            margin: [5, 0, 0, 0]
          }
        ]

        /* ---------------------------------------- Shipping info --------------------------------------- */

        var shipping_info = [
          {
            stack: [
              {
                text: client.ship_address1 ? 'Shipping address:' : '',

                color: 'gray',
                fontSize: 8
              },
              {
                stack: [
                  {
                    text: client.ship_address1 + '\n' + client.ship_address2,
                    fontSize: 9
                  },
                  {
                    text:
                      client.ship_city +
                      ' ' +
                      client.ship_state +
                      ' ' +
                      client.ship_zip,
                    fontSize: 9
                  }
                ]
              }
            ]
          }
        ]

        /* ----------------------------------- Checkboxes and topping ----------------------------------- */

        var checkboxes = [
          {
            columns: [
              {
                image:
                  client.catalog_parts == 'TRUE'
                    ? this.checked
                    : this.unchecked,
                fit: [10, 10],
                width: 10
              },
              {
                text: 'Parts catalog',
                fontSize: 9,
                width: 60,
                backgroundColor: '#aaa',
                relativePosition: { x: 2, y: -1 }
              },
              {
                image:
                  client.catalog_carriage == 'TRUE'
                    ? this.checked
                    : this.unchecked,
                fit: [10, 10],
                width: 10
              },
              {
                text: 'Carriage catalog',
                fontSize: 9,
                width: 75,
                relativePosition: { x: 2, y: -1 }
              },
              {
                image:
                  client.retail_newsletter == 'TRUE'
                    ? this.checked
                    : this.unchecked,
                fit: [10, 10],
                width: 10
              },
              {
                text: 'Retail news',
                fontSize: 9,
                width: 65,
                relativePosition: { x: 2, y: -1 }
              },
              {
                image:
                  client.wholesale_newsletter == 'TRUE'
                    ? this.checked
                    : this.unchecked,
                fit: [10, 10],
                width: 10
              },
              {
                text: 'Wholesale news',
                fontSize: 9,
                // width: 90,
                relativePosition: { x: 2, y: -1 }
              },
              {
                text: 'top:',
                alignment: 'right',
                color: 'gray',
                fontSize: 9,
                width: 16
              },
              {
                text: client.topping,
                alignment: 'left',
                fontSize: 9
                // width: 25
              }
            ],
            margin: [0, 5, 0, 5]
          }
        ]

        /* ------------------------------------------ Tax info ------------------------------------------ */

        var tax_info = [
          {
            columns: [
              {
                text: 'TAX or NO-TAX:',
                alignment: 'right',
                color: 'gray',
                fontSize: 9,
                width: 65
              },

              {
                text: client.tax_status,
                alignment: 'left',
                fontSize: 10,
                width: 120
              },

              {
                text: 'EIN or SS#:',
                color: 'gray',
                alignment: 'right',
                fontSize: 9,
                width: 50
              },

              {
                text: client.tax_number,
                alignment: 'left',
                fontSize: 10
              },

              {
                text: client.start_date,
                alignment: 'right',
                fontSize: 7,
                width: 38
              }
            ]
          }
        ] //Combine addresses and notes into a "box" for inserting
        let addresses_and_notes = [
          {
            columns: [
              { stack: address_info, width: 130 },
              { stack: shipping_info, width: 130 }
            ]
          },
          notes_info
        ]

        /* --------------------------------------- Horizontal Line -------------------------------------- */
        // This object causes an error "Cannot read property _minWidth of undefined" the first time it is run, then succeeds on the second click
        // This has somethign to do with colSpan
        let horizontal_line = {
          table: {
            headerRows: 1,
            widths: [555],
            body: [[''], ['']]
          },
          layout: this.horizontal_line_table_layout,
          colSpan: 2
        }

        /* ------------------------------------ --------------------- ----------------------------------- */
        // Put customer info and events into a table with a single cell to prevent page breaks within one customer
        // Each row must have 2 cells
        let wrapper_table_cell = {
          table: {
            body: [
              [horizontal_line, {}],
              [
                col1,
                {
                  stack: [
                    {
                      columns: [
                        { stack: addresses_and_notes, width: 320 },
                        { stack: business_info, width: '*' }
                      ]
                    },
                    checkboxes,
                    tax_info
                  ]
                }
              ],
              client.row7 ? [events_table, {}] : [{}, {}]
            ],
            widths: [130, '*']
          },
          layout: this.main_layout
        }

        newarray.push([wrapper_table_cell])
      })

      return newarray
    }
  },
  mounted() {
    this.load_clients()
  },

  /* ----------- ----------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    initializeClient() {
      this.clients.forEach(x => {
        console.log(x.client_id)

        if (x.first_name !== undefined && x.first_name !== '') {
          x.first_name = x.first_name + ' '
        }
        if (x.last_name !== undefined && x.last_name !== '') {
          x.last_name = x.last_name + ', '
        }

        var email_array = []
        if (x.email !== undefined && x.email !== '') {
          email_array.push({ text: x.email })
        }
        x.emailLine = email_array

        var title_array = []
        if (x.title !== undefined && x.title !== '') {
          title_array.push({ text: 'Title: ' + x.title })
        }
        x.titleLine = title_array

        /* ---------------------------------------- PHONE NUMBERS --------------------------------------- */

        var phone_number_array = [{ number: '', text: '' }]
        // var phone_number_array = [];
        if (x.phone_1 !== undefined && x.phone_1 !== '') {
          phone_number_array.push({
            number: x.phone_1,
            text: ' ' + x.phone_1_text
          })
        }
        if (x.phone_2 !== undefined && x.phone_2 !== '') {
          phone_number_array.push({
            number: x.phone_2,
            text: ' ' + x.phone_2_text
          })
        }
        if (x.phone_3 !== undefined && x.phone_3 !== '') {
          phone_number_array.push({
            number: x.phone_3,
            text: ' ' + x.phone_3_text
          })
        }
        if (x.phone_4 !== undefined && x.phone_4 !== '') {
          phone_number_array.push({
            number: x.phone_4,
            text: ' ' + x.phone_4_text
          })
        }
        if (x.fax !== undefined && x.fax !== '') {
          phone_number_array.push({ number: x.fax, text: ' fax' })
        }
        x.phoneNumbers = phone_number_array

        /* ------------------------------------------- EVENTS ------------------------------------------- */

        if (!this.my_events[x.client_id]) {
          return
        }
        x.events = _.orderBy(this.my_events[x.client_id], 'event_date', 'desc')
        var count = x.events.length
        var temp1 = []
        var temp2 = []

        x.events.forEach(x => {
          if (temp1.length * 2 < count) {
            temp1.push({
              style: this.events_table_style,
              table: {
                widths: [50, 200],
                body: [
                  [
                    { text: x.event_date },
                    {
                      text:
                        x.event_type +
                        ' - ' +
                        (x.carriage_type ? x.carriage_type + ' - ' : '') +
                        x.notes +
                        (x.job_number != 0 ? ' - ' + x.job_number : '')
                    }
                  ]
                ]
              },
              layout: this.events_table_layout
            })
          } else {
            temp2.push({
              style: this.events_table_style,
              table: {
                widths: [50, 200],
                body: [
                  [
                    { text: x.event_date },
                    { text: x.event_type + ' - ' + x.notes }
                  ]
                ]
              },
              layout: this.events_table_layout
            })
          }
        })
        x.eventsArrayCol1 = temp1
        x.eventsArrayCol2 = temp2

        x.row7 = [
          {
            columns: [
              // { width: 25, text: ' ' },
              { stack: x.eventsArrayCol1 },
              { stack: x.eventsArrayCol2 }
            ],
            columnGap: 3
          }
        ]
      })
    },
    /* ---------------------------------------- generate() { ---------------------------------------- */
    //http://pdfmake.org/playground.html for examples

    generate() {
      var tmpdoc = document.getElementById('printPdf').contentWindow.document
      tmpdoc.open()
      tmpdoc.write('Loading directory report...')
      tmpdoc.close()
      var date = dayjs().format('M/D/YYYY')
      //console.log(date)
      var docDefinition = {
        footer: function (currentPage, pageCount) {
          return {
            table: {
              body: [
                [
                  {
                    text: `Printed ${date} - Page ${currentPage.toString()} of ${pageCount}`,
                    alignment: 'right',
                    fontSize: 8,
                    margin: [40, -10, 0, -10]
                  }
                ]
              ]
            },
            layout: 'noBorders'
          }
        },
        content: [
          {
            table: {
              headerRows: 0,

              dontBreakRows: true,
              body: this.rows
            },
            layout: this.main_layout
          }
        ],
        defaultStyle: {
          font: 'Roboto'
        },
        pageMargins: [30, 0, 30, 10],
        pageSize: 'LETTER'
      }
      pdfMake.fonts = {
        Myriad: {
          normal: process.env.VUE_APP_FONT_URL + '/Myriad-Pro-Condensed.ttf',
          bold: process.env.VUE_APP_FONT_URL + '/Myriad-Pro-Bold-Condensed.ttf',
          italics: process.env.VUE_APP_FONT_URL + '/Myriad-Pro-Italic.ttf'
        },
        //  download default Roboto font from cdnjs.com
        Roboto: {
          normal:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
          bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
          italics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
          bolditalics:
            'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
        }
      }
      //   pdfMake.createPdf(docDefinition).download("Directory.pdf");
      pdfMake.createPdf(docDefinition).open({}, window.frames['printPdf'])
    },

    /* ----------------------------------- async load_clients() { ----------------------------------- */

    async load_clients() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_directory_report.php',
          {
            search: ''
          }
        )
        this.clients = response.data.clients // This is where we get the data
        this.events = response.data.events // This is where we get the data
        //console.log(response.data)
        this.initializeClient()
        this.generate()

        this.generate()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.background {
  position: absolute;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-content: center;
}
.spinner {
  margin: 99px;
}
#printPdf {
  position: absolute;
  top: 0px;
  display: inline;
  height: 1000px;
  width: 1200px;
}
</style>
