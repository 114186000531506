<template>
  <v-card elevation="10" width="1200">
    <v-app-bar app fixed>
      <v-text-field
        v-model="local_search"
        @change="$store.commit('parts/set_part_search', local_search)"
        label="search"
        outlined
        clearable
        hide-details
        dense
        class="mr-2 shrink"
        @keypress.enter="load_parts()"
      >
        <template #append>
          <v-icon @click="load_parts()">mdi-magnify</v-icon>
        </template>
      </v-text-field>
      <v-autocomplete
        class="mt-5"
        :items="categories"
        item-text="category_name"
        item-value="category_name"
        label="category"
        v-model="category"
        @change="load_parts()"
        return-object
      ></v-autocomplete>
      Showing {{ parts.length }} parts
      <template>
        <div class="pagination" v-if="count > 0">
          <v-pagination
            v-model="page"
            :length="pagination_length"
            total-visible="5"
          ></v-pagination>
          <div style="width: 60px" class="pt-3">
            <v-autocomplete
              dense
              hide-details="true"
              label="per page"
              v-model="per_page"
              :items="per_page_options"
            >
            </v-autocomplete>
          </div>
        </div>

        <v-spacer></v-spacer>
        <v-icon @click="new_part" color="green">mdi-plus-circle</v-icon>
      </template>
    </v-app-bar>
    <v-skeleton-loader
      style="padding-top: 50px"
      v-if="loading == true"
      class="mx-auto"
      max-width="800"
      type="card"
    >
    </v-skeleton-loader>
    <v-card-text v-else style="padding-top: 15px" class="px-0">
      <v-simple-table fixed-header dense height="calc(100vh - 67px)">
        <thead class="head">
          <tr>
            <th>
              Part #
              <div class="db_id">db ID</div>
            </th>
            <th style="line-height: 1">
              CATEGORY<BR /><i
                ><span style="color: rgb(145, 99, 14)">Subcategory</span></i
              ><BR /><span style="color: blue">mm group</span>
            </th>
            <th><span class="supplier_name">Supplier</span>/#</th>
            <th>Description</th>
            <th>
              Cost
              <div class="unit_label">unit</div>
            </th>
            <th>
              Retail
              <div class="margin_label">margin</div>
            </th>
            <th>
              WS
              <div class="margin_label">disc.</div>
            </th>
            <th>Qty disc</th>
            <th>Notes</th>
          </tr>
        </thead>
        <SinglePart
          v-for="(part, idx) in paginated_parts"
          :key="idx"
          :passedpart="part"
          :index="idx"
        ></SinglePart>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import SinglePart from '../components/SinglePart'
import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'PartsList',
  components: {
    SinglePart
  },
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('parts/set_parts_data', {
      param: 'parts',
      value: []
    })
    //this.$store.commit('parts/clear_persist_vars')
    next()
  },
  props: {
    hideCols: {
      type: Array,
      default: function() {
        return ['9', 'sum', 'margin']
      }
    },
    passedparent: {
      type: Object,
      default: function() {
        return { id: 0 }
      }
    }
  },
  data() {
    return {
      category: {},
      categories: [],
      per_page: 25,
      page: 1,
      per_page_options: [10, 15, 20, 25, 30, 40, 100, 500, 9999999],
      loading: true,
      local_search: this.$store.getters['parts/get_part_search'],
      expanding: false
    }
  },
  computed: {
    pagination_length() {
      return Math.ceil(this.count / this.per_page)
    },
    paginated_parts() {
      return this.parts.slice(
        this.page * this.per_page - this.per_page,
        this.per_page * this.page
      )
    },
    parts() {
      return this.$store.getters['parts/get_parts']
    },

    count() {
      return this.parts.length
    }
  },
  mounted() {
    this.local_search = this.$store.getters['parts/get_part_search']
    this.get_categories()
    this.load_parts()
  },
  methods: {
    /* --------------------------------------- get_categories --------------------------------------- */
    async get_categories() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parts_cats.php',
          {}
        )
        this.categories = response.data
        console.log(response.data)
      } catch (error) {
        console.log(error)
      }
    },
    /* ----------------------------------------- load_parts ----------------------------------------- */
    async load_parts() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parts.php',
          {
            category_id: this.category.category_id,
            hide_archive: false,
            parents_only: this.$store.state.list_parents,
            search: this.local_search
          }
        )

        console.log(response.data)
        this.loading = false
        this.validate_part(response.data.parts[0])

        this.$store.dispatch('parts/set_parts_data', {
          param: 'parts',
          value: response.data.parts
        })
      } catch (error) {
        console.log(error)
      }
    },
    new_part() {
      this.$store.dispatch('parts/add_part', {
        param: 'parts',
        value: {
          noob: true,
          cost: 0,
          wholesale: 0,
          retail: 0,
          wcs_part_number: 'new',
          description: '',
          q1_unit: '',
          q1_discount: '',
          q2_unit: '',
          q2_discount: '',
          q3_unit: '',
          q3_discount: '',
          q4_unit: '',
          q4_discount: '',
          unit: '',
          supplier_part_number: '',
          notes: ''
        }
      })
    }
  }
}
</script>

<style scoped>
.head th {
  text-align: center !important;
  font-size: 1.2em !important;
}

.head th:nth-child(1),
::v-deep tbody tr td:nth-child(1) {
  width: 100px;
}
.head th:nth-child(3),
::v-deep tbody tr td:nth-child(2) {
  text-align: left;
  width: 100px;
}
.head th:nth-child(4),
::v-deep tbody tr td:nth-child(3) {
  text-align: left;
  width: 380px;
}
.head th:nth-child(5),
::v-deep tbody tr td:nth-child(4) {
  width: 70px;
  text-align: right;
}
.head th:nth-child(6),
::v-deep tbody tr td:nth-child(5) {
  width: 70px;
  text-align: right;
}
.head th:nth-child(7),
::v-deep tbody tr td:nth-child(6) {
  width: 70;
  text-align: right;
}
.head th:nth-child(8),
::v-deep tbody tr td:nth-child(7) {
  min-width: 150px;
}
.head th:nth-child(9),
::v-deep tbody tr td:nth-child(8) {
  width: 150px;
}
.head th:nth-child(10),
::v-deep tbody tr td:nth-child(9) {
  max-width: 20px;
}
.unit_label {
  font-size: 0.8em;
  font-weight: normal !important;
  text-align: right;
  font-style: italic;
  line-height: 1em;
}
.margin_label {
  font-size: 0.8em;
  font-weight: normal !important;
  text-align: right;
  line-height: 1em;
}
.db_id {
  color: purple;
  font-size: 0.6em;
  font-weight: normal !important;
  font-style: italic;
  text-align: right;
}
</style>
