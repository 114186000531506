<template>
  <v-card width="1000">
    <v-card-title class="pa-0">
      <v-app-bar height="100">
        <div class="header_box">
          <v-text-field
            v-model="search"
            label="search"
            outlined
            clearable
            hide-details
            dense
            class="mr-2 shrink"
            @keypress.enter="load_parts()"
          >
            <template #append>
              <v-icon @click="load_parts()"> mdi-magnify </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-app-bar>
    </v-card-title>

    <span v-if="parts.length == 0" style="font-weight: bold; font-size: 20px">
      Please use search to get results.
    </span>
    <v-card-text class="pa-0">
      <v-skeleton-loader
        v-if="loading == true"
        class="mx-auto"
        height="900"
        type="table: table-heading, table-thead, table-tbody, table-tfoot"
      ></v-skeleton-loader>
      <v-data-table
        v-else
        class="parts_table"
        fixed-header
        dense
        :headers="headers"
        :items="parts"
        :items-per-page="10"
      >
        <template #item="{ item }">
          <SingleAddChildPart
            :parent-id="parentId"
            :passedpart="item"
            @added="added"
          ></SingleAddChildPart>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import SingleAddChildPart from '@/components/SingleAddChildPart.vue'
import axios from 'axios'
import _ from 'lodash'
export default {
  name: 'ChildPartsList',
  components: {
    SingleAddChildPart
  },
  props: {
    parentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      parts: [],
      loading: false,
      search: '',
      headers: [
        { text: 'Part#', value: 'wcs_part_number' },
        { text: 'Qty', value: 'qty' }
      ]
    }
  },

  methods: {
    /* ----------------------------------------- load_parts ----------------------------------------- */
    async load_parts() {
      this.loading = true

      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'load_parts.php',
          {
            client_id: 0,
            hide_archive: true,
            search: this.search
          }
        )
        console.log(response.data.parts)
        // this.parts = _.sortBy(response.data, 'wcs_part_number')
        this.parts = response.data.parts
        //!  needs to filter out existing child parts
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    added() {
      this.$emit('added')
    }
  }
}
</script>

<style scoped>
.head th:nth-child(1) {
  width: 120px;
}

::v-deep tbody tr td:nth-child(1) {
  width: 120px;
}
::v-deep tbody tr td:nth-child(2) {
  text-align: left;
  width: 500px;
}
</style>
