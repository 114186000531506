<template>
  <v-text-field
    v-if="!deleted"
    dense
    outlined
    hide-details="auto"
    v-model="part.wcs_part_number"
    :hint="
      part.id +
      ' - ' +
      (part.created_by ? part.created_by + ' @ ' + part.created_at : '')
    "
    persistent-hint
  >
    <template #append>
      <v-icon @click="delete_index_tag">mdi-trash-can </v-icon>
    </template>
  </v-text-field>
</template>

<script>
import axios from 'axios'
export default {
  name: 'SingleIndexTagPart',
  props: {
    passedPart: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      part: { ...this.passedPart },
      deleted: false
    }
  },
  computed: {},
  methods: {
    async delete_index_tag() {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + 'delete_index_tag.php',
          {
            tag: this.part
          }
        )
        console.log(response.data)
        this.$emit('deleted', this.part.id)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
</style>