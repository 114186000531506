<template>
  <div>
    <b-form-group>
      <v-autocomplete
        :value="chosen.category_name"
        dense
        label="Category"
        outlined
        hide-details="true"
        :items="cat_list"
        item-text="category_name"
        data-lpignore="true"
        @change="set_cat"
        return-object
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'CatPicker',
  props: {
    catName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      chosen: {
        category_name: this.catName,
        id: 0
      }
    }
  },
  computed: {
    cat_list: function () {
      return this.$store.getters['parts/get_parts_cats']
    }
    // cat_names: function () {
    //   return this.cat_list.map(x => {
    //     return { value: x.category_name, text: x.category_name }
    //   })
    // }
  },
  mounted() {},
  methods: {
    /* ---------------------------------------------------------------------------------------------- */
    /*                                         SET_cat() {                                         */
    /* ---------------------------------------------------------------------------------------------- */

    set_cat(x) {
      this.chosen = x
      this.$emit('output', x)
    },
    create_text(c) {
      return c.category_name + ' - ' + c.category_id
    }
  }
}
</script>

<style scoped></style>
