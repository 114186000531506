<template>
  <tr :class="row_class" @dblclick="start_edit">
    <td>
      {{ part.wcs_part_number }}
      <v-icon color="success">child-tree</v-icon>
      <b-icon-box-arrow-in-up
        v-if="part.has_parents > 0"
      ></b-icon-box-arrow-in-up>
      <div class="id">{{ part.id }}</div>
    </td>
    <td>
      <div class="category">{{ part.category_name }}</div>
      <div class="subcategory">{{ part.subcategory }}</div>
      <div class="mm">{{ part.mm_group }}</div>
    </td>
    <td>
      <span class="supplier_name">{{ part.supplier_name }}</span>
      <div class="supplier_part">{{ part.supplier_part_number }}</div>
    </td>

    <td>{{ part.description }}</td>
    <td class="hidden_icon">
      <div class="cell">
        <div :class="part.cost == 0 ? 'myred cost' : 'cost'">
          {{ money_format(part.cost) }}
        </div>
        <div v-if="part.unit" class="unit">per {{ part.unit }}</div>
        <div v-if="part.production_cost > 0" class="production_cost">
          {{ money_format(part.production_cost) }}
        </div>
        <div class="clock" @click="show_history('cost')">
          <div class="clockwords">history</div>
          &#x1F551;
        </div>
      </div>
    </td>
    <td>
      <div :class="part.retail == 0 ? 'myred retail' : 'retail'">
        {{ money_format(part.retail) }}
      </div>
      <div class="markup">{{ retail_margin }}%</div>
    </td>
    <td>
      <div :class="part.wholesale == 0 ? 'myred wholesale' : 'wholesale'">
        {{ money_format(part.wholesale) }}
      </div>
      <div class="markup">
        {{ percent_format(1 - part.wholesale / part.retail) }}
      </div>
    </td>
    <!-- QUANTITY DISCOUNTS -->
    <td>
      <div v-if="part.q1_discount > 0">
        <div class="qty_unit">x{{ part.q1_unit }}</div>

        {{ percent_format(part.q1_discount / 100) }}
        <div class="qty_price">
          {{
            Number(part.cost) < 1
              ? money_format(qty_discount.q1, 3)
              : money_format(qty_discount.q1)
          }}
        </div>
        <div class="qty_markup">{{ qty_discount.q1_markup }}</div>
      </div>
      <div v-if="part.q2_discount > 0">
        <div class="qty_unit">x{{ part.q2_unit }}</div>

        {{ percent_format(part.q2_discount / 100) }}
        <div class="qty_price">
          {{
            Number(part.cost) < 1
              ? money_format(qty_discount.q2, 3)
              : money_format(qty_discount.q2)
          }}
        </div>
        <div class="qty_markup">{{ qty_discount.q1_markup }}</div>
      </div>
      <div v-if="part.q3_discount > 0">
        <div class="qty_unit">x{{ part.q3_unit }}</div>

        {{ percent_format(part.q3_discount / 100) }}
        <div class="qty_price">
          {{
            Number(part.cost) < 1
              ? money_format(qty_discount.q3, 3)
              : money_format(qty_discount.q3)
          }}
        </div>
        <div class="qty_markup">{{ qty_discount.q1_markup }}</div>
      </div>
      <div v-if="part.q4_discount > 0">
        <div class="qty_unit">x{{ part.q4_unit }}</div>

        {{ percent_format(part.q4_discount / 100) }}
        <div>
          {{
            Number(part.cost) < 1
              ? money_format(qty_discount.q4, 3)
              : money_format(qty_discount.q4)
          }}
        </div>
        <div class="qty_markup">{{ qty_discount.q1_markup }}</div>
      </div>
    </td>

    <td>{{ part.notes }}</td>

    <td>
      <v-btn color="primary" small outlined @click="start_edit">Edit</v-btn>
    </td>

    <EditPart
      v-if="edit_flag"
      :passed-part="part"
      :mode="edit_mode"
      @killme="stop_edit()"
    ></EditPart>
    <PriceHistory
      v-if="show_history_flag"
      :passed-part="part"
      :price-type="price_history_type"
      @killme="hide_history"
    ></PriceHistory>
  </tr>
</template>

<script>
import EditPart from './EditPart.vue'
import PriceHistory from './PriceHistory.vue'
export default {
  name: 'SinglePart',
  components: {
    EditPart,
    PriceHistory
  },
  props: {
    passedpart: {
      type: Object,
      default: function() {
        return {}
      }
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      edit_flag: false,
      edit_retail_flag: false,
      edit_wholesale_flag: false,
      show_history_flag: false,
      price_history_type: '',
      part: { ...this.passedpart },
      edit_mode: 'edit',
      qty: 1,
      retail_margin: this.round(
        (1 - this.passedpart.cost / this.passedpart.retail) * 100
      )
    }
  },
  computed: {
    qty_discount() {
      const q1 =
        (Number(this.part.wholesale) * (100 - this.part.q1_discount)) / 100
      const q2 = (q1 * (100 - this.part.q2_discount)) / 100
      const q3 = (q2 * (100 - this.part.q3_discount)) / 100
      const q4 = (q3 * (100 - this.part.q4_discount)) / 100
      const q1_markup = this.percent_format(
        (q1 - this.part.cost) / this.part.cost
      )
      const q2_markup = this.percent_format(
        (q2 - this.part.cost) / this.part.cost
      )
      const q3_markup = this.percent_format(
        (q3 - this.part.cost) / this.part.cost
      )
      const q4_markup = this.percent_format(
        (q4 - this.part.cost) / this.part.cost
      )
      return {
        q1: q1,
        q2: q2,
        q3: q3,
        q4: q4,
        q1_markup: q1_markup,
        q2_markup: q2_markup,
        q3_markup: q3_markup,
        q4_markup: q4_markup
      }
    },
    row_class() {
      return this.index % 2 == 0 ? 'even' : 'odd'
    }
  },
  watch: {
    passedpart() {
      this.part = { ...this.passedpart }
    }
  },
  mounted() {
    if (this.passedpart.noob) {
      this.noob()
    }
  },

  /* ---------------------------------------------------------------------------------------------- */
  /*                                           METHODS: {                                           */
  /* ---------------------------------------------------------------------------------------------- */

  methods: {
    noob() {
      this.edit_mode = 'new'
      this.start_edit()
    },

    start_edit() {
      this.edit_flag = true
    },
    stop_edit() {
      this.edit_flag = false
    },
    show_history(type) {
      this.price_history_type = type
      this.show_history_flag = true
    },
    hide_history() {
      this.price_history_type = 'x'
      this.show_history_flag = false
    },
    add(x) {
      this.$emit('add', x)
    }
  }
}
</script>

<style scoped>
.even {
  background-color: #fefeff;
}
.odd {
  background-color: #efefff;
}

.markup {
  font-size: 0.7em;
}
.unit {
  font-size: 0.7em;
  margin-top: -2px;
  line-height: 0.7em;
  font-style: italic;
}
.production_cost {
  font-size: 0.8em;
  color: blue;
  line-height: 1em;
  padding-top: 3px;
}
.myred {
  font-weight: bold;
  color: red;
}
.cell {
  position: relative;
  height: 100%;
}
.cost {
  display: inline-block;
  vertical-align: top;
}

tr td:nth-child(1) {
  width: 180px;
}

tr td:nth-child(1),
tr td:nth-child(2),
tr td:nth-child(3) {
  padding-left: 4px;
}

tr td:nth-child(4),
tr td:nth-child(5),
tr td:nth-child(6),
tr td:nth-child(8),
tr td:nth-child(7) {
  text-align: right;
  padding-right: 4px;
}
.clock {
  cursor: pointer;
  top: 20px;
  height: 21px;
  right: 0px;
  padding: 0px 2px 0px 4px;
  position: absolute;
  background-color: rgb(200, 255, 205);
  border-radius: 10px;
  border: green solid 1px;
  z-index: 98;
}
.hidden_icon .clock {
  visibility: hidden;
}
.hidden_icon:hover .clock {
  visibility: visible;
}
.clockwords {
  font-size: 0.7em;
  display: inline-block;
  position: relative;
  top: -3px;
}
.qty_unit {
  display: inline;
  font-size: 0.8em;
  color: blue;
}
.qty_price {
  display: inline-block;
}
.qty_markup {
  display: inline;
  font-size: 0.8em;
  color: green;
}
.id {
  font-size: 0.6em;
  font-style: italic;
  text-align: right;
  color: purple;
}
.supplier_name {
  color: purple;
}
.parts_table td {
  border-right: 1px dotted rgb(213, 213, 245);
}
.category {
  font-size: 0.7em;
  padding-left: 10px;
}
.subcategory {
  font-size: 0.7em;
  padding-left: 10px;
  font-style: italic;
  color: rgb(145, 99, 14);
}
.mm {
  font-size: 0.7em;
  padding-left: 10px;
  color: blue;
}
</style>
