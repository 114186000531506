<template>
  <v-dialog v-model="show" width="500">
    <v-card>
      <v-card-title class="card_title">
        <h3>Archive or delete?</h3>
        <v-spacer></v-spacer>
        <v-icon @click="show = false">mdi-close</v-icon>
      </v-card-title>
      <v-card-text>
        You can set this item to be Archived so that it will no longer show up
        in the main category. It will be in the Archive category. This does not
        fully delete it from the database in case there were some orders using
        the part number.
        <br />
        <br />
        Only fully delete it if there are no orders or other records referencing
        it. To enable the delete button, type DELETE into this box
        <v-text-field outlined dense v-model="delete_text"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          :disabled="delete_text != 'DELETE'"
          @click="delete_me"
        >
          Delete
        </v-btn>
        <v-btn color="primary" @click="hide_me">Archive part</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="cancel()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios'
export default {
  props: {
    part: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      delete_text: '',
      show: true
    }
  },
  watch: {
    show() {
      this.$emit('killme')
    }
  },
  mounted() {
    this.$refs.modal.show()
  },
  methods: {
    async delete_me() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + 'delete_part.php', {
          func: 'delete',
          part: this.part
        })
        this.show = false
      } catch (error) {
        console.log(error)
      }
      return true
    },
    async hide_me() {
      try {
        await axios.post(process.env.VUE_APP_API_URL + 'delete_part.php', {
          func: 'hide',
          part: this.part
        })
        this.show = false
      } catch (error) {
        console.log(error)
      }
      return true
    },
    cancel() {
      this.show = false
      return true
    }
  }
}
</script>

<style scoped>
.card_title {
  background-color: rgb(212, 212, 212);
  color: rgb(0, 0, 0);
  cursor: grab;
}
.card_title:active {
  cursor: grabbing;
}
</style>
