import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CustomerList from '@/views/CustomerList.vue'
import ReportsCompactMasterPriceList from '@/views/ReportsCompactMasterPriceList.vue'
import ReportsOfficeCopy from '@/views/ReportsOfficeCopy.vue'
import ReportsShopCopy from '@/views/ReportsShopCopy.vue'
import ReportsCustomerPricelist from '@/views/ReportsCustomerPricelist.vue'
import ReportsWarehouseReport from '@/views/ReportsWarehouseReport.vue'
import ReportsCheatSheet from '@/views/ReportsCheatSheet.vue'
import ReportsDirectoryReport from '@/views/ReportsDirectoryReport.vue'
import ReportsPocketDirectory from '@/views/ReportsPocketDirectory.vue'
import ReportsPricelistCSV from '@/views/ReportsPricelistCSV.vue'
import ReportsParentParts from '@/views/ReportsParentParts.vue'
import EnterInventory from '@/views/EnterInventory.vue'
import InventoryList from '@/views/InventoryList.vue'
import ProductionCosts from '@/views/ProductionCosts.vue'
import MailingLists from '@/views/MailingLists.vue'
import IndexEntry from '@/views/IndexEntry.vue'
import Audit from '@/views/Audit.vue'
import PartsList from '@/views/PartsList.vue'
import ReportsCalendar from '@/views/ReportsCalendar.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/customer_list',
    name: 'CustomerList',
    component: CustomerList
  },
  {
    path: '/reports_calendar',
    name: 'ReportsCalendar',
    component: ReportsCalendar
  },
  {
    path: '/reports_parent_parts',
    name: 'ReportsParentParts',
    component: ReportsParentParts
  },
  {
    path: '/production_costs',
    name: 'ProductionCosts',
    component: ProductionCosts
  },
  {
    path: '/audit',
    name: 'Audit',
    component: Audit
  },
  {
    path: '/reports_pricelist_csv',
    name: 'ReportsPricelistCSV',
    component: ReportsPricelistCSV
  },
  {
    path: '/parts_list',
    name: 'PartsList',
    component: PartsList
  },
  {
    path: '/reports_customer_pricelist',
    name: 'ReportsCustomerPricelist',
    component: ReportsCustomerPricelist
  },
  {
    path: '/index_entry',
    name: 'IndexEntry',
    component: IndexEntry
  },
  {
    path: '/enter_inventory',
    name: 'EnterInventory',
    component: EnterInventory
  },
  {
    path: '/inventory_list',
    name: 'InventoryList',
    component: InventoryList
  },
  {
    path: '/reports_cheat_sheet',
    name: 'ReportsCheatSheet',
    component: ReportsCheatSheet
  },
  {
    path: '/mailing_lists',
    name: 'MailingLists',
    component: MailingLists
  },

  {
    path: '/reports_compact_master_price_list',
    name: 'ReportsCompactMasterPriceList',
    component: ReportsCompactMasterPriceList
  },
  {
    path: '/reports_office_copy',
    name: 'ReportsOfficeCopy',
    component: ReportsOfficeCopy
  },
  {
    path: '/reports_shop_copy',
    name: 'ReportsShopCopy',
    component: ReportsShopCopy
  },
  {
    path: '/reports_warehouse_report',
    name: 'ReportsWarehouseReport',
    component: ReportsWarehouseReport
  },
  {
    path: '/reports_directory_report',
    name: 'ReportsDirectoryReport',
    component: ReportsDirectoryReport
  },
  {
    path: '/reports_pocket_directory',
    name: 'ReportsPocketDirectory',
    component: ReportsPocketDirectory
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
