<template>
  <div id="header" class="shadow">
    <b-nav class="navbar bg-light py-0 mr-0">
      <div class="ml-2 mt-1 title">
        <slot name="title">Menu</slot>
      </div>
      <div id="summary" v-if="show_summary_flag">
        <div class="overlay">
          <slot name="summary"></slot>
        </div>
      </div>
      <!-- Google login -->
      <b-nav-item-dropdown right>
        <template slot="button-content">
          <div
            class="admin"
            v-if="$store.getters.profile.is_admin"
            style="font-size: 0.8em; line-height: 0.1em"
          >
            administrator
          </div>
          {{ $store.getters.profile.name }}

          <b-avatar
            :src="$store.getters.profile.picture"
            class="avatar"
          ></b-avatar>
        </template>
        <b-dropdown-item v-if="is_logged_in" :href="logout_url">
          Logout
        </b-dropdown-item>

        <b-dropdown-item v-else>
          <GoogleLogin />
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-nav>
  </div>
</template>

<script>
const axios = require('axios')
import GoogleLogin from '../components/GoogleLogin'

export default {
  name: 'TheTopBar',
  components: {
    GoogleLogin
  },
  props: {},
  data() {
    return {
      auth_timer: null,
      show_summary_flag: false
    }
  },
  computed: {
    logout_url: function () {
      return process.env.VUE_APP_API_URL + 'auth_logout.php'
    },
    is_logged_in: function () {
      return this.$store.getters.profile.authorized === true
    }
  },
  mounted() {
    this.start_auth_timer()
  },
  created() {
    this.check_auth()
  },
  beforeDestroy() {
    this.stop_auth_timer()
  },
  methods: {
    start_auth_timer() {
      let v = this
      this.auth_timer = setInterval(function () {
        v.check_auth()
      }, 5 * 60 * 1000)
    },
    stop_auth_timer() {
      clearInterval(this.auth_timer)
    },
    conditional_check_auth() {
      if (!this.$store.getters.profile.authorized) {
        this.check_auth()
      }
    },
    check_auth() {
      axios.post(process.env.VUE_APP_API_URL + 'auth.php').then(response => {
        this.$store.commit('set_profile', response.data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  border: #ddd solid 1px !important;
  width: 100%;
  height: 68px;
  margin-bottom: 1px;
}
.navbar {
  height: 68px;
}

.admin {
  text-align: left;
  color: green;
}
.avatar {
  position: absolute;

  top: -10px;
}
</style>
