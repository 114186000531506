<template>
  <g-signin-button
    :params="googleSignInParams"
    @success="onSignInSuccess"
    @error="onSignInError"
  >
    Sign in to OLD site with Google
  </g-signin-button>
</template>

<script>
const axios = require('axios')

export default {
  data() {
    return {
      // /**
      //  * The Auth2 parameters, as seen on
      //  * https://developers.google.com/identity/sign-in/web/reference#gapiauth2initparams.

      googleSignInParams: {
        client_id:
          '998845935395-l7689amra2g8qi38726f51ao7st6299j.apps.googleusercontent.com'
      }
    }
  },
  methods: {
    onSignInSuccess(googleUser) {
      // `googleUser` is the GoogleUser object that represents the just-signed-in user.
      // See https://developers.google.com/identity/sign-in/web/reference#users
      // eslint-disable-next-line no-unused-vars
      const profile = googleUser.getBasicProfile()
      //console.log(profile)
      var id_token = googleUser.getAuthResponse().id_token
      axios
        .post(process.env.VUE_APP_API_URL + 'auth.php', {
          token: id_token
        })
        .then(response => {
          console.log(response.data)
          this.$store.commit('set_profile', response.data)
        })
        .catch(function(error) {
          console.log(error)
          alert(error)
        })
    },
    onSignInError(error) {
      // `error` contains any error occurred.
      //console.log('OH NOES', error)
      alert(error)
    }
  }
}
</script>

<style>
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
</style>
