<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width="30"
    height="30"
  >
    <title>{{ title }}</title>
    <g>
      <path
        fill="#335262"
        d="M41,1H8C6.89543,1,6,1.89543,6,3v42c0,1.10457,0.89543,2,2,2h33c1.10457,0,2-0.89543,2-2V3 C43,1.89543,42.10457,1,41,1z"
      />
      <path
        fill="#E6E6E6"
        d="M37,7H12c-0.55273,0-1,0.44775-1,1v6c0,0.55225,0.44727,1,1,1h25c0.55273,0,1-0.44775,1-1V8 C38,7.44775,37.55273,7,37,7z"
      />
      <path
        fill="#6A8A91"
        d="M17,19h-5c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1v-4 C18,19.44775,17.55273,19,17,19z"
      />
      <path
        fill="#6A8A91"
        d="M27,19h-5c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1v-4 C28,19.44775,27.55273,19,27,19z"
      />
      <path
        fill="#6A8A91"
        d="M37,19h-5c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1v-4 C38,19.44775,37.55273,19,37,19z"
      />
      <path
        fill="#6A8A91"
        d="M17,27h-5c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1v-4 C18,27.44775,17.55273,27,17,27z"
      />
      <path
        fill="#6A8A91"
        d="M27,27h-5c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1v-4 C28,27.44775,27.55273,27,27,27z"
      />
      <path
        fill="#EA9860"
        d="M37,27h-5c-0.55273,0-1,0.44775-1,1v12c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1V28 C38,27.44775,37.55273,27,37,27z"
      />
      <path
        fill="#6A8A91"
        d="M17,35h-5c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1v-4 C18,35.44775,17.55273,35,17,35z"
      />
      <path
        fill="#6A8A91"
        d="M27,35h-5c-0.55273,0-1,0.44775-1,1v4c0,0.55225,0.44727,1,1,1h5c0.55273,0,1-0.44775,1-1v-4 C28,35.44775,27.55273,35,27,35z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'calculator'
    }
  }
}
</script>
